import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import Button from 'src/components/atoms/Button/Button';
import SearchInput from 'src/components/atoms/SearchInput/SearchInput';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import { changeTab } from 'src/store/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './InfoCard.module.scss';
import { useNavigate } from 'react-router-dom';

export interface IInfoCardData {
  nameTranslationId: string;
  totalCount: number;
  tabs: { nameTranslationId: string; type: any }[];
  type: 'REQUISITIONS' | 'RECRUITMENT' | 'AI_MATCHING';
  lastUpdateDate?: Date;
}

const InfoCard = ({ nameTranslationId, totalCount, tabs, type, lastUpdateDate }: IInfoCardData) => {
  const activeTab = useAppSelector((state) => state.pagination.tab);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { anErrorOccured, dataHasBeenSynchronized } = useAlertTexts();

  return (
    <section data-test-id='page-info-card' className={styles.infoCard}>
      <div className={styles.firstRow}>
        <h1 className={styles.header} data-test-id='page-info-card-header'>
          {translate(nameTranslationId)}
          <div className={styles.dot}></div>
          {totalCount}
        </h1>
        {type !== 'AI_MATCHING' && <SearchInput type={type} />}
        {type === 'AI_MATCHING' && (
          <div className={styles.aiMatchingSection}>
            <SearchInput type={type} />
            <Button
              ctaText={'New Match'}
              stylingType='primary'
              type='button'
              dataTestId='card-secondary-button'
              ownClass={styles.actionButton}
              onClick={() => navigate('/match-candidate', { state: null })}
            />
          </div>
        )}
      </div>
      <div className={styles.secondRow}>
        <ul className={styles.tabs} data-test-id='page-info-card-tabs'>
          {tabs.map((tab) => (
            <li
              data-test-id={`page-info-card-${tab.type}-tab`}
              className={tab.type === activeTab ? styles.activeTab : ''}
              key={getUUID()}
              onClick={() => tab.type !== activeTab && dispatch(changeTab(tab.type))}
            >
              {translate(tab.nameTranslationId)}
            </li>
          ))}
        </ul>
        {type === 'REQUISITIONS' && (
          <div className={styles.bullhornSync}>
            <p className={styles.syncInfo}>
              <span>{translate('lastSync')}: </span>
              {dayjs(lastUpdateDate).format('DD.MM.YYYY, HH:mm') || '-'}
            </p>
            <Button
              ctaText={
                <div className={styles.refreshButtonCTA}>
                  <Icon icon={IconType.Refresh} /> {translate('refresh')}
                </div>
              }
              stylingType='secondary'
              type='button'
              dataTestId='cancel-button'
              ownClass={styles.syncButton}
              onClick={() => {
                setLoading(true);
                requestWrapper('POST', '/Job/refreshjobs')
                  .then(() => {
                    addAlert({
                      id: getUUID(),
                      type: 'SUCCESS',
                      message: dataHasBeenSynchronized,
                    });
                    setLoading(false);
                  })
                  .catch((err) => {
                    addAlert({
                      id: getUUID(),
                      type: 'ERROR',
                      message: anErrorOccured,
                    });
                    console.error(err);
                    setLoading(false);
                  });
              }}
            />
          </div>
        )}
      </div>
      <LoaderModal isOpen={loading} />
    </section>
  );
};

export default InfoCard;
