import classNames from 'classnames';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/atoms/Button/Button';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import PersonShortenInfo from 'src/components/atoms/PersonShortenInfo/PersonShortenInfo';
import PollStatus from 'src/components/atoms/PollStatus/PollStatus';
import CancelPollModal from 'src/components/modals/CancelPoll/CancelPollModal';
import NoteModal from 'src/components/modals/NoteModal/NoteModal';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import Modal from 'src/components/utility/Modal/Modal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type { IPollDetails } from 'src/pages/PollDetailsPage/PollDetailsPage.def';
import preparePollDetailsPageData from 'src/pages/PollDetailsPage/preparePollDetailsPageData';
import type { ICandidate } from 'src/pages/RequisitionsPage/Requisitions.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './CandidateRow.module.scss';

interface ICandidateRowProps {
  id: number;
  candidate: ICandidate;
  candidates: ICandidate[];
  setCandidates: Dispatch<SetStateAction<ICandidate[]>>;
  checked: boolean;
  type: string;
  handleCheck: () => void;
  handleNoteSave: (candidateNote: string) => void;
}

const CandidateRow = ({
  id,
  candidate,
  candidates,
  setCandidates,
  checked,
  type,
  handleCheck,
  handleNoteSave,
}: ICandidateRowProps) => {
  const [showFullNoteIndex, setShowFullNoteIndex] = useState<boolean>(false);
  const [openActions, setOpenActions] = useState<boolean>(false);
  const [pollDetails, setPollDetails] = useState<IPollDetails | null>(null);
  const [noteModal, setNoteModal] = useState<'NEW' | 'EDIT' | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { pollCanceledSuccessfullyText, anErrorOccured } = useAlertTexts();

  const showCancelThePoll = ['SentToClient', 'NoCommonAvailability'].includes(
    candidate.questionnaireStatusCache,
  );

  const navigate = useNavigate();

  const handleOpenCancelModal = () => {
    setLoading(true);
    requestWrapper('GET', `/Questionnaire/details?shortListCandidateId=${candidate.id}`)
      .then((response) => {
        setLoading(false);
        setPollDetails(preparePollDetailsPageData(response));
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  const handlePollCancelation = () => {
    setLoading(true);
    requestWrapper('GET', `/Questionnaire/disable?shortListCandidateId=${candidate.id}`)
      .then(() => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'SUCCESS',
          message: pollCanceledSuccessfullyText,
        });
        setCandidates(
          candidates.map((candidate) =>
            pollDetails?.candidates.find((cand) => cand.candidateId === candidate.candidateId)
              ? {
                  ...candidate,
                  questionnaireStatusCache: 'NoQuestionnaire',
                  questionnaireStatusUpdateDate: new Date().toDateString(),
                  selected: true,
                }
              : candidate,
          ),
        );
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (openActions && ref.current && !ref.current.contains(e.target)) {
        setOpenActions(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openActions]);

  const pollActionsTranslations = {
    pollDetails: translate('pollDetails'),
    cancelThePoll: translate('cancelThePoll'),
  };

  return (
    <div className={styles.candidateRow}>
      <Checkbox
        dataTestId='table-row-checkbox'
        checked={checked}
        onChange={handleCheck}
        disabled={candidate.questionnaireStatusCache !== 'NoQuestionnaire'}
      />
      <PersonShortenInfo
        name={`${candidate.firstName} ${candidate.lastName}`}
        email={candidate.email}
      />
      <p data-test-id='candidate-type' className={styles.candidateType}>
        {type}
      </p>
      <div data-test-id='candidate-note' className={styles.note}>
        {candidate.note && candidate.note !== '' && (
          <div data-test-id='existing-note' className={styles.existingNote}>
            <div
              className={candidate.note.length > 51 ? styles.expandedNote : ''}
              onClick={() => {
                setShowFullNoteIndex(!showFullNoteIndex ? candidate.note.length > 51 : false);
              }}
            >
              <p className={styles.noteParagraph} data-test-id='note'>
                {candidate.note}
              </p>
              {showFullNoteIndex && (
                <div className={styles.fullNote}>
                  <p>{candidate.note}</p>
                </div>
              )}
            </div>
            <div className={styles.noteButtons}>
              <Button
                ctaText={<Icon icon={IconType.Edit} />}
                stylingType='secondary'
                type='button'
                dataTestId='edit-button'
                ownClass={styles.noteButton}
                onClick={() => {
                  setNoteModal('EDIT');
                }}
              />
              <Button
                ctaText={<Icon icon={IconType.Remove} />}
                stylingType='secondary'
                type='button'
                dataTestId='remove-button'
                ownClass={styles.noteButton}
                onClick={() => {
                  handleNoteSave('');
                }}
              />
            </div>
          </div>
        )}
        {(!candidate.note || candidate.note === '') && (
          <div
            onClick={() => setNoteModal('NEW')}
            data-test-id='candidate-add-new-note'
            className={styles.addNewNote}
          >
            <div className={styles.addNote}>
              <Icon icon={IconType.Plus} />
            </div>
            <p>{translate('addNote')}</p>
          </div>
        )}
        <LoaderModal isOpen={loading} />
        <Modal
          handleClose={() => setNoteModal(null)}
          isOpen={!!noteModal}
          dataTestId='add-new-note-modal'
        >
          <NoteModal
            type={noteModal}
            note={candidate.note}
            onClose={() => {
              setNoteModal(null);
            }}
            handleSave={handleNoteSave}
          />
        </Modal>
        <Modal
          handleClose={() => setPollDetails(null)}
          isOpen={!!pollDetails}
          dataTestId='add-new-note-modal'
        >
          <CancelPollModal
            pollDetails={pollDetails}
            onClose={() => {
              setPollDetails(null);
            }}
            handleCancel={handlePollCancelation}
          />
        </Modal>
      </div>
      <PollStatus
        questionnaireStatusCache={candidate.questionnaireStatusCache}
        questionnaireStatusUpdateDate={candidate.questionnaireStatusUpdateDate}
      />
      <div data-test-id='candidate-row-action-dropdown-button' ref={ref} className={styles.action}>
        <Button
          ctaText={<Icon icon={IconType.MorePurple} />}
          stylingType='secondary'
          type='button'
          dataTestId='actions-button'
          ownClass={styles.actionButton}
          onClick={() => setOpenActions(!openActions)}
          disabled={
            ![
              'SentToClient',
              'SentToCandidate',
              'NoCommonAvailability',
              'WaitingForURL',
              'URLSet',
              'ToReschedule',
              'NoDate',
              'Expired',
            ].includes(candidate.questionnaireStatusCache)
          }
        />

        {openActions && (
          <div
            data-test-id='actions-dropdown'
            className={classNames(
              styles.actionsDropdown,
              !showCancelThePoll && styles.actionsDropdownShorter,
            )}
          >
            <ul>
              <li
                data-test-id='poll-details-option'
                onClick={() => {
                  navigate(`/poll-details/${candidate.id}`);
                }}
              >
                {pollActionsTranslations.pollDetails}
              </li>
              {showCancelThePoll && (
                <li onClick={handleOpenCancelModal} data-test-id='cancel-the-poll-option'>
                  {pollActionsTranslations.cancelThePoll}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateRow;
