import type { IRecruitmentDataResponse, IRecruitmentPageData } from './Recruitment.def';

const prepareRecruitmentPageData = (
  recruitmentResponse: IRecruitmentDataResponse | null,
): IRecruitmentPageData | null => {
  if (!recruitmentResponse) {
    return null;
  }

  return {
    infoCard: {
      nameTranslationId: 'recruitments',
      totalCount: recruitmentResponse.TotalDataCount,
      type: 'RECRUITMENT',
      tabs: [
        { nameTranslationId: 'allMeetings', type: 'all' },
        { nameTranslationId: 'upcoming', type: 'upcoming' },
        { nameTranslationId: 'pending', type: 'pending' },
        { nameTranslationId: 'actionRequired', type: 'action_required' },
        { nameTranslationId: 'archives', type: 'archives' },
      ],
    },
    recruitmentsList: recruitmentResponse.Data.map((resp) => ({
      id: resp.Id,
      requisitionId: resp.RequisitionId,
      jobTitle: resp.JobTitle,
      jobDescription: resp.JobDescription,
      companyName: resp.CompanyName,
      candidateFirstName: resp.CandidateFirstName,
      candidateLastName: resp.CandidateLastName,
      status: resp.Status,
      statusUpdateDate: resp.StatusUpdateDate,
      dateFrom: resp.DateFrom,
      dateTo: resp.DateTo,
      link: resp.Url,
      isArchived: resp.IsArchived,
    })),
  };
};

export default prepareRecruitmentPageData;
