import { translate } from 'src/contexts/Language';
import { useState } from 'react';
import styles from './SendPollAnywayModal.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from 'src/components/atoms/Button/Button';
import type { ITerm } from 'src/pages/PollDetailsPage/PollDetailsPage.def';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';

interface ISendPollAnywayModalProps {
  totalVotesNumber: number;
  minTermsCount: number;
  terms: ITerm[];
  onCancel: () => void;
  onSend: (chosenTerms: ITerm[]) => void;
}

const SendPollAnywayModal = ({
  totalVotesNumber,
  minTermsCount,
  terms,
  onCancel,
  onSend,
}: ISendPollAnywayModalProps) => {
  const [chosenTerms, setChosenTerms] = useState<ITerm[]>(terms);

  const handleTermClick = (uuid: string) => {
    setChosenTerms(
      chosenTerms.map((term) =>
        term.uuid === uuid ? { ...term, selected: !term.selected } : term,
      ),
    );
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <h3>{translate('sendPollToCandidates')}</h3>
        <p>{translate('sendPollModalInfo')}</p>
      </div>
      <div className={styles.termsList}>
        {chosenTerms.map((term) => (
          <div
            key={term.uuid}
            className={classNames(styles.term, term.selected && styles.chosenTerm)}
            onClick={() => {
              handleTermClick(term.uuid);
            }}
          >
            <div className={styles.termWrapper}>
              <div className={styles.termHeader}>
                <div className={styles.date}>
                  <p>{dayjs(term.dateFrom).format('dddd, MMMM DD')}</p>
                  <p>{`${dayjs(term.dateFrom).format('HH:mm')} - ${dayjs(term.dateTo).format(
                    'HH:mm',
                  )}`}</p>
                </div>
                <div className={styles.rightHeader}>
                  <p>
                    {translate('votes')}: <span>{term.votesCount}</span>/{totalVotesNumber}
                  </p>
                  {term.selected && <Icon icon={IconType.Tick} />}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.actionButtons} data-test-id='actions-buttons'>
        <Button
          ctaText={translate('cancel')}
          stylingType='secondary'
          type='button'
          dataTestId='cancel-button'
          ownClass={styles.cancelButton}
          onClick={onCancel}
        />
        <Button
          ctaText={translate('sendPoll')}
          stylingType='primary'
          type='button'
          dataTestId='confirm-button'
          ownClass={styles.primaryButton}
          disabled={!(chosenTerms.filter((term) => term.selected === true).length >= minTermsCount)}
          onClick={() => {
            onSend(chosenTerms);
            onCancel();
          }}
        />
      </div>
    </div>
  );
};

export default SendPollAnywayModal;
