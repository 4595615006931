import { useState } from 'react';
import AddParticipantSection from 'src/components/organisms/AddParticipantSection/AddParticipantSection';
import MeetingDetailsForm from 'src/components/organisms/MeetingDetailsForm/MeetingDetailsForm';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import type { ICandidate, IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import styles from './CreatePollForm.module.scss';

interface ICreatePollFormProps {
  requisition: Partial<IRequisition>;
  candidates: ICandidate[];
  participants?: IParticipant[];
  duration?: string;
  timezone?: string;
  pollExpirationDate?: Date;
}

const CreatePollForm = ({
  requisition,
  candidates,
  participants,
  duration,
  timezone,
  pollExpirationDate,
}: ICreatePollFormProps) => {
  const [_participants, setParticipants] = useState<IParticipant[]>(participants || []);
  const [skipParticipants, setSkipParticipants] = useState<boolean>(false);

  return (
    <div className={styles.createFormPollContainer}>
      <AddParticipantSection
        setParticipants={setParticipants}
        participants={_participants}
        skipParticipants={skipParticipants}
        setSkipParticipants={setSkipParticipants}
      />
      <MeetingDetailsForm
        requisition={requisition}
        candidates={candidates}
        participants={_participants}
        duration={duration}
        timezone={timezone}
        pollExpirationDate={pollExpirationDate}
        skipParticipants={skipParticipants}
      />
    </div>
  );
};

export default CreatePollForm;
