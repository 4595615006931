import { useLocation } from 'react-router-dom';
import PollSummaryTemplate from 'src/components/templates/PollSummaryTemplate/PollSummaryTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';

const PollSummaryPage = () => {
  const location = useLocation();
  useADAccessToken();

  return <PollSummaryTemplate {...location.state} />;
};

export default PollSummaryPage;
