import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IMeetingPoolData } from 'src/pages/CreatePollPage/CreatePoll.def';
import styles from './CalendarPageHeader.module.scss';

const CalendarPageHeader = (props: IMeetingPoolData) => (
  <header className={styles.header}>
    <BackHeader
      title={translate('proposeDates')}
      backUrl={'/create-poll'}
      backState={props}
      ownClass={styles.backHeader}
    />
    <div className={styles.headerInfo} data-test-id='meeting-info'>
      <div className={styles.infoItem}>
        <span data-test-id='meeting-duration-label'>
          <Icon icon={IconType.Clock} /> {translate('duration')}:
        </span>
        <p data-test-id='meeting-duration'>{`${props.duration} min`}</p>
      </div>
      <div className={styles.infoItem}>
        <span data-test-id='meeting-timezone-label'>
          <Icon icon={IconType.Location} /> {translate('meetingTimeZone')}:
        </span>
        <p data-test-id='meeting-timezone'>{props.timezone}</p>
      </div>
    </div>
  </header>
);

export default CalendarPageHeader;
