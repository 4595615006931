import classNames from 'classnames';
import styles from './LoaderModal.module.scss';
import ReactPortal from 'src/components/utility/ReactPortal/ReactPortal';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';

interface ILoaderModalProps {
  isOpen: boolean;
}

const LoaderModal = ({ isOpen }: ILoaderModalProps) => {
  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId='react-portal-loader-modal-container'>
      <div className={styles.modalBackground}>
        <div data-test-id='loader-modal' className={classNames(styles.modal)}>
          <LoadingSpinner />
        </div>
      </div>
    </ReactPortal>
  );
};

export default LoaderModal;
