import styles from './RequisitionCardHeader.module.scss';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import { useNavigate } from 'react-router';

interface IRequisitionCardHeaderProps {
  id: number;
  requisitionId: number;
  title: string;
  companyName: string;
  isCardOpen: boolean;
  handleCardOpenChange: () => void;
}

const RequisitionCardHeader = ({
  id,
  title,
  companyName,
  requisitionId,
  isCardOpen,
  handleCardOpenChange,
}: IRequisitionCardHeaderProps) => {
  const navigate = useNavigate();

  return (
    <header data-test-id='card-header' className={styles.cardHeader}>
      <div className={styles.cardInfo}>
        <h2 data-test-id='card-title' onClick={() => handleCardOpenChange()}>
          {title}
        </h2>
        <div className={styles.requisitionDetails}>
          <p data-test-id='company-name'>
            <Icon icon={IconType.Folder} /> {companyName}
          </p>
          <p data-test-id='id'>
            <Icon icon={IconType.RequisitionId} /> <span>{translate('requisitionID')}:</span>{' '}
            {requisitionId}
          </p>
        </div>
      </div>

      <div className={styles.actionButtons} data-test-id='card-actions-buttons'>
        <Button
          ctaText={translate('history')}
          stylingType='secondary'
          type='button'
          dataTestId='card-secondary-button'
          onClick={() => navigate(`/requisitions/history/${id}`)}
          ownClass={styles.historyButton}
        />
        <Button
          ctaText={<Icon icon={IconType.ArrowDownWhite} />}
          stylingType='primary'
          type='button'
          dataTestId='card-primary-button'
          ownClass={styles.expandButton}
          onClick={() => handleCardOpenChange()}
          ctaClass={isCardOpen ? styles.arrowUp : ''}
        />
      </div>
    </header>
  );
};

export default RequisitionCardHeader;
