import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import CandidatePollTemplate from 'src/components/templates/CandidatePollTemplate/CandidatePollTemplate';
import { requestWrapper } from 'src/utils/requestWrapper';
import type { ICandidatePoll, ICandidatePollResponseData } from './CandidatePoll.def';

import prepareClientPollPageData from './prepareCandidatePollPageData';

const CandidatePollPage = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState<ICandidatePoll | null>();
  const navigate = useNavigate();

  useEffect(() => {
    requestWrapper('GET', `/client/candidatetimespans?token=${id}`)
      .then((res) => {
        if (res.status === 499) {
          res.text().then((txt: string) => {
            console.error(txt);
            if (txt === 'Questionnaire expired') {
              navigate('/candidate-poll/success', { state: { type: 'EXPIRED' } });
            }
          });
        } else {
          if (res.IsCanceled) {
            navigate('/candidate-poll/success', { state: { type: 'CANCELED' } });
            return null;
          }
          if (res.IsCompleted) {
            navigate('/candidate-poll/success', { state: { type: 'VOTED' } });
            return null;
          }
          return res;
        }
      })
      .then((res: ICandidatePollResponseData | null) => {
        res ? setPageData(prepareClientPollPageData(res)) : null;
      })
      .catch((err) => {
        console.error(err);
        navigate('/candidate-poll/success', { state: { type: 'EXPIRED' } });
      });
  }, [id, navigate]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <CandidatePollTemplate {...pageData} />;
};

export default CandidatePollPage;
