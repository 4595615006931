import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import RequisitionHistoryPageTemplate from 'src/components/templates/RequisitionHistoryPageTemplate/RequisitionHistoryPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import { changePageCount } from 'src/store/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';
import { requestWrapper } from 'src/utils/requestWrapper';
import type {
  IRequisitionHistoryEventResponse,
  IRequisitionHistoryPageData,
} from './RequisitionHistoryPage.def';

const RequisitionHistoryPage = () => {
  const [pageData, setPageData] = useState<IRequisitionHistoryPageData | null>(null);

  const { id } = useParams();
  useADAccessToken();

  const dispatch = useAppDispatch();
  const { page } = useAppSelector((state) => state.pagination);

  useEffect(() => {
    requestWrapper('POST', '/Job/activityhistory', {
      itemsPerPage: 10,
      pageIndex: page,
      jobId: id,
    })
      .then((response) => {
        dispatch(changePageCount(response.TotalPageCount));
        setPageData({
          events: response.Data.map((r: IRequisitionHistoryEventResponse) => ({
            additionalData: r.AdditionalData,
            date: r.Date,
            eventType: r.EventType,
            id: r.Id,
            interviewId: r.InterviewId,
            jobId: r.JobId,
            message: r.Message,
            questionnaireId: r.QuestionnaireId,
            userFullName: r.UserFullName,
            userName: r.UserName,
          })),
          companyName: response.CompanyName,
          jobName: response.JobName,
          jobId: response.JobId,
        });
      })
      .catch((err) => console.error(err));
  }, [dispatch, id, page]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <RequisitionHistoryPageTemplate {...pageData} />;
};

export default RequisitionHistoryPage;
