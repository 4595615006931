import { useLocation } from 'react-router-dom';
import ClientPollSuccessTemplate from 'src/components/templates/ClientPollSuccessTemplate/ClientPollSuccessTemplate';

const ClientPollSuccessPage = () => {
  const location = useLocation();

  const type = location?.state?.type || 'EXPIRED';
  return <ClientPollSuccessTemplate type={type} />;
};

export default ClientPollSuccessPage;
