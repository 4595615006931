import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'src/App/authConfig';

const useADAccessToken = () => {
  const { instance, accounts, inProgress } = useMsal();
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  instance
    .acquireTokenSilent(request)
    .then((response) => {
      sessionStorage.setItem('idToken', JSON.stringify(response.idToken));
    })
    .catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        sessionStorage.setItem('idToken', JSON.stringify(response.idToken));
      });
    });
};

export default useADAccessToken;
