import dayjs from 'dayjs';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/atoms/Button/Button';
import FormDatePicker from 'src/components/atoms/FormDateTimePicker/FormDateTimePicker';
import RemoveParticipantModal from 'src/components/modals/RemoveParticipantModal/RemoveParticipantModal';
import SaveMeetingEditionModal from 'src/components/modals/SaveMeetingEditionModal/SaveMeetingEditionModal';
import AddPersonForm from 'src/components/molecules/AddPersonForm/AddPersonForm';
import ParticipantsListItem from 'src/components/molecules/ParticipantsListItem/ParticipantsListItem';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import Modal from 'src/components/utility/Modal/Modal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import type { IRecruitmentDetails } from 'src/pages/RecruitmentPage/Recruitment.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './MeetingEditionForm.module.scss';

const MeetingEditionForm = (props: IRecruitmentDetails) => {
  const [date, setDate] = useState<Date>(props.selectedDateFrom);
  const [participants, setParticipants] = useState<IParticipant[]>(props.recruiters);
  const [removeParticipantModal, setRemoveParticipantModal] = useState<IParticipant | null>(null);
  const [saveMeetingEditionModal, setSaveMeetingEditionModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string>(props.url);

  const { changesHaveBeenSaved, anErrorOccured } = useAlertTexts();
  const { addAlert } = useContext(AlertContext) as AlertContextType;

  const navigate = useNavigate();

  const handleSave = () => {
    setLoading(true);
    const data = {
      id: props.id,
      selectedDateFrom: date,
      selectedDateTo: dayjs(date).add(props.duration, 'minutes').toDate(),
      url: link,
      attendees: participants,
    };

    requestWrapper('POST', '/interview/edit', data)
      .then(() => {
        setLoading(false);
        addAlert({ id: getUUID(), type: 'SUCCESS', message: changesHaveBeenSaved });
        navigate('/recruitment');
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  return (
    <div className={styles.meetingFormContainer}>
      <div className={styles.meetingDate}>
        <div className={styles.label} data-test-id='meeting-date-label'>
          <Icon icon={IconType.CalendarPurple} />
          {translate('meetingDate')}
        </div>
        <p className={styles.meetingDateInfo} data-test-id='meeting-date-additional-info'>
          {translate('meetingDateAdditionalInfo')}
        </p>
        <Formik
          initialValues={{
            formDate:
              new Date(props.selectedDateFrom) ||
              dayjs().add(1, 'month').set('hour', 13).set('minute', 0).set('second', 0).toDate(),
          }}
          onSubmit={(
            values: { formDate: Date },
            { setSubmitting }: FormikHelpers<{ formDate: Date }>,
          ) => {
            setSubmitting(false);
          }}
        >
          <Form data-test-id='date-form' className={styles.dateForm}>
            <FormDatePicker
              name='formDate'
              label={false}
              additionalLabels={true}
              setOutsideValue={setDate}
            />
          </Form>
        </Formik>
      </div>
      <div className={styles.candidate}>
        <div className={styles.label} data-test-id='candidate-label'>
          <Icon icon={IconType.Candidates} />
          {translate('candidate')}
        </div>
        <div className={styles.candidateTile}>
          <div
            className={styles.initials}
            data-test-id='candidate-initials'
          >{`${props.candidateFirstName[0]}${props.candidateLastName[0]}`}</div>
          <div className={styles.candidateDetails}>
            <p data-test-id='candidate-full-name'>{`${props.candidateFirstName} ${props.candidateLastName}`}</p>
            <span data-test-id='candidate-email'>{props.candidateEmail}</span>
          </div>
        </div>
      </div>
      <div className={styles.participants}>
        <div className={styles.label} data-test-id='participants-label'>
          <Icon icon={IconType.Candidates} />
          {translate('participants')}
        </div>
        <div className={styles.participantsForm} data-test-id='added-participants'>
          {participants.map((participant) => (
            <Fragment key={getUUID()}>
              <ParticipantsListItem
                participant={participant}
                onRemove={() => {
                  setRemoveParticipantModal(participant);
                }}
                ownClass={styles.participantListItem}
              />
            </Fragment>
          ))}
          <AddPersonForm
            participants={participants}
            setParticipants={setParticipants}
            ownClass={styles.participantListItem}
          />
        </div>
      </div>
      <div className={styles.meetingLink}>
        <div className={styles.label} data-test-id='link-to-the-meeting-label'>
          <Icon icon={IconType.SmallLink} />
          {translate('linkToTheMeeting')}
        </div>
        <input
          data-test-id='link-input'
          name='note'
          placeholder={translate('addALinkToTheMeeting')}
          value={link}
          onChange={(event) => {
            setLink(event?.target.value);
          }}
        />
      </div>
      <div className={styles.actionButtons} data-test-id='actions-buttons'>
        <Button
          ctaText={translate('back')}
          stylingType='secondary'
          type='button'
          dataTestId='back-button'
          ownClass={styles.cancelButton}
          onClick={() => {
            navigate('/recruitment');
          }}
        />
        <Button
          ctaText={translate('save')}
          stylingType='primary'
          type='button'
          dataTestId='save-button'
          ownClass={styles.addButton}
          onClick={() => {
            setSaveMeetingEditionModal(true);
          }}
        />
      </div>
      <Modal
        handleClose={() => setRemoveParticipantModal(null)}
        isOpen={!!removeParticipantModal}
        dataTestId='remove-participant-modal'
      >
        <RemoveParticipantModal
          onClose={() => {
            setRemoveParticipantModal(null);
          }}
          handleRemove={(participantEmail) => {
            setParticipants(participants.filter((el) => el.email !== participantEmail));
          }}
          participant={removeParticipantModal}
        />
      </Modal>
      <Modal
        handleClose={() => setSaveMeetingEditionModal(false)}
        isOpen={saveMeetingEditionModal}
        dataTestId='save-meeting-edition-modal'
      >
        <SaveMeetingEditionModal
          onClose={() => {
            setSaveMeetingEditionModal(false);
          }}
          handleSave={handleSave}
        />
      </Modal>
      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default MeetingEditionForm;
