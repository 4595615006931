const AvengaLogo = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37 37'>
    <path
      fill='#FF4FE4'
      d='M9.456 18.315c0-5.24-2.149-9.094 1.07-11.31 7.16-5.374 10.676 7.247-1.07 11.31z'
    />
    <path
      fill='#00B5FF'
      d='M21.006 26.7c3.784 1.687 5.888 3.21 8.01.871 2.124-2.447 4.06-7.603-8.01-.871z'
    />
    <path
      fill='#FFB300'
      d='M13.55 31.45c1.083.072 1.894-.176 3.016-1.06 1.269-.999 2.044-2.327 4.439-3.688A15.418 15.418 0 0 0 16.8 25.55c-5.279-.75-12.204 2.187-3.251 5.9z'
    />
    <path
      fill='#00960A'
      d='M21.007 26.703c-2.394 1.36-3.17 2.689-4.439 3.687-1.122.885-1.933 1.133-3.016 1.06a13.871 13.871 0 0 0 8.349.508 13.845 13.845 0 0 0 7.117-4.384c-2.13 2.34-4.227.816-8.011-.871z'
    />
    <path
      fill='#FFB300'
      d='M5.26 22.563s0 .036.016.052c.23.735.52 1.448.87 2.134 2.29 4.526 3.431-1.739 3.31-6.435-3.85 1.283-4.583 2.94-4.197 4.249z'
    />
    <path
      fill='#FF3607'
      d='M9.457 18.318c0-5.12-2.05-8.918.86-11.156-2.337 1.731-4.079 4.143-4.984 6.904s-.93 5.733-.07 8.508c-.392-1.309.338-2.97 4.194-4.255z'
    />
    <path
      fill='#194EEF'
      d='M22.73 5.061c1.227.5 1.308 2.409.749 3.828a21.342 21.342 0 0 0-.844 2.532c-.046.21-.092.411-.137.61 1.38 1.022 4.615 1.201 7.02 2.262 1.635.72 2.9 1.86 2.943 3.97a13.928 13.928 0 0 0-2.717-8.176 13.983 13.983 0 0 0-7.014-5.026z'
    />
    <path
      fill='#00B5FF'
      d='M32.451 18.31c-.033-5.257-7.635-4.53-9.954-6.278-1.295 6.05 9.996 14.4 9.95 6.281'
    />
    <path
      fill='#FF4FE4'
      d='M22.498 12.032c-6.542-4.549-5.348-8.81.229-6.967 1.227.5 1.308 2.408.749 3.828a16.571 16.571 0 0 0-.978 3.139z'
    />
  </svg>
);

export default AvengaLogo;
