import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/atoms/Button/Button';
import CalendarPageHeader from 'src/components/molecules/CalendarPageHeader/CalendarPageHeader';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import Calendar from 'src/components/organisms/Calendar/Calendar';
import { translate } from 'src/contexts/Language';
import type { ICalendarEvent, IMeetingPoolData } from 'src/pages/CreatePollPage/CreatePoll.def';
import styles from './CalendarPageTemplate.module.scss';

const CalendarPageTemplate = (props: IMeetingPoolData) => {
  const [events, setEvents] = useState<ICalendarEvent[]>(props.events || []);
  const navigate = useNavigate();

  useEffect(() => {
    setEvents(props.events || []);
  }, [props.events]);

  return (
    <main className={styles.container}>
      <AppHeader currentPage='REQUISITIONS' />
      <section className={styles.calendarCard} data-test-id='calendar-card'>
        <CalendarPageHeader {...props} />
        <Calendar
          duration={props.duration}
          events={events}
          setEvents={setEvents}
          participants={props.participants}
        />
        <div className={styles.buttons} data-test-id='actions-buttons'>
          <Button
            dataTestId='back-button'
            ctaText={translate('back')}
            type='button'
            stylingType='secondary'
            ownClass={styles.backButton}
            onClick={() => {
              navigate('/create-poll', { state: props });
            }}
          />

          <Button
            dataTestId='next-button'
            ctaText={translate('next')}
            type='submit'
            stylingType='primary'
            disabled={events.length < 1}
            ownClass={styles.proposeDatesButton}
            onClick={() => {
              const _events = events.sort((a, b) => a.dateFrom.getTime() - b.dateFrom.getTime());
              const meetingPollData: IMeetingPoolData = {
                ...props,
                events: _events.filter((event) => event?.type !== 'EXTERNAL'),
              };
              navigate('/create-poll/summary', { state: meetingPollData });
            }}
          />
        </div>
      </section>
    </main>
  );
};

export default CalendarPageTemplate;
