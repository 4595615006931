import { useEffect, useState } from 'react';
import DetailedBackHeader from 'src/components/atoms/DetailedBackHeader/DetailedBackHeader';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import MatchingDescription from 'src/components/organisms/MatchingDescription/MatchingDescription';
import SelectMatchingCandidates from 'src/components/organisms/SelectMatchingCandidates/SelectMatchingCandidates';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './MatchCandidatesTemplate.module.scss';
import { removeTagsFromString } from 'src/utils/removeTagsFromString';

const mockDescription = `Primary responsibilities involve:
•	Assisting with project setup from a project planning, resource planning and time tracking perspective
•	Establishing and maintaining project governance
•	Creating and maintaining project initiation documents to ensure appropriate project setup
•	Supporting the Project/Programme Manager in all aspects of tracking and management
•	Defining and collecting project metrics including cost, time, quality, and risk
•	Scheduling and attending project meetings and documenting meeting minutes and action items
•	Managing the issues list to ensure issues are documented and actioned as appropriate
•	Effectively communicating with key project stakeholders on project progress
•	Taking responsibility for quality assurance within the program by overseeing that Project Services methodologies, standards and procedures are being implemented regionally
•	Establishing credibility with all levels of IT management
Essential Experience:
•	Technology skills
•	Strong Word, PowerPoint and Excel skills
•	Bachelors Degree or equivalent training/experience
Essential Personal Attributes:
•	Interest in PMO/Project Management as a career path
•	Good organisational skills and a process-driven approach
•	Strong verbal and written communication skills
•	Good analytical capabilities, diligence with accounts and numbers
•	Ability to understand and work in a multi-cultural environment
•	Excellent written and spoken English
•	Flexible and proactive work style
•	Ability to multi-task and work under pressure
Desirable Experience:
•	Previous experience working within a PMO type role
•	Experience of gathering detailed information and consolidating/summarising
•	Business knowledge (Investment Banking/Risk)
•	Exposure to project management processes and methodologies`;

interface IMatchCandidatesTemplateProps {
  requisition: IRequisition | null;
}

const MatchCandidatesTemplate = ({ requisition }: IMatchCandidatesTemplateProps) => {
  const [jobDescription, setJobDescription] = useState<string>('');

  useEffect(() => {
    const fetchDescription = async () => {
      requestWrapper('GET', `/job/getjobdescription?jobId=${requisition?.requisitionId || ''}`)
        .then((response) => {
          setJobDescription(
            removeTagsFromString(response.JobData.Description) ||
              removeTagsFromString(response.JobData.PublicDescription) ||
              '',
          );
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchDescription();
  }, [requisition?.requisitionId]);

  return (
    <main className={styles.container}>
      <AppHeader currentPage={'AI_MATCHING'} />
      <section className={styles.matchCandidateCard} data-test-id='match-candidate-card'>
        <DetailedBackHeader
          requisition={requisition}
          backUrl={'/ai-matching'}
          ownClass={styles.backHeader}
        />
        <aside className={styles.jobDescription} data-test-id='aside-requisition-info'>
          <MatchingDescription
            jobDescription={requisition ? jobDescription : ''}
            setJobDescription={setJobDescription}
          />
        </aside>
        <SelectMatchingCandidates
          jobDescription={jobDescription || ''}
          type={requisition?.type || 'New Match'}
        />
      </section>
    </main>
  );
};

export default MatchCandidatesTemplate;
