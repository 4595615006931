import { useEffect, useState } from 'react';
import AIMatchingRequisitionCard from 'src/components/organisms/AIMatchingRequisitionCard/AIMatchingRequisitionCard';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import { getUUID } from 'src/utils/getUUID';
import styles from './AIMatchingRequisitionsList.module.scss';

interface IAIMatchingRequisitionsListProps {
  requisitionsList: IRequisition[];
}

const AIMatchingRequisitionsList = ({ requisitionsList }: IAIMatchingRequisitionsListProps) => {
  const [newRequisitionsList, setNewRequisitionsList] = useState<IRequisition[]>(requisitionsList);

  useEffect(() => {
    setNewRequisitionsList(requisitionsList);
  }, [requisitionsList]);

  return (
    <ul className={styles.AIMatchingRequisitionsList} data-test-id='recruitments-list'>
      {newRequisitionsList.map((requisition: IRequisition) => (
        <li key={getUUID()}>
          <AIMatchingRequisitionCard requisition={requisition} />
        </li>
      ))}
    </ul>
  );
};

export default AIMatchingRequisitionsList;
