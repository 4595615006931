import styles from './CandidatesSearch.module.scss';

import Icon, { IconType } from 'src/components/utility/Icon/Icon';

interface ICandidatesSearchInput {
  searchCandidateName: string;
  setSearchCandidateName: (name: string) => void;
}

const CandidatesSearch = ({
  searchCandidateName,
  setSearchCandidateName,
}: ICandidatesSearchInput) => (
  <div className={styles.search}>
    <input
      data-test-id='search-input'
      className={styles.searchInput}
      placeholder={'Search by Bullhorn ID'}
      value={searchCandidateName}
      onChange={(event) => {
        setSearchCandidateName(event?.target.value);
      }}
    />
    <Icon icon={IconType.Search} />
  </div>
);

export default CandidatesSearch;
