import { createContext, useState } from 'react';

export interface IAlert {
  id: string;
  type: 'SUCCESS' | 'ERROR';
  message: string;
}

export type AlertContextType = {
  alert: IAlert | null;
  addAlert: (alert: IAlert) => void;
  removeAlert: () => void;
};

const AlertContext = createContext<AlertContextType | null>(null);
AlertContext.displayName = 'AlertContext';

const AlertProvider = ({ children }: { children: JSX.Element[] }) => {
  const [alert, setAlert] = useState<IAlert | null>(null);

  const addAlert = (alert: IAlert) => {
    setAlert(alert);
  };

  const removeAlert = () => {
    setAlert(null);
  };

  return (
    <AlertContext.Provider value={{ alert, addAlert, removeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider };
export default AlertContext;
