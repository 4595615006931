import classNames from 'classnames';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { ICandidatePoll } from 'src/pages/CandidatePollPage/CandidatePoll.def';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './ResignationPageTemplate.module.scss';

const ResignationPageTemplate = (props: ICandidatePoll) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(translate('chooseReason'));
  const [note, setNote] = useState<string>('');
  const navigate = useNavigate();

  const handleResign = (type: 'SKIP' | 'OK') => {
    const data = {
      token: props.token,
      resignReason: type === 'SKIP' ? '' : reason,
      otherReason: type === 'SKIP' ? '' : note,
    };

    requestWrapper('POST', '/client/resign', data)
      .then((res) => {
        navigate('/candidate-poll/success', { state: { type: 'RESIGNATION' } });
      })
      .catch((err) => console.error(err));
  };

  if (props.isResigned) {
    return <Navigate to='/candidate-poll/success' state={{ type: 'RESIGNATION' }} />;
  }

  return (
    <main className={styles.container}>
      <div className={styles.pageCard}>
        <div className={styles.header}>
          <BrandingLogo ownClass={styles.brandingLogo} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.informationContainer}>
          <div className={styles.information} data-test-id='content'>
            <div className={styles.icon} data-test-id='thumbnail'>
              <Icon icon={IconType.Resignation} />
            </div>
            {!showForm && (
              <div className={styles.confirmContent}>
                <div className={styles.text}>
                  <h1 data-test-id='header'>{translate('resignation')}</h1>
                  <p data-test-id='info'>{translate('resignationInfo')}</p>
                </div>
                <div className={styles.infoWrapper}>
                  <h3 data-test-id='job-title'>{props.title}</h3>
                  <div className={styles.info}>
                    <div data-test-id='company-name' className={styles.companyName}>
                      <Icon icon={IconType.Folder} />
                      {props.companyName}
                    </div>
                  </div>
                </div>
                <p data-test-id='resignation-confirm-question' className={styles.question}>
                  {translate('areYouSureYouWantToEndRecruitment')}
                </p>
                <Button
                  ctaText={translate('confirm')}
                  stylingType='primary'
                  type='button'
                  dataTestId='confirm-button'
                  ownClass={styles.confirmButton}
                  onClick={() => {
                    setShowForm(true);
                  }}
                />
              </div>
            )}
            {showForm && (
              <div className={styles.formContent}>
                <div className={styles.text}>
                  <h1 data-test-id='header'>{translate('resignation')}</h1>
                </div>
                <p className={styles.question} data-test-id='reason-question'>
                  {translate('resignationReasonQuestion')}
                </p>
                <div className={styles.formWrapper}>
                  <div>
                    <p>{translate('provideReasonLabel')}</p>
                    <select
                      data-test-id='select-input-reason'
                      className={styles.select}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    >
                      <option
                        className={styles.placeholder}
                        value={translate('chooseReason')}
                        hidden
                      >
                        {translate('chooseReason')}
                      </option>
                      <option value={translate('reasonOption1')}>
                        {translate('reasonOption1')}
                      </option>
                      <option value={translate('reasonOption2')}>
                        {translate('reasonOption2')}
                      </option>
                      <option value={translate('reasonOption3')}>
                        {translate('reasonOption3')}
                      </option>
                    </select>
                  </div>
                  <div>
                    <p>{translate('otherReason')}</p>
                    <div className={styles.textarea}>
                      <textarea
                        data-test-id='input'
                        name='note'
                        placeholder={translate('enterYourNote')}
                        value={note}
                        maxLength={250}
                        onChange={(event) => {
                          setNote(event?.target.value);
                        }}
                      />
                      <p className={classNames(note.length >= 250 && styles.charactersLimit)}>
                        {note.length}/250 {translate('characters')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.actionButtons} data-test-id='actions-buttons'>
                  <Button
                    ctaText={translate('skip')}
                    stylingType='secondary'
                    type='button'
                    dataTestId='skip-button'
                    ownClass={styles.skipButton}
                    onClick={() => {
                      handleResign('SKIP');
                    }}
                  />
                  <Button
                    ctaText={translate('ok')}
                    stylingType='primary'
                    type='button'
                    dataTestId='confirm-button'
                    ownClass={styles.confirmButton}
                    onClick={() => {
                      handleResign('OK');
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {/* <div className={styles.footer}>
            {translate('resignationFooterInfo')} <a href='tel:+48555444333'> +48 555 444 333</a>
          </div> */}
        </div>
      </div>
    </main>
  );
};

export default ResignationPageTemplate;
