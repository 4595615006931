import { Quill } from 'react-quill';

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      Button: buttonHandle,
    },
  },
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

// Quill Toolbar component
export const EditorToolbar = () => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <select className='ql-header' defaultValue='3'>
        <option value='1'>Heading</option>
        <option value='3'>Normal</option>
        <option value='5'>Smaller</option>
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
    </span>
    <span className='ql-formats'>
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
    </span>
    <span className='ql-formats'>
      <button className='ql-blockquote' />
    </span>
    <span className='ql-formats'>
      <select className='ql-align' />
      <select className='ql-color' />
    </span>
    <span className='ql-formats'>
      <button className='ql-link' />
    </span>
    <span className='ql-formats'>
      <button className='ql-Button'>Button</button>
    </span>
  </div>
);

function buttonHandle(this: any) {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, 'button[primary/secondary,title,url,color,text-color]');
}

export default EditorToolbar;
