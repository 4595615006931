import { Fragment } from 'react';
import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import HistoryEvent from 'src/components/molecules/HistoryEvent/HistoryEvent';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRequisitionHistoryPageData } from 'src/pages/RequisitionHistoryPage/RequisitionHistoryPage.def';
import { getUUID } from 'src/utils/getUUID';
import styles from './RequisitionHistoryPageTemplate.module.scss';

const RequisitionHistoryPageTemplate = ({
  events,
  jobName,
  jobId,
  companyName,
}: IRequisitionHistoryPageData) => (
  <main className={styles.container}>
    <AppHeader currentPage='REQUISITIONS' />
    <section className={styles.card} data-test-id='requisitions-history-card'>
      <BackHeader
        title={translate('history')}
        backUrl={'/requisitions'}
        ownClass={styles.backHeader}
        clearPage
      />
      <div className={styles.info}>
        <h2>{jobName}</h2>
        <ul className={styles.requisitionDetailsList}>
          <li data-test-id='requisition-company-name'>
            <Icon icon={IconType.Folder} /> {companyName}
          </li>
          <li>
            <Icon icon={IconType.RequisitionId} />{' '}
            <span data-test-id='requisition-id'>
              <span className={styles.label} data-test-id='requisition-id-label'>
                {translate('requisitionID')}:
              </span>{' '}
              {jobId}
            </span>
          </li>
        </ul>
      </div>

      <ul className={styles.content} data-test-id='card-content'>
        {events.map((event) => (
          <Fragment key={getUUID()}>
            <HistoryEvent {...event} />
          </Fragment>
        ))}
      </ul>
    </section>
    <Pagination />
  </main>
);

export default RequisitionHistoryPageTemplate;
