import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import { IRecruiter } from 'src/pages/RecruitmentPage/Recruitment.def';
import styles from './SaveMeetingEditionModal.module.scss';

interface ISaveMeetingEditionModalProps {
  onClose: () => void;
  handleSave: () => void;
}

const SaveMeetingEditionModal = ({ onClose, handleSave }: ISaveMeetingEditionModalProps) => (
  <div className={styles.modal}>
    <div className={styles.modalInfo}>
      <div className={styles.thumbnail} data-test-id='thumbnail'>
        <Icon icon={IconType.Save} />
      </div>
      <h3 data-test-id='header'>{translate('saveChanges')}</h3>
    </div>
    <p className={styles.question} data-test-id='question'>
      {translate('saveChangesEditMeetingQuestion')}
    </p>
    <div className={styles.actionButtons} data-test-id='actions-buttons'>
      <Button
        ctaText={translate('dontSave')}
        stylingType='secondary'
        type='button'
        dataTestId='cancel-button'
        ownClass={styles.cancelButton}
        onClick={onClose}
      />
      <Button
        ctaText={translate('yesSave')}
        stylingType='primary'
        type='button'
        dataTestId='confirm-button'
        ownClass={styles.addButton}
        onClick={() => {
          handleSave();
          onClose();
        }}
      />
    </div>
  </div>
);

export default SaveMeetingEditionModal;
