import styles from './PollSummaryModal.module.scss';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';

interface IPollSummaryModalProps {
  onClose: () => void;
}

const PollSummaryModal = ({ onClose }: IPollSummaryModalProps) => (
  <div className={styles.modal} data-test-id='note-modal-content'>
    <div className={styles.icon} data-test-id='modal-thumbnail'>
      <Icon icon={IconType.Tick} />
    </div>
    <div className={styles.modalContent}>
      <div className={styles.text}>
        <h2 data-test-id='modal-header'>{translate('thatsAllThanks')}</h2>
        <p>{translate('yourPollHasBeenSentToRecruiters')}</p>
      </div>

      <Button
        ctaText={translate('OK')}
        stylingType='primary'
        type='button'
        dataTestId='ok-button'
        ownClass={styles.okButton}
        onClick={() => {
          onClose();
        }}
      />
    </div>
  </div>
);

export default PollSummaryModal;
