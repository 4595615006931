import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IMeetingPoolData } from 'src/pages/CreatePollPage/CreatePoll.def';
import { getUUID } from 'src/utils/getUUID';
import styles from './PollSummary.module.scss';

interface IProposedTimes {
  [date: string]: string[];
}

const PollSummary = (props: IMeetingPoolData) => {
  const [showDates, setShowDates] = useState<boolean>(false);

  const getDaysNumber = (): number => {
    const dateArr = props.events.map(
      (ev) =>
        `${ev.dateFrom.toString().split(' ')[0]}${ev.dateFrom.toString().split(' ')[1]}${
          ev.dateFrom.toString().split(' ')[2]
        }${ev.dateFrom.toString().split(' ')[3]}`,
    );

    return new Set(dateArr).size;
  };

  const getHoursInDates = () => {
    let data: IProposedTimes = {};
    props.events.forEach((ev) => {
      const date = dayjs(ev.start).format('dddd, MMMM DD, YYYY');
      if (data[date]) {
        data[date] = [...data[date.toString()], ev.start.toString()];
      } else {
        data = {
          ...data,
          [date]: [ev.start.toString()],
        };
      }
    });
    return data;
  };

  return (
    <div className={styles.pollSummary}>
      <div className={styles.groupInfo}>
        <div className={styles.label}>
          <Icon icon={IconType.Candidates} />
          <p data-test-id='poll-summary-participants-label'>{translate('participants')}</p>
        </div>
        <div className={styles.info} data-test-id='poll-summary-participants'>
          {props.participants.map((participant) => (
            <p
              key={getUUID()}
              data-test-id='poll-summary-participant'
            >{`${participant.firstName} ${participant.lastName}`}</p>
          ))}
        </div>
      </div>
      <div className={styles.groupInfoDuration}>
        <div className={styles.label}>
          <Icon icon={IconType.Clock} />
          <p data-test-id='poll-summary-duration-label'>{translate('duration')}</p>
        </div>
        <div className={styles.info}>
          <p data-test-id='poll-summary-duration'>{`${props.duration} min`}</p>
        </div>
      </div>
      <div className={styles.groupInfoProposedDates}>
        <div className={styles.label}>
          <Icon icon={IconType.CalendarPurple} />
          <p data-test-id='poll-summary-proposed-dates-label'>{translate('proposedDates')}</p>
        </div>
        <div className={styles.timesContainer}>
          <button
            type='button'
            className={classNames(styles.timesButton, showDates && styles.timesButtonActive)}
            onClick={() => {
              setShowDates(!showDates);
            }}
            data-test-id='poll-summary-proposed-dates-button'
          >
            {`${props.events.length} ${translate('timesIn')} ${getDaysNumber()} ${
              getDaysNumber() > 1 ? translate('days') : translate('day')
            }`}
            <Icon icon={IconType.ArrowDownBlack} />
          </button>
          {showDates && (
            <div className={styles.datetimesContainer} data-test-id='poll-summary-proposed-dates'>
              {Object.entries(getHoursInDates()).map(([day, times]) => (
                <div className={styles.date} key={getUUID()}>
                  <p className={styles.dateTitle} data-test-id='poll-summary-proposed-day'>
                    {day}
                  </p>
                  <ul className={styles.times} data-test-id='poll-summary-proposed-day-times'>
                    {times.sort().map((time) => (
                      <li key={getUUID()} data-test-id='poll-summary-proposed-time'>
                        {dayjs(time).format('HH:mm')}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PollSummary;
