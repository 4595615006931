import type { IClientPoll, IClientPollResponseData } from './ClientPoll.def';

const prepareClientPollPageData = (clientPollResponse: IClientPollResponseData): IClientPoll => ({
  token: clientPollResponse.Token,
  title: clientPollResponse.Title,
  description: clientPollResponse.Description,
  companyName: clientPollResponse.CompanyName,
  votesCast: clientPollResponse.VotesCast,
  votesTotal: clientPollResponse.VotesTotal,
  duration: clientPollResponse.Duration,
  timezone: clientPollResponse.TimeZone,
  additionalInformation: clientPollResponse.AdditionalInformation,
  minimumTimespansToSelect: clientPollResponse.MinimumTimespansToSelect,
  isCompleted: clientPollResponse.IsCompleted,
  isCanceled: clientPollResponse.IsCanceled,
  timeSpans: clientPollResponse.TimeSpans.map((timespan) => ({
    id: timespan.Id,
    dateFrom: timespan.DateFrom,
    dateTo: timespan.DateTo,
    votesCount: timespan.VotesCount,
    selected: timespan.Selected,
  })),
  selectedCandidatesCount: clientPollResponse.SelectedCandidatesCount,
});

export default prepareClientPollPageData;
