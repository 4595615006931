import { createContext, useContext, useState } from 'react';

import { dictionaryList, languageOptions } from 'src/lang/index';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
});

// it provides the language context to app
export const LanguageProvider = ({ children }: { children: JSX.Element }) => {
  const defaultLanguage = localStorage.getItem('rcml-lang');
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en');

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    changeUserLanguage: (selected: string) => {
      const newLanguage = selected in languageOptions ? selected : 'en';
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    },
  };

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
};

// get text according to id & current language
export const translate = (tid: string) => {
  const languageContext = useContext(LanguageContext);

  return languageContext.dictionary[tid] || tid;
};
