import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import RecruitmentDetailsPageTemplate from 'src/components/templates/RecruitmentDetailsPageTemplate/RecruitmentDetailsPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import type { IRecruitmentResignationDetails } from 'src/pages/RecruitmentPage/Recruitment.def';
import { requestWrapper } from 'src/utils/requestWrapper';
import prepareEditRecruitmentPageData from './prepareRecruitmentDetailsPageData';

const RecruitmentDetailsPage = () => {
  const [pageData, setPageData] = useState<IRecruitmentResignationDetails | null>(null);

  const { id } = useParams();
  useADAccessToken();

  useEffect(() => {
    requestWrapper('GET', `/interview/details?id=${id}`)
      .then((response) => setPageData(prepareEditRecruitmentPageData(response)))
      .catch((err) => console.error(err));
  }, [id]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <RecruitmentDetailsPageTemplate {...pageData} />;
};

export default RecruitmentDetailsPage;
