import { useState } from 'react';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './AddLinkModal.module.scss';

interface IAddLinkModalProps {
  onClose: () => void;
  handleSave: (link: string) => void;
}

const AddLinkModal = ({ onClose, handleSave }: IAddLinkModalProps) => {
  const [link, setLink] = useState<string>('');

  return (
    <div className={styles.modal} data-test-id='modal-content'>
      <div className={styles.icon} data-test-id='modal-thumbnail'>
        <Icon icon={IconType.Link} />
      </div>
      <div className={styles.modalContent}>
        <h2 data-test-id='modal-header'>{translate('addALink')}</h2>

        <div data-test-id='modal-text-input' className={styles.input}>
          <label data-test-id='input-label' htmlFor='link'>
            {translate('addALinkToTheMeeting')}
          </label>
          <input
            data-test-id='input'
            name='note'
            placeholder={translate('addALinkToTheMeeting')}
            value={link}
            onChange={(event) => {
              setLink(event?.target.value);
            }}
          />
        </div>

        <div className={styles.actionButtons} data-test-id='actions-buttons'>
          <Button
            ctaText={translate('cancel')}
            stylingType='secondary'
            type='button'
            dataTestId='cancel-button'
            ownClass={styles.cancelButton}
            onClick={onClose}
          />
          <Button
            ctaText={translate('addALink')}
            stylingType='primary'
            type='button'
            dataTestId='confirm-button'
            ownClass={styles.addButton}
            onClick={() => {
              handleSave(link);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddLinkModal;
