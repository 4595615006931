import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CalendarPageTemplate from 'src/components/templates/CalendarPageTemplate/CalendarPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';

const CalendarPage = () => {
  const location = useLocation();
  useADAccessToken();

  return <CalendarPageTemplate {...location.state} />;
};

export default CalendarPage;
