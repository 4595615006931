import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CandidatesDetails.module.scss';
import type { ICandidate, IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import Button from 'src/components/atoms/Button/Button';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import CandidateRow from 'src/components/organisms/CandidateRow/CandidateRow';
import { translate } from 'src/contexts/Language';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import useAlertTexts from 'src/hooks/useAlertTexts';
import { useAppDispatch } from 'src/store/reduxHooks';
import { changeRequisitionNote } from 'src/store/requisitions/requisitionsSlice';

interface ICandidatesTableProps {
  requisition: IRequisition;
  candidatesList: ICandidate[];
  type: string;
}

const CandidatesDetails = ({ requisition, candidatesList, type }: ICandidatesTableProps) => {
  const [checkAll, setCheckAll] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<ICandidate[]>(candidatesList);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { anErrorOccured } = useAlertTexts();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const redirectToCreatePoll = () => {
    const chosenCandidates = candidates.filter((candidate) => candidate.selected);
    if (chosenCandidates.length > 0) {
      navigate('/create-poll', { state: { candidates: chosenCandidates, requisition } });
    } else {
      console.error('No candidates chosen!');
    }
  };

  const handleNoteSave = (candidateNote: string, candidate: ICandidate) => {
    setLoading(true);
    requestWrapper('POST', '/job/updatejobdetails', {
      id: requisition.id,
      shortList: [{ ...candidate, note: candidateNote }],
    })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });

    dispatch(
      changeRequisitionNote({
        requisitionId: requisition.id,
        candidateId: candidate.candidateId,
        note: candidateNote,
      }),
    );
  };

  const handleCheckAllClick = () => {
    setCheckAll(!checkAll);

    setCandidates(
      candidates.map((candidate) => ({
        ...candidate,
        selected: candidate.questionnaireStatusCache !== 'NoQuestionnaire' ? false : !checkAll,
      })),
    );
  };

  const handleCheck = (id: number) => {
    const newCandidates = candidates.map((candidate, index) =>
      id === index ? { ...candidate, selected: !candidates[index].selected } : { ...candidate },
    );

    const selectedCandidatesNumber = newCandidates.filter((candidate) => candidate.selected).length;

    setCandidates([...newCandidates]);
    setCheckAll(selectedCandidatesNumber === newCandidates.length);
  };

  return (
    <div data-test-id='candidates-detailed-list' className={styles.candidatesDetails}>
      <div data-test-id='table-header' className={styles.header}>
        <ul data-test-id='table-header-columns'>
          <li>
            <Checkbox
              dataTestId='candidates-table-header-checkbox'
              checked={checkAll}
              onChange={() => handleCheckAllClick()}
              disabled={false}
            />
          </li>
          <li>{translate('name')}</li>
          <li>{translate('candidateType')}</li>
          <li>{translate('note')}</li>
          <li>{translate('pollStatus')}</li>
          <li>{translate('action')}</li>
        </ul>
      </div>
      <ul className={styles.candidatesList} data-test-id='table-rows-list'>
        {candidates.map((candidate, index) => (
          <li data-test-id='table-row' className={styles.candidateListItem} key={getUUID()}>
            <CandidateRow
              id={requisition.id}
              candidates={candidates}
              setCandidates={setCandidates}
              candidate={candidate}
              handleCheck={() => {
                handleCheck(index);
              }}
              handleNoteSave={(candidateNote: string) => {
                handleNoteSave(candidateNote, candidate);
              }}
              checked={candidate.selected}
              type={type}
            />
          </li>
        ))}
      </ul>
      <Button
        ctaText={translate('createNewPoll')}
        stylingType='primary'
        type='button'
        dataTestId='create-new-poll-button'
        ownClass={styles.newPollButton}
        onClick={() => redirectToCreatePoll()}
      />
      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default CandidatesDetails;
