import classNames from 'classnames';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'src/components/atoms/Button/Button';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import TestSMTPModal from 'src/components/modals/TestSMTPModal/TestSMTPModal';
import SettingsInfo from 'src/components/molecules/SettingsInfo/SettingsInfo';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import Modal from 'src/components/utility/Modal/Modal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useADAccessToken from 'src/hooks/useADAccessToken';
import useAlertTexts from 'src/hooks/useAlertTexts';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './SMTPConfiguration.module.scss';

const SMTPConfiguration = () => {
  useADAccessToken();

  const [pageData, setPageData] = useState<any>(null);
  const [encription, setEncription] = useState<string>('none');
  const [loading, setLoading] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [openTestSMTPModal, setOpenTestSMTPModal] = useState<boolean>(false);

  const selectOptions = [
    { value: 'none', label: 'none' },
    { value: 'SSL', label: 'SSL' },
    { value: 'TLS', label: 'TLS' },
  ];

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { anErrorOccured, smtpConfigurationHasBeenSaved, testEmailHasBeenSent } = useAlertTexts();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fromEmail: pageData?.FromEmail || '',
      replyToEmail: pageData?.ReplyToEmail || '',
      fromName: pageData?.FromName || '',
      serverAddress: pageData?.ServerAddress || '',
      port: pageData?.Port || '',
      username: pageData?.UserName || '',
      password: '',
    },
    onSubmit: (values) => {
      setLoading(true);
      const data = {
        id: 0,
        ...values,
        securityType: encription,
      };
      requestWrapper('POST', '/settings/editsmtpconfiguration', data)
        .then(() => {
          addAlert({
            id: getUUID(),
            type: 'SUCCESS',
            message: smtpConfigurationHasBeenSaved,
          });
          setSaved(true);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          addAlert({
            id: getUUID(),
            type: 'ERROR',
            message: anErrorOccured,
          });
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    setLoading(true);
    requestWrapper('GET', '/settings/getsmtpconfiguration')
      .then((response) => {
        setPageData(response);
        setEncription(response.SecurityType);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <section className={styles.emailEdition} data-test-id='email-edition-card'>
        <aside className={styles.aside}>
          <SettingsInfo
            title={translate('emailAccountEdition')}
            editedBy={pageData?.EditedByName || 'System'}
            editedByEmail={pageData?.EditedByEmail || '-'}
            editionDate={pageData?.EditDate || new Date()}
          />
        </aside>
        <main className={styles.main}>
          <form onSubmit={formik.handleSubmit}>
            <section className={styles.titleEditionSection} data-test-id='sending-email-section'>
              <div>
                <div className={styles.sectionHeader} data-test-id='title-header'>
                  <div className={styles.punction}>1</div>
                  <h3>{translate('emailSending')}</h3>
                </div>
                <p className={styles.additionalTitleInfo}>{translate('emailSendingInfo')}</p>
              </div>
              <div className={styles.radioSendingEmails}>
                <label
                  htmlFor={'fromServer'}
                  className={classNames(styles.radioLabel, styles.disabledRadio)}
                >
                  <input
                    className={styles.radioInput}
                    type='radio'
                    name={'fromServer'}
                    id={'fromServer'}
                    value={'PMI'}
                    disabled={true}
                    // onChange={formik.handleChange}
                  />

                  {translate('sendFromPMI')}
                </label>
                <label htmlFor={'fromServer2'} className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type='radio'
                    name={'fromServer'}
                    id={'fromServer2'}
                    value={'integrated_app'}
                    checked={true}
                    // onChange={formik.handleChange}
                  />

                  {translate('sendFromIntegratedApp')}
                </label>
              </div>
            </section>
            <section className={styles.titleEditionSection} data-test-id='sender-email-address'>
              <div>
                <div className={styles.sectionHeader} data-test-id='title-header'>
                  <div className={styles.punction}>2</div>
                  <h3>{translate('senderEmailAddress')}</h3>
                </div>
                <p className={styles.additionalTitleInfo}>{translate('senderEmailAddressInfo')}</p>
              </div>
              <div className={styles.inputContainer}>
                <input
                  data-test-id='sender-email-input'
                  id='fromEmail'
                  name='fromEmail'
                  onChange={formik.handleChange}
                  placeholder='Type e-mail here...'
                  value={formik.values.fromEmail}
                />
              </div>
            </section>
            <section className={styles.titleEditionSection} data-test-id='receiver-email-address'>
              <div>
                <div className={styles.sectionHeader} data-test-id='title-header'>
                  <div className={styles.punction}>3</div>
                  <h3>{translate('emailAddressForReply')}</h3>
                </div>
                <p className={styles.additionalTitleInfo}>
                  {translate('emailAddressForReplyInfo')}
                </p>
              </div>
              <div className={styles.inputContainer}>
                <input
                  data-test-id='replyToEmail-input'
                  id='replyToEmail'
                  name='replyToEmail'
                  onChange={formik.handleChange}
                  placeholder='Type e-mail here...'
                  value={formik.values.replyToEmail}
                />
              </div>
            </section>
            <section className={styles.titleEditionSection} data-test-id='sender-name'>
              <div>
                <div className={styles.sectionHeader} data-test-id='title-header'>
                  <div className={styles.punction}>4</div>
                  <h3>{translate('senderName')}</h3>
                </div>
                <p className={styles.additionalTitleInfo}>{translate('senderNameInfo')}</p>
              </div>
              <div className={styles.inputContainer}>
                <input
                  data-test-id='sender-name-input'
                  id='fromName'
                  name='fromName'
                  onChange={formik.handleChange}
                  placeholder='Type name here...'
                  value={formik.values.fromName}
                />
              </div>
            </section>
            <section className={styles.titleEditionSection} data-test-id='smtp-address'>
              <div>
                <div className={styles.sectionHeader} data-test-id='title-header'>
                  <div className={styles.punction}>5</div>
                  <h3>{translate('outgoingSMTPServer')}</h3>
                </div>
                <p className={styles.additionalTitleInfo}>{translate('outgoingSMTPServerInfo')}</p>
              </div>
              <div className={styles.inputContainer}>
                <input
                  data-test-id='smtp-address-input'
                  id='serverAddress'
                  name='serverAddress'
                  onChange={formik.handleChange}
                  placeholder='Type smtp address here...'
                  value={formik.values.serverAddress}
                />
              </div>
            </section>

            <div className={styles.gridSections}>
              <section className={styles.titleEditionSection} data-test-id='port'>
                <div>
                  <div className={styles.sectionHeader} data-test-id='title-header'>
                    <div className={styles.punction}>6</div>
                    <h3>{translate('port')}</h3>
                  </div>
                  <p className={styles.additionalTitleInfo}>{translate('portInfo')}</p>
                </div>
                <div className={styles.inputContainer}>
                  <input
                    data-test-id='port-input'
                    id='port'
                    name='port'
                    onChange={formik.handleChange}
                    placeholder='Type port number here...'
                    value={formik.values.port}
                  />
                </div>
              </section>
              <section className={styles.titleEditionSection} data-test-id='encryption'>
                <div>
                  <div className={styles.sectionHeader} data-test-id='title-header'>
                    <div className={styles.punction}>7</div>
                    <h3>{translate('encryption')}</h3>
                  </div>
                  <p className={styles.additionalTitleInfo}>{translate('encryptionInfo')}</p>
                </div>
                <div className={styles.inputContainer}>
                  <Select
                    defaultValue={{ value: encription, label: encription } || selectOptions[0]}
                    options={selectOptions}
                    className={styles.select}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#F9F9F9',
                        primary: '#ad79fa',
                      },
                    })}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: '40px',
                      }),

                      valueContainer: (provided) => ({
                        ...provided,
                        height: '40px',
                        padding: '0 12px',
                      }),

                      input: (provided) => ({
                        ...provided,
                        margin: '-2px',
                      }),
                      indicatorsContainer: (provided) => ({
                        ...provided,
                        height: '40px',
                      }),
                    }}
                    onChange={(selectedOption) => {
                      setEncription(selectedOption?.value || 'none');
                    }}
                  />
                </div>
              </section>
              <section className={styles.titleEditionSection} data-test-id='login'>
                <div>
                  <div className={styles.sectionHeader} data-test-id='title-header'>
                    <div className={styles.punction}>8</div>
                    <h3>{translate('login')}</h3>
                  </div>
                  <p className={styles.additionalTitleInfo}>{translate('loginInfo')}</p>
                </div>
                <div className={styles.inputContainer}>
                  <input
                    data-test-id='login-input'
                    id='username'
                    name='username'
                    onChange={formik.handleChange}
                    placeholder='Type your login here...'
                    value={formik.values.username}
                  />
                </div>
              </section>
              <section className={styles.titleEditionSection} data-test-id='password'>
                <div>
                  <div className={styles.sectionHeader} data-test-id='title-header'>
                    <div className={styles.punction}>9</div>
                    <h3>{translate('password')}</h3>
                  </div>
                  <p className={styles.additionalTitleInfo}>{translate('passwordInfo')}</p>
                </div>

                <div className={styles.inputContainer}>
                  <input
                    data-test-id='password-input'
                    id='password'
                    name='password'
                    onChange={formik.handleChange}
                    type='password'
                    value={formik.values.password}
                  />
                </div>
              </section>
            </div>

            <section className={styles.buttons} data-test-id='form-buttons'>
              <Button
                dataTestId='test-connection-button'
                ctaText={translate('testConnection')}
                type='button'
                stylingType='secondary'
                disabled={!saved}
                ownClass={styles.testBtn}
                onClick={() => {
                  setOpenTestSMTPModal(true);
                }}
              />

              <Button
                dataTestId='save-button'
                ctaText={translate('save')}
                type='submit'
                stylingType='primary'
                ownClass={styles.saveBtn}
              />
            </section>
          </form>
        </main>
      </section>
      <LoaderModal isOpen={loading} />
      <Modal
        handleClose={() => setOpenTestSMTPModal(false)}
        isOpen={openTestSMTPModal}
        dataTestId='add-link-modal'
      >
        <TestSMTPModal
          onClose={() => {
            setOpenTestSMTPModal(false);
          }}
          handleSave={(email) => {
            setLoading(true);

            requestWrapper('POST', '/settings/editsmtpconfiguration', { email })
              .then(() => {
                addAlert({
                  id: getUUID(),
                  type: 'SUCCESS',
                  message: testEmailHasBeenSent,
                });
                setLoading(false);
              })
              .catch((e) => {
                console.error(e);
                addAlert({
                  id: getUUID(),
                  type: 'ERROR',
                  message: anErrorOccured,
                });
                setLoading(false);
              });
          }}
        />
      </Modal>
    </>
  );
};

export default SMTPConfiguration;
