import dayjs from 'dayjs';
import type { FormikHelpers } from 'formik';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Button from 'src/components/atoms/Button/Button';
import FormInput from 'src/components/atoms/FormInput/FormInput';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type { ISetURLPageData } from 'src/pages/SetURLPage/SetURLPage.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import * as Yup from 'yup';
import styles from './SetURLPageTemplate.module.scss';

const SetURLPageTemplate = ({
  token,
  jobTitle,
  candidateFirstName,
  candidateLastName,
  selectedDateFrom,
  selectedDateTo,
  isUrlSet,
  isCanceled,
}: ISetURLPageData) => {
  const linkSchema = Yup.object().shape({
    link: Yup.string().required(translate('pleaseProvideALink')),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { anErrorOccured } = useAlertTexts();
  const { addAlert } = useContext(AlertContext) as AlertContextType;

  const handleSave = (url: string) => {
    setLoading(true);
    const data = {
      Token: token,
      Url: url,
    };
    requestWrapper('POST', `/client/setinterviewurl?Token=${token}&Url=${url}`, data)
      .then(() => {
        setLoading(false);
        navigate('/client-set-url/success');
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  if (isUrlSet) {
    return <Navigate to='/client-poll/success' state={{ type: 'ADD_LINK_EXPIRED' }} />;
  }

  if (isCanceled) {
    return <Navigate to='/client-poll/success' state={{ type: 'CANCELED' }} />;
  }

  return (
    <main className={styles.container}>
      <LoaderModal isOpen={loading} />
      <div className={styles.pageCard}>
        <div className={styles.header}>
          <BrandingLogo ownClass={styles.brandingLogo} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.informationContainer}>
          <div className={styles.information} data-test-id='content'>
            <div className={styles.icon} data-test-id='thumbnail'>
              <Icon icon={IconType.Link} />
            </div>
            <div className={styles.content}>
              <h1 data-test-id='header'>{translate('addALinkToTheMeeting')}</h1>
              <Formik
                initialValues={{ link: '' }}
                validationSchema={linkSchema}
                onSubmit={(
                  values: { link: string },
                  { setSubmitting }: FormikHelpers<{ link: string }>,
                ) => {
                  handleSave(values.link);
                  setSubmitting(false);
                }}
              >
                {({ errors }) => (
                  <Form className={styles.linkForm}>
                    <div className={styles.addLink}>
                      <FormInput
                        dataTestId='add-link-input'
                        name='link'
                        label={translate('addALink')}
                        type='text'
                        placeholder={translate('linkToTheOnlineMeeting')}
                        ownClass={styles.input}
                      />
                      <div className={styles.info}>
                        <h3 data-test-id='job-title'>{jobTitle}</h3>
                        <p data-test-id='candidate'>
                          <span data-test-id='candidate-label'>
                            <Icon icon={IconType.Candidates} /> {translate('candidate')}:
                          </span>
                          {`${candidateFirstName} ${candidateLastName}`}
                        </p>
                        <p data-test-id='meeting-date' className={styles.meetingInfo}>
                          <span data-test-id='meeting-date-label'>
                            <Icon icon={IconType.Clock} /> {translate('meeting')}:
                          </span>
                          {`${dayjs(selectedDateFrom).utc(false).format('DD.MM.YYYY')}, ${dayjs(
                            selectedDateFrom,
                          )
                            .utc(false)
                            .format('H:mm')} - ${dayjs(selectedDateTo)
                            .utc(false)
                            .utc(false)
                            .format('H:mm')}`}
                        </p>
                      </div>
                    </div>

                    <Button
                      dataTestId='add-link-button'
                      ctaText={translate('addALink')}
                      type='submit'
                      stylingType='primary'
                      ownClass={styles.button}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SetURLPageTemplate;
