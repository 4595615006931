import dayjs from 'dayjs';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './CandidatePollSuccessTemplate.module.scss';

interface ICandidatePollSuccessTemplateProps {
  dateFrom: Date;
  dateTo: Date;
  type: 'SUCCESS' | 'NO_DATE' | 'VOTED' | 'EXPIRED' | 'RESIGNATION' | 'CANCELED';
}

const CandidatePollSuccessTemplate = ({
  dateFrom,
  dateTo,
  type,
}: ICandidatePollSuccessTemplateProps) => {
  let info = '';
  let title = '';
  let expired = false;

  switch (type) {
    case 'SUCCESS': {
      info = translate('candidatePollSuccessInfo');
      title = translate('thankYou');
      break;
    }
    case 'NO_DATE': {
      info = translate('candidatePollNoDateInfo');
      title = translate('thankYou');
      break;
    }
    case 'VOTED': {
      info = translate('pollVotedInfo');
      title = translate('youHaveAlreadyVoted');
      break;
    }
    case 'EXPIRED': {
      info = translate('youCannotVoteAnymore');
      title = translate('thisPollHasExpired');
      expired = true;
      break;
    }
    case 'CANCELED': {
      info = translate('youWillReceiveALinkToANewPoll');
      title = translate('sorryThisPollWasCanceled');
      expired = true;
      break;
    }
    case 'RESIGNATION': {
      info = translate('yourRecruitmentProcessHasBeenClosed');
      title = translate('thankYou');
      break;
    }
    default: {
      info = translate('candidatePollSuccessInfo');
      title = translate('thankYou');
    }
  }

  return (
    <main className={styles.container}>
      <div className={styles.pageCard}>
        <div className={styles.header}>
          <BrandingLogo ownClass={styles.brandingLogo} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.informationContainer}>
          <div className={styles.information} data-test-id='content'>
            <div className={styles.icon} data-test-id='thumbnail'>
              {expired && <Icon icon={IconType.ClosePurple} />}
              {!expired && <Icon icon={IconType.Tick} />}
            </div>
            <div className={styles.content}>
              <div className={styles.text}>
                <h1 data-test-id='header'>{title}</h1>
                {type === 'SUCCESS' && (
                  <div className={styles.selectedData}>
                    <p data-test-id='selected-date-label'>{translate('selectedDate')}</p>
                    <h2 data-test-id='selected-date'>
                      {dayjs(dateFrom).format('dddd, MMMM DD')}{' '}
                      <span>{`${dayjs(dateFrom).format('H:mm')} - ${dayjs(dateTo).format(
                        'H:mm',
                      )}`}</span>
                    </h2>
                  </div>
                )}
                <p data-test-id='info'>{info}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CandidatePollSuccessTemplate;
