import { useEffect, useState } from 'react';
import RecruitmentsPageTemplate from 'src/components/templates/RecruitmentPageTemplate/RecruitmentPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import { changePageCount } from 'src/store/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';

import { requestWrapper } from 'src/utils/requestWrapper';
import prepareRecruitmentPageData from './prepareRecuritmentPageData';
import type { IRecruitmentPageData, TRecruitmentTabs } from './Recruitment.def';
import { RecruitmentTabs } from './Recruitment.def';

const RecruitmentPage = () => {
  const [pageData, setPageData] = useState<IRecruitmentPageData | null>(null);
  const dispatch = useAppDispatch();
  useADAccessToken();

  const { page, search, tab } = useAppSelector((state) => state.pagination);

  useEffect(() => {
    requestWrapper('POST', '/interview/list', {
      pageIndex: page,
      generalSearch: search,
      type: RecruitmentTabs[tab as TRecruitmentTabs],
    })
      .then((response) => {
        dispatch(changePageCount(response.TotalPageCount));
        setPageData(prepareRecruitmentPageData(response));
      })
      .catch((err) => console.error(err));
  }, [dispatch, page, search, tab]);

  // useEffect(() => {
  //   setPage(1);
  // }, [activeTab]);

  return (
    <RecruitmentsPageTemplate
      infoCard={pageData?.infoCard || null}
      recruitmentsList={pageData?.recruitmentsList || null}
    />
  );
};

export default RecruitmentPage;
