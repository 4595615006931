import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/store/reduxHooks';

const useScrollToTop = () => {
  const { pathname } = useLocation();
  const { page } = useAppSelector((state) => state.pagination);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [pathname, page]);

  return null;
};

export default useScrollToTop;
