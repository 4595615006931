import classNames from 'classnames';
import styles from './Checkbox.module.scss';

interface ICheckboxProps {
  checked: boolean;
  onChange: () => void;
  dataTestId: string;
  disabled: boolean;
  ownClass?: string;
}

const Checkbox = ({ dataTestId, checked, onChange, disabled, ownClass }: ICheckboxProps) => (
  <input
    data-test-id={dataTestId}
    className={classNames(styles.checkbox, ownClass)}
    type='checkbox'
    checked={checked}
    onChange={onChange}
    disabled={disabled}
  />
);

export default Checkbox;
