import styles from './CardCandidatesList.module.scss';
import type { ICandidate } from 'src/pages/RequisitionsPage/Requisitions.def';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import { getUUID } from 'src/utils/getUUID';

interface ICardCandidatesListProps {
  candidatesList: ICandidate[];
}

const CardCandidatesList = ({ candidatesList }: ICardCandidatesListProps) => (
  <div className={styles.candidatesNames} data-test-id='candidates-list-section'>
    <p className={styles.candidatesLabel} data-test-id='candidates-label'>
      <Icon icon={IconType.Candidates} />
      <span>{translate('candidates')}:</span>
    </p>
    <ul className={styles.candidatesNamesList} data-test-id='candidates-list'>
      {candidatesList.length > 0 ? (
        candidatesList.map((candidate) => (
          <li
            data-test-id='candidate'
            key={getUUID()}
          >{`${candidate.firstName} ${candidate.lastName}`}</li>
        ))
      ) : (
        <li data-test-id='candidate'>{translate('none')}</li>
      )}
    </ul>
  </div>
);

export default CardCandidatesList;
