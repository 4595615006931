import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import InfoCard from 'src/components/molecules/InfoCard/InfoCard';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import RequisitionCard from 'src/components/organisms/RequisitionCard/RequisitionCard';
import type {
  IRequisition,
  IRequisitionsPageData,
} from 'src/pages/RequisitionsPage/Requisitions.def';
import { useAppSelector } from 'src/store/reduxHooks';
import { getUUID } from 'src/utils/getUUID';
import styles from './RequisitionsTemplate.module.scss';

const RequisitionsTemplate = ({ infoCard }: IRequisitionsPageData) => {
  const requisitionsList = useAppSelector((state) => state.requisitions);

  return (
    <main className={styles.container}>
      <AppHeader currentPage='REQUISITIONS' />
      {!infoCard && <LoadingSpinner />}
      {infoCard && (
        <>
          <InfoCard {...infoCard} />
          <ul data-test-id='requisitions-list' className={styles.requisitionsList}>
            {requisitionsList.map((requisition: IRequisition) => (
              <li key={getUUID()}>
                <RequisitionCard {...requisition} />
              </li>
            ))}
          </ul>
          <Pagination />
        </>
      )}
    </main>
  );
};

export default RequisitionsTemplate;
