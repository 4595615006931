import type {
  IRecruitmentResignationDetails,
  IRecruitmentResignationDetailsResponse,
} from 'src/pages/RecruitmentPage/Recruitment.def';

const prepareRecruitmentDetailsPageData = (
  response: IRecruitmentResignationDetailsResponse,
): IRecruitmentResignationDetails => ({
  id: response.Id,
  jobTitle: response.JobTitle,
  jobDescription: response.JobDescription,
  jobCompanyName: response.CompanyName,
  candidateFirstName: response.CandidateFirstName,
  candidateLastName: response.CandidateLastName,
  candidateEmail: response.CandidateEmail,
  candidateRescheduleReason: response.CandidateRescheduleReason,
  candidateNoSuitableDateComment: response.CandidateNoSuitableDateComment,
  candidateResignReason: response.CandidateResignReason,
  candidateResignOtherReason: response.CandidateResignOtherReason,
  status: response.Status,
  contactPerson: `${response.SupervisorFirstName} ${response.SupervisorLastName}`,
  contactPersonEmail: response.SupervisorEmail,
  recruiters: response.Attendees
    ? response.Attendees.map((recruiter) => ({
        id: recruiter.Id,
        firstName: recruiter.FirstName,
        lastName: recruiter.LastName,
        email: recruiter.Email,
      }))
    : [],
});

export default prepareRecruitmentDetailsPageData;
