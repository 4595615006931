import { translate } from 'src/contexts/Language';

const useEmailTemplates = () => [
  { value: 1, label: translate('[To participants] Define available terms') }, // QuestionnairePublish
  { value: 3, label: translate('[To Candidate] Define available terms') }, // ClientSetCommonAvailability
  { value: 19, label: translate('[To Participants] Reminder: Define the available terms') }, // QuestionnaireReminderForClient
  { value: 18, label: translate('[To Candidate] Reminder: Define the available terms') }, // QuestionnaireReminderForCandidate
  { value: 12, label: translate('[To Contact Person] Ask to generate a link to the meeting') }, // SetInterviewTimeSpan
  {
    value: 17,
    label: translate('[To Contact Person] Reminder: Ask to generate a link to the meeting'),
  }, // LinkReminderForClient
  { value: 11, label: translate('[To Participants] Invitation for a meeting') }, // ClientSetUrlForClient
  { value: 2, label: translate('[To Candidate] Invitation for a meeting') }, // ClientSetUrlForCandidate
  { value: 16, label: translate('[To Participants] Reminder: Invitation for a meeting') }, // InterviewReminderForClient
  { value: 15, label: translate('[To Candidate] Reminder: Invitation for a meeting') }, // InterviewReminderForCandidate
  { value: 13, label: translate('[To Participants] Manually updated time of a meeting') }, // SetInterviewTimeSpanManualForClient
  { value: 14, label: translate('[To Candidate] Manually updated time of a meeting') }, // SetInterviewTimeSpanManualForCandidate
  { value: 21, label: translate('[To Participants] Manually updated link to the meeting') }, // SetInterviewUrlManualForClient
  { value: 20, label: translate('[To Candidate] Manually updated link to the meeting') }, // SetInterviewUrlManualForCandidate
  { value: 23, label: translate('[To Participants] Meeting rescheduled') }, // InterviewRescheduled
  { value: 6, label: translate('[To Participants] Candidate resignation') }, // CandidateResign
  {
    value: 22,
    label: translate('[To Participants] Candidate resignation without a meeting set'),
  }, // CandidateResignNotScheduled
  { value: 9, label: translate('[To Participants] Client resignation') }, // InterviewCancelForClientB
  { value: 10, label: translate('[To Candidate] Client resignation') }, // InterviewCancelForCandidateB
  { value: 7, label: translate('[To Participants] Client resignation  without a meeting set') }, // InterviewCancelForClient
  { value: 8, label: translate('[To Candidate] Client resignation  without a meeting set') }, // InterviewCancelForCandidate
  { value: 90, label: translate('[Bullhorn] The end of recruitment proccess') }, // BullhornSynchronizerRemovesCandidate
];

export default useEmailTemplates;
