export interface IUser {
  username: string;
  name: string;
  email: string;
}

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID || '',
    authority: process.env.REACT_APP_AD_AUTHORITY_URL || '',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  endpoints: {
    api: process.env.REACT_APP_AD_API || '',
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMe: {
    endpoint: 'https://graph.microsoft.com/v1.0/me',
    scopes: ['User.Read'],
  },
  graphGroups: {
    endpoint: 'https://graph.microsoft.com/v1.0/me/memberOf',
    scopes: ['GroupMember.Read.All'],
  },
};

export default msalConfig;
