import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import ReschedulePageTemplate from 'src/components/templates/ReschedulePageTemplate/ReschedulePageTemplate';
import { requestWrapper } from 'src/utils/requestWrapper';

const ReschedulePage = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState<{ token: string; isCompleted: boolean } | null>();
  const navigate = useNavigate();

  useEffect(() => {
    requestWrapper('GET', `/client/rescheduleinfo?token=${id}`)
      .then((res) => {
        if (res.status === 499) {
          res.text().then((txt: string) => {
            console.error(txt);
          });
        } else {
          return res;
        }
      })
      .then((res: { Token: string; CanReschedule: boolean }) => {
        setPageData({ token: id || '', isCompleted: !res.CanReschedule });
      })
      .catch((err) => {
        console.error(err);
        navigate('/candidate-poll/success', { state: { type: 'EXPIRED' } });
      });
  }, [id, navigate]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <ReschedulePageTemplate {...pageData} />;
};

export default ReschedulePage;
