import styles from './AIMatchingRequisitionCard.module.scss';

import classNames from 'classnames';
import AIMatchingRequisitionCardHeader from 'src/components/molecules/AIMatchingRequisitionCardHeader/AIMatchingRequisitionCardHeader';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';

interface IAIMatchingRequisitionCardProps {
  requisition: IRequisition | null;
}

const AIMatchingRequisitionCard = ({ requisition }: IAIMatchingRequisitionCardProps) => {
  if (!requisition) {
    return (
      <section data-test-id='recruitment-card' className={styles.recruitmentCard}>
        <AIMatchingRequisitionCardHeader requisition={requisition} />
      </section>
    );
  }

  return (
    <section data-test-id='recruitment-card' className={styles.recruitmentCard}>
      <AIMatchingRequisitionCardHeader requisition={requisition} />
      <div className={styles.divider}></div>
      <div className={styles.additionalInfo}>
        <p className={classNames(styles.infoItem, styles.bold)} data-test-id='meeting-candidate'>
          <span className={styles.label} data-test-id='meeting-candidate-label'>
            <Icon icon={IconType.Candidates} /> {`${translate('shortlistCandidates')}:`}
          </span>
          {(requisition &&
            requisition.candidatesList
              .map((candidate) => `${candidate.firstName} ${candidate.lastName}`)
              .join(', ')) ||
            '-'}
        </p>
      </div>
    </section>
  );
};

export default AIMatchingRequisitionCard;
