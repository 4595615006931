import { useState } from 'react';
import styles from './RequisitionCard.module.scss';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';

import CardCandidatesList from 'src/components/molecules/CardCandidatesList/CardCandidatesList';
import CardHeader from 'src/components/molecules/RequisitionCardHeader/RequisitionCardHeader';
import CandidatesDetails from 'src/components/organisms/CandidatesDetails/CandidatesDetails';
import { useAppDispatch } from 'src/store/reduxHooks';
import { toggleRequisitionCard } from 'src/store/requisitions/requisitionsSlice';

const RequisitionCard = (requisition: IRequisition) => {
  const { id, title, companyName, type, candidatesList, requisitionId } = requisition;
  const [isCardOpen, setIsCardOpen] = useState<boolean>(requisition.expanded);
  const dispatch = useAppDispatch();

  const handleCardOpenChange = () => {
    dispatch(toggleRequisitionCard(id));
    setIsCardOpen(!isCardOpen);
  };

  return (
    <section data-test-id='requisition-card' className={styles.requisitionCard}>
      <CardHeader
        title={title}
        companyName={companyName}
        id={id}
        requisitionId={requisitionId}
        isCardOpen={isCardOpen}
        handleCardOpenChange={handleCardOpenChange}
      />
      <div className={styles.divider}></div>
      {isCardOpen && (
        <CandidatesDetails requisition={requisition} candidatesList={candidatesList} type={type} />
      )}
      {!isCardOpen && <CardCandidatesList candidatesList={candidatesList} />}
    </section>
  );
};

export default RequisitionCard;
