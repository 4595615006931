import { useMsal } from '@azure/msal-react';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useADAccessToken from 'src/hooks/useADAccessToken';
import useAlertTexts from 'src/hooks/useAlertTexts';
import useEmailTemplates from 'src/hooks/useEmailTemplates';
import type { IEmailCreatorTemplateData } from 'src/pages/SettingsPage/SettingsPage.def';
import { bodyToHtml, htmlToBody } from 'src/pages/SettingsPage/SettingsPage.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import Select from 'react-select';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import classNames from 'classnames';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { TextEditor } from 'src/components/molecules/TextEditor/TextEditor';
import Button from 'src/components/atoms/Button/Button';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import styles from './EmailCreator.module.scss';
import SettingsInfo from 'src/components/molecules/SettingsInfo/SettingsInfo';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';

const emailValidationSchema = Yup.object().shape({
  subject: Yup.string().min(3).max(200).required('Required'),
  body: Yup.string().min(3).required('Required'),
});

const EmailCreator = () => {
  useADAccessToken();

  const [pageData, setPageData] = useState<IEmailCreatorTemplateData | null>(null);
  const [emailTemplateId, setEmailTemplateId] = useState<number>(1);
  const selectOptions = useEmailTemplates();
  const [emailBody, setEmailBody] = useState<string>('');
  const [activeListParameters, setActiveListParameters] = useState<string[]>([]);
  const [activeButtonParameters, setActiveButtonParameters] = useState<string[]>([]);
  const [testEmail, setTestEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const {
    anErrorOccured,
    emailTemplateHasBeenChanged,
    testEmailHasBeenSent,
    emailTemplateHasBeenReturnedToDefault,
  } = useAlertTexts();

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const formik = useFormik({
    initialValues: { subject: pageData?.Subject || '', body: pageData?.Body || '' },
    onSubmit: (values) => {
      setLoading(true);
      const body = `
        <html>
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
            />
            <style type="text/css">
              body {
                color: #474747 !important;
              }

              h1 {
                font-size: 24px;
                font-weight: 600;
                margin: 0;
                color: #474747 !important;
              }
        
              p {
                font-size: 18px;
                font-weight: 400;
                margin: 0;
                color: #474747 !important;
              }
        
              a {
                text-decoration: underline;
                color: #8444e3;
                margin: 0;
              }
        
              ul {
                font-size: 18px;
                font-weight: 400;
                color: #474747 !important;
              }

              h5 {
                font-size: 14px;
                font-weight: 400;
                margin: 0;
              }
        
              header {
                padding: 20px 0px 20px 0px;
                border-bottom: 1px solid rgb(221, 221, 221);
                margin-bottom: 20px;
              }
        
              .email-container {
                width: 100%;
              }
        
              .email-content {
                width: 600px;
                margin: 26px auto;
              }
        
              .email-footer {
                display: flex;
                justify-content: space-between;
        
                padding-top: 14px;
                border-top: 1px solid rgb(221, 221, 221);
                margin-top: 20px;
              }
        
              footer p {
                font-size: 12px;
                font-weight: 400;
                color: #474747 !important;
              }
            </style>
          </head>
          <body>
            <div class="email-container">
              <div class="email-content">
                <header>
                  <a href="https://www.avenga.com/" target="_blank">
                    <img
                      border="0"
                      style="
                        display: block;
                        color: #000000;
                        text-decoration: none;
                        font-family: Helvetica, arial, sans-serif;
                        font-size: 16px;
                        max-width: 80% !important;
                        height: auto !important;
                      "
                      width="240"
                      alt="Avenga - Plan MyInterview"
                      src="https://ci6.googleusercontent.com/proxy/4f-rlh1GbK8hFRDPCjbq9I70DHhfJJZNpBSDMvpTqGwqaWwrAzY4O7X2sa4TVF4Xh0YgCV7qrHVXzfvvEzXMVJ_E-6Zb_MSufOJXD7GrHmuEpNlc4X1hk64i5ZbZnp244AgExVqfoyIIZTZ854wgKyM2Zwa5cJvRvrGXg0hT_Jmv6tfcJw=s0-d-e1-ft#http://cdn.mcauto-images-production.sendgrid.net/14302816304e15ea/a97678db-0ab9-45bb-900d-d6d29adb8a33/516x106.png"
                      data-bit="iit"
                    />
                  </a>
                </header>
        
                ${emailBody}
        
                <footer class="email-footer">
                  <div className="{styles.footerInfo}">
                    <p>© 2022 Avenga.com - All rights reserved.</p>
                    <p>Generała Bohdana Zielińskiego 22</p>
                    <p>30-320 Kraków, Poland</p>
                    <p>Phone: +48(0)123 070 332</p>
                  </div>
                </footer>
              </div>
            </div>
          </body>
        </html>`;

      const newEmailTemplate = {
        type: pageData?.Type,
        subject: values.subject,
        body: body.replace(/(\r\n|\n|\r)/gm, ''),
        parameters: pageData?.Parameters,
        buttons: pageData?.Buttons,
        editDate: new Date(),
        editedByName: activeAccount?.name || '',
        editedByEmail: activeAccount?.username || '',
      };

      requestWrapper('POST', '/Settings/editemailtemplate', newEmailTemplate)
        .then(() => {
          addAlert({
            id: getUUID(),
            type: 'SUCCESS',
            message: emailTemplateHasBeenChanged,
          });
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          addAlert({
            id: getUUID(),
            type: 'ERROR',
            message: anErrorOccured,
          });
          setLoading(false);
        });
    },
    validationSchema: emailValidationSchema,
  });

  useEffect(() => {
    setLoading(true);
    requestWrapper('GET', `/settings/getemailtemplate?emailType=${emailTemplateId}`)
      .then((response) => {
        setPageData({ ...response, Body: response.Body ? htmlToBody(response.Body) : null });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [emailTemplateId]);

  useEffect(() => {
    formik.setValues({ subject: pageData?.Subject || '', body: pageData?.Body || '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData?.Type]);

  useEffect(() => {
    setEmailBody(bodyToHtml(formik.values.body));
  }, [formik.values.body]);

  useEffect(() => {
    setActiveListParameters(
      pageData
        ? pageData.Parameters.filter(
            (param) =>
              formik.values.body.indexOf(`{{${param}}}`) > 0 ||
              formik.values.subject.indexOf(`{{${param}}}`) > 0,
          )
        : activeListParameters,
    );

    setActiveButtonParameters(
      pageData
        ? pageData.Buttons.filter(
            (param) =>
              formik.values.body.indexOf(`{{${param}}}`) > 0 ||
              formik.values.subject.indexOf(`{{${param}}}`) > 0,
          )
        : activeButtonParameters,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageData?.Buttons,
    pageData?.Parameters,
    emailTemplateId,
    formik.values.body,
    formik.values.subject,
  ]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <section className={styles.emailEdition} data-test-id='email-edition-card'>
        <aside className={styles.aside}>
          <SettingsInfo
            title={translate('emailEdition')}
            editedBy={pageData.EditedByName}
            editedByEmail={pageData.EditedByEmail}
            editionDate={pageData.EditDate}
          />
          <p>{translate('thisEmailShouldProvide')}:</p>
          <ul className={styles.itemsList}>
            {pageData.Parameters.map((param) =>
              activeListParameters.includes(param) ? (
                <li
                  id={`l-${param}`}
                  key={getUUID()}
                  className={classNames(styles.item, styles.activeItem)}
                  onClick={() => {
                    navigator.clipboard.writeText('{{' + param + '}}');
                  }}
                >
                  {'{{' + param + '}}'}
                  <Icon icon={IconType.Tick} />
                </li>
              ) : (
                <li
                  id={`l-${param}`}
                  key={getUUID()}
                  className={styles.item}
                  onClick={() => {
                    navigator.clipboard.writeText('{{' + param + '}}');
                  }}
                >
                  {'{{' + param + '}}'}
                </li>
              ),
            )}
          </ul>
          {pageData.Buttons.length > 0 && (
            <>
              <p className={styles.additionalParagraph}>
                {translate('buttonsWhichShouldBeProvided')}:
              </p>
              <ul className={styles.itemsList}>
                {pageData.Buttons.map((param) =>
                  activeButtonParameters.includes(param) ? (
                    <li
                      id={`b-${param}`}
                      key={getUUID()}
                      className={classNames(styles.item, styles.activeItem)}
                      onClick={() => {
                        navigator.clipboard.writeText('{{' + param + '}}');
                      }}
                    >
                      {'{{' + param + '}}'}
                      <Icon icon={IconType.Tick} />
                    </li>
                  ) : (
                    <li
                      id={`b-${param}`}
                      key={getUUID()}
                      className={styles.item}
                      onClick={() => {
                        navigator.clipboard.writeText('{{' + param + '}}');
                      }}
                    >
                      {'{{' + param + '}}'}
                    </li>
                  ),
                )}
              </ul>
            </>
          )}
          <p className={styles.infoParagraph}>{translate('clickOnItemToCopyItToClipboard')}.</p>
        </aside>
        <main className={styles.main}>
          <form onSubmit={formik.handleSubmit}>
            <section className={styles.titleEditionSection} data-test-id='template-select-edition'>
              <div className={styles.sectionHeader} data-test-id='title-header'>
                <div className={styles.punction}>1</div>
                <h3>{translate('chooseTypeOfEmailYouWantToEdit')}</h3>
              </div>
              <div className={styles.inputContainer}>
                <Select
                  defaultValue={selectOptions[0]}
                  options={selectOptions}
                  className={styles.select}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#F9F9F9',
                      primary: '#ad79fa',
                    },
                  })}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '40px',
                    }),

                    valueContainer: (provided) => ({
                      ...provided,
                      height: '40px',
                      padding: '0 12px',
                    }),

                    input: (provided) => ({
                      ...provided,
                      margin: '-2px',
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: '40px',
                    }),
                  }}
                  onChange={(selectedOption) => {
                    setEmailTemplateId(selectedOption?.value || 1);
                  }}
                />
              </div>
            </section>
            <section className={styles.titleEditionSection} data-test-id='title-edition'>
              <div className={styles.sectionHeader} data-test-id='title-header'>
                <div className={styles.punction}>2</div>
                <h3>{translate('emailTitle')}</h3>
              </div>
              <div className={styles.inputContainer}>
                <input
                  data-test-id='title-input'
                  id='subject'
                  name='subject'
                  onChange={formik.handleChange}
                  placeholder='Type title of the e-mail here...'
                  value={formik.values.subject}
                />
              </div>
            </section>
            <section className={styles.templateEditionSection} data-test-id='template-edition'>
              <div className={styles.sectionHeader} data-test-id='template-header'>
                <div className={styles.punction}>3</div>
                <h3>{translate('emailContent')}</h3>
              </div>
              <div className={styles.textEditor}>
                <TextEditor
                  setFieldValue={(val) => formik.setFieldValue('body', val)}
                  value={formik.values.body}
                />
              </div>
            </section>
            <section className={styles.buttons} data-test-id='form-buttons'>
              <Button
                dataTestId='preview-button'
                ctaText={translate('backToDefaultTemplate')}
                type='button'
                stylingType='secondary'
                ownClass={styles.previewBtn}
                onClick={() => {
                  setLoading(true);

                  requestWrapper(
                    'GET',
                    `/Settings/disableemailtemplate?emailType=${emailTemplateId}`,
                  )
                    .then(() => {
                      addAlert({
                        id: getUUID(),
                        type: 'SUCCESS',
                        message: emailTemplateHasBeenReturnedToDefault,
                      });
                      setLoading(false);
                    })
                    .catch((e) => {
                      console.error(e);
                      addAlert({
                        id: getUUID(),
                        type: 'ERROR',
                        message: anErrorOccured,
                      });
                      setLoading(false);
                    });
                }}
              />

              <Button
                dataTestId='save-button'
                ctaText={translate('save')}
                type='submit'
                stylingType='primary'
                disabled={!emailBody || !formik.values.subject}
                ownClass={styles.saveBtn}
              />
            </section>
            <section className={styles.testingEmail} data-test-id='test-email-section'>
              <div className={styles.sectionHeader} data-test-id='template-header'>
                <div className={styles.punction}>4</div>
                <h3>{translate('testEmail')}</h3>
              </div>
              <div className={styles.inputContainer}>
                <input
                  data-test-id='test-email-input'
                  onChange={(e) => {
                    setTestEmail(e.target.value);
                  }}
                  placeholder='Type your test e-mail here...'
                  value={testEmail}
                />
                <Button
                  dataTestId='send-test-email-button'
                  ctaText={translate('send')}
                  type='button'
                  stylingType='primary'
                  disabled={!emailBody || !formik.values.subject || !testEmail}
                  ownClass={styles.saveBtn}
                  onClick={() => {
                    setLoading(true);
                    const data = {
                      emailType: emailTemplateId,
                      targetEmail: testEmail,
                    };

                    requestWrapper('POST', '/Settings/sendtestemail', data)
                      .then(() => {
                        addAlert({
                          id: getUUID(),
                          type: 'SUCCESS',
                          message: testEmailHasBeenSent,
                        });
                        setLoading(false);
                      })
                      .catch((e) => {
                        console.error(e);
                        addAlert({
                          id: getUUID(),
                          type: 'ERROR',
                          message: anErrorOccured,
                        });
                        setLoading(false);
                      });
                  }}
                />
              </div>
            </section>
          </form>
        </main>
      </section>
      <section className={styles.emailPreview} id='email-preview'>
        <CardTitle title={translate('emailPreview')} dataTestId='email-preview-card-title' />
        <article className={styles.emailPreviewCard}>
          <header className={styles.emailHeader}>
            <BrandingLogo ownClass={styles.branding} />
          </header>
          <div className={styles.divider}></div>
          <main
            dangerouslySetInnerHTML={{ __html: emailBody }}
            className={styles.emailContent}
          ></main>
          <footer className={styles.emailFooter}>
            <div className={styles.footerInfo}>
              <p>© 2022 Avenga.com - All rights reserved. </p>
              <p>Generała Bohdana Zielińskiego 22</p>
              <p>30-320 Kraków, Poland</p>
              <p>Phone: +48(0)123 070 332</p>
            </div>
          </footer>
        </article>
      </section>
      <LoaderModal isOpen={loading} />
    </>
  );
};

export default EmailCreator;

interface ICardTitleProps {
  title: string;
  dataTestId: string;
}

const CardTitle = ({ title, dataTestId }: ICardTitleProps) => (
  <div className={styles.cardTitle} data-test-id={dataTestId}>
    <h2>{title}</h2>
  </div>
);
