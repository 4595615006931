import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';
import type {
  ICandidateResponseData,
  IRequisition,
  IRequisitionResponseData,
  IRequisitionsResponse,
} from 'src/pages/RequisitionsPage/Requisitions.def';

export const prepareRequisitionsInfoCardData = (
  totalDataCount: number | null,
  lastUpdateDate?: Date,
): IInfoCardData | null => ({
  nameTranslationId: 'requisitions',
  totalCount: totalDataCount || 0,
  type: 'REQUISITIONS',
  tabs: [
    { nameTranslationId: 'allRequisitions', type: 'all' },
    { nameTranslationId: 'pending', type: 'pending' },
    { nameTranslationId: 'active', type: 'active' },
    { nameTranslationId: 'actionRequired', type: 'action_required' },
    { nameTranslationId: 'canceled', type: 'canceled' },
  ],
  lastUpdateDate: lastUpdateDate,
});

export const prepareRequisitionsData = (
  requisitionsResponse: IRequisitionsResponse | null,
): IRequisition[] => {
  if (!requisitionsResponse) {
    return [];
  }

  return requisitionsResponse.Data.map((requisition: IRequisitionResponseData) => ({
    id: requisition.Id,
    requisitionId: requisition.RequisitionId,
    title: requisition.Title,
    companyName: requisition.CompanyName,
    isHot: requisition.IsHot,
    isNew: requisition.IsNew,
    isClosed: requisition.IsClosed,
    type: requisition.Type,
    contactPersonEmail: requisition.ContactPersonEmail,
    contactPerson: requisition.ContactPerson,
    candidatesList: requisition.ShortListCandidates.map((candidate: ICandidateResponseData) => ({
      id: candidate.Id,
      candidateId: candidate.CandidateId,
      firstName: candidate.FirstName,
      lastName: candidate.LastName,
      email: candidate.Email,
      phone: candidate.Phone,
      note: candidate.Note,
      selected: candidate.QuestionnaireStatusCache === 'NoQuestionnaire',
      questionnaireStatusCache: candidate.QuestionnaireStatusCache,
      questionnaireStatusUpdateDate: candidate.QuestionnaireStatusUpdateDate,
    })),
    expanded: false,
  }));
};
