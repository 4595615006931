import { useLocation } from 'react-router-dom';
import MatchCandidatesTemplate from 'src/components/templates/MatchCandidatesTemplate/MatchCandidatesTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';

const MatchCandidatePage = () => {
  const location = useLocation();
  useADAccessToken();

  return <MatchCandidatesTemplate requisition={location.state} />;
};

export default MatchCandidatePage;
