import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import InfoCard from 'src/components/molecules/InfoCard/InfoCard';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import RecruitmentsList from 'src/components/organisms/RecruitmentsList/RecruitmentsList';
import type { IRecruitmentPageData } from 'src/pages/RecruitmentPage/Recruitment.def';
import styles from './RecruitmentPageTemplate.module.scss';

const RecruitmentsPageTemplate = ({ infoCard, recruitmentsList }: IRecruitmentPageData) => (
  <main className={styles.container}>
    <AppHeader currentPage='RECRUITMENT' />
    {!infoCard && <LoadingSpinner />}
    {infoCard && recruitmentsList && (
      <>
        <InfoCard {...infoCard} />
        <RecruitmentsList recruitmentsList={recruitmentsList} />
        <Pagination />
      </>
    )}
  </main>
);

export default RecruitmentsPageTemplate;
