import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Navigate } from 'react-router';
import MsalGroup from 'src/utils/MsalGroup';

interface IPrivateRouteProps {
  component: JSX.Element;
}

const PrivateRoute = ({ component }: IPrivateRouteProps) => (
  <>
    <MsalGroup validAzureAdGroupId='736d49fe-4cd0-44ad-8192-f30f0c9ab2c2'>
      <AuthenticatedTemplate>{component}</AuthenticatedTemplate>
    </MsalGroup>
    <UnauthenticatedTemplate>
      <Navigate to='/login' />
    </UnauthenticatedTemplate>
  </>
);

export default PrivateRoute;
