import PersonShortenInfo from 'src/components/atoms/PersonShortenInfo/PersonShortenInfo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { ICandidate, IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import styles from './RequisitionInfo.module.scss';

interface IRequisitionInfoProps {
  requisition: Partial<IRequisition>;
}

const RequisitionInfo = ({ requisition }: IRequisitionInfoProps) => (
  <div className={styles.requisitionInfo}>
    <h3 data-test-id='requisition-title'>{requisition.title}</h3>
    <ul className={styles.requisitionDetailsList}>
      <li data-test-id='requisition-company-name'>
        <Icon icon={IconType.Folder} /> {requisition.companyName}
      </li>
      <li>
        <Icon icon={IconType.RequisitionId} />{' '}
        <span data-test-id='requisition-id'>
          <span className={styles.label} data-test-id='requisition-id-label'>
            {translate('requisitionID')}:
          </span>{' '}
          {requisition.id}
        </span>
      </li>
      {requisition.contactPerson && (
        <li className={styles.contactPersonListItem}>
          <div className={styles.contactPersonLabel}>
            <Icon icon={IconType.Person} />
            <span className={styles.label} data-test-id='requisition-contact-person-label'>
              {translate('contactPerson')}:
            </span>
          </div>

          <div className={styles.contactPerson}>
            <PersonShortenInfo
              name={requisition.contactPerson || ''}
              email={requisition.contactPersonEmail || ''}
              ownClass={styles.personShortenInfo}
            />
          </div>
        </li>
      )}
    </ul>
  </div>
);

export default RequisitionInfo;
