import { useMsal } from '@azure/msal-react';
import type { IUser } from 'src/App/authConfig';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import styles from './UserThumbnail.module.scss';

interface IUserThumbnail {
  onClick: () => void;
}

const UserThumbnail = ({ onClick }: IUserThumbnail) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  if (!activeAccount) {
    return null;
  }
  const user: IUser = {
    username: activeAccount.username,
    name: activeAccount.name || '',
    email: activeAccount.username,
  };
  const { name, email } = user;

  return (
    <div className={styles.thumbnail} onClick={onClick} data-test-id='user-thumbnail'>
      <div className={styles.initials} data-test-id='user-avatar'>
        {name.split(' ')[0][0] || ''}
        {name.split(' ')[1][0] || ''}
      </div>
      <div className={styles.userName} data-test-id='user-shorten-info'>
        <p className={styles.name} data-test-id='user-name'>
          {name}
        </p>
        <p className={styles.email} data-test-id='user-email'>
          {email}
        </p>
      </div>
      <Icon icon={IconType.ArrowDownBlack} />
    </div>
  );
};

export default UserThumbnail;
