import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import Button from 'src/components/atoms/Button/Button';
import CadidatesShortenInfoList from 'src/components/molecules/CadidatesShortenInfoList/CadidatesShortenInfoList';
import PollSummaryModal from 'src/components/modals/SummaryPollModal/PollSummaryModal';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import PollSummary from 'src/components/organisms/PollSummary/PollSummary';
import RequisitionInfo from 'src/components/organisms/RequisitionInfo/RequisitionInfo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import Modal from 'src/components/utility/Modal/Modal';
import { translate } from 'src/contexts/Language';
import type { IMeetingPoolData } from 'src/pages/CreatePollPage/CreatePoll.def';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './PollSummaryTemplate.module.scss';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import useAlertTexts from 'src/hooks/useAlertTexts';
import { getUUID } from 'src/utils/getUUID';
import dayjs from 'dayjs';
import classNames from 'classnames';

const PollSummaryTemplate = (props: IMeetingPoolData) => {
  const [comments, setComments] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { pollSentSuccessfullyText, anErrorOccured } = useAlertTexts();

  const navigate = useNavigate();

  const publishMeeting = () => {
    setLoading(true);
    const data = {
      id: null,
      jobId: props.requisition.id,
      duration: props.duration,
      timeZone: props.timezone,
      notes: '',
      expireDate: dayjs(props.pollExpirationDate).add(1, 'hour').toDate(),
      additionalInformation: comments,
      isPublished: true,
      timeSpans: props.events.map((event) => ({
        id: event.id,
        dateFrom: event.dateFrom,
        dateTo: event.dateTo,
        votesCount: event.votesCount,
        selected: true,
      })),
      respondents: [
        ...props.participants.map((participant) => ({
          id: 0,
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
        })),
      ],
      skipRespondentVoting: props.skipRespondentVoting,
      candidates: props.candidates,
    };

    requestWrapper('POST', '/Questionnaire/add', data)
      .then(() => {
        setLoading(false);
        setOpenModal(true);
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  useEffect(() => {
    if (!openModal && !firstLoad) {
      addAlert({
        id: getUUID(),
        type: 'SUCCESS',
        message: pollSentSuccessfullyText,
      });
      navigate('/requisitions');
    } else {
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, openModal]);

  return (
    <main className={styles.container}>
      <AppHeader currentPage='REQUISITIONS' />
      <section className={styles.pollSummaryCard} data-test-id='poll-summary-card'>
        <BackHeader
          title={translate('summary')}
          backUrl={'/create-poll/calendar'}
          backState={props}
          ownClass={styles.backHeader}
        />
        <aside className={styles.requisitionInfo} data-test-id='aside-requisition-info'>
          <RequisitionInfo requisition={props.requisition} />
          <div className={styles.divider}></div>
          <CadidatesShortenInfoList candidates={props.candidates} />
        </aside>
        <div className={styles.pollSummary} data-test-id='poll-summary'>
          <PollSummary {...props} />
          <div className={styles.comments} data-test-id='poll-summary-comments-for-participants'>
            <div className={styles.label}>
              <Icon icon={IconType.Comment} />
              <p data-test-id='poll-summary-comments-for-participants-label'>
                {translate('commentsForParticipants')}
              </p>
            </div>
            <div className={styles.textarea}>
              <textarea
                data-test-id='poll-summary-comments-for-participants-textarea'
                name='comments'
                placeholder={translate('enterYourNote')}
                value={comments}
                maxLength={250}
                onChange={(event) => {
                  setComments(event?.target.value);
                }}
              />
              <p className={classNames(comments.length >= 250 && styles.charactersLimit)}>
                {comments.length}/250 {translate('characters')}
              </p>
            </div>
          </div>
          <div className={styles.buttons} data-test-id='actions-buttons'>
            <Button
              dataTestId='back-button'
              ctaText={translate('back')}
              type='button'
              stylingType='secondary'
              ownClass={styles.backButton}
              onClick={() => {
                navigate('/create-poll/calendar', { state: props });
              }}
            />

            <Button
              dataTestId='publish-meeting-button'
              ctaText={translate('publishMeetingPoll')}
              type='submit'
              stylingType='primary'
              ownClass={styles.publishMeetingButton}
              onClick={publishMeeting}
            />
          </div>
        </div>
      </section>

      <LoaderModal isOpen={loading} />

      <Modal
        handleClose={() => {
          setOpenModal(false);
        }}
        isOpen={!!openModal}
        dataTestId='published-meeting-modal'
        modalClass={styles.pollSummaryModal}
      >
        <div className={styles.modal}>
          <PollSummaryModal
            onClose={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </Modal>
    </main>
  );
};

export default PollSummaryTemplate;
