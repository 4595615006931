import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';

interface IPaginationState {
  id: string | null;
  page: number;
  pageCount: number;
  tab: string;
  search: string;
}

const initialState: IPaginationState = {
  id: null,
  page: 1,
  pageCount: 1,
  tab: 'all',
  search: '',
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    changePageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload;
    },
    changeTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
      state.page = 1;
    },
    changeSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    resetPagination: () => initialState,
  },
});

export const { changePage, changePageCount, changeTab, changeSearch, resetPagination } =
  paginationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getPaginationState = (state: RootState) => state.pagination;

export default paginationSlice.reducer;
