import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/atoms/Button/Button';
import StatusBean from 'src/components/atoms/StatusBean/StatusBean';
import AddLinkModal from 'src/components/modals/AddLinkModal/AddLinkModal';
import CancelMeetingModal from 'src/components/modals/CancelMeetingModal/CancelMeetingModal';
import RescheduleMeetingModal from 'src/components/modals/RescheduleMeetingModal/RescheduleMeetingModal';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import Modal from 'src/components/utility/Modal/Modal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type {
  IRecruiterResponse,
  IRecruitment,
  TRecruitmentStatus,
} from 'src/pages/RecruitmentPage/Recruitment.def';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './RecruitmentCardHeader.module.scss';

interface IRecruitmentCardHeaderProps {
  recruitment: IRecruitment;
  setRecruitmentsList: (recruitmentsList: IRecruitment[]) => void;
  recruitmentsList: IRecruitment[];
}

const RecruitmentCardHeader = ({
  recruitment,
  setRecruitmentsList,
  recruitmentsList,
}: IRecruitmentCardHeaderProps) => {
  const [openActions, setOpenActions] = useState<boolean>(false);
  const [openAddLinkModal, setOpenAddLinkModal] = useState<boolean>(false);
  const [openCancelMeetingModal, setOpenCancelMeetingModal] = useState<boolean>(false);
  const [openRescheduleMeetingModal, setOpenRescheduleMeetingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    anErrorOccured,
    meetingCanceledSuccessText,
    linkAddedSuccessText,
    meetingHasBeenMovedToArchives,
  } = useAlertTexts();
  const { addAlert } = useContext(AlertContext) as AlertContextType;

  const ref = useRef<HTMLDivElement>(null);

  const { jobTitle, companyName, id, status, isArchived, requisitionId } = recruitment;

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openActions && ref.current && !ref.current.contains(e.target)) {
        setOpenActions(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openActions]);

  const navigate = useNavigate();

  const handleAddLinkModal = () => {
    setOpenAddLinkModal(!openAddLinkModal);
  };

  const handleCancelMeetingModal = () => {
    setOpenCancelMeetingModal(!openCancelMeetingModal);
  };

  const handleRescheduleMeetingModal = () => {
    setOpenRescheduleMeetingModal(!openRescheduleMeetingModal);
  };

  const handleLinkSave = (link: string) => {
    setLoading(true);
    requestWrapper('POST', '/interview/seturl', { id, url: link })
      .then(() => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'SUCCESS',
          message: linkAddedSuccessText,
        });
        setRecruitmentsList(
          recruitmentsList.map((recruitment) =>
            recruitment.id === id
              ? { ...recruitment, link: link, status: 'Scheduled' }
              : recruitment,
          ),
        );
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  const handleCancelMeeting = () => {
    setLoading(true);
    requestWrapper('GET', `/interview/cancel?id=${recruitment.id}`)
      .then(() => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'SUCCESS',
          message: meetingCanceledSuccessText,
        });

        setRecruitmentsList(
          recruitmentsList.map((recruitment) =>
            recruitment.id === id
              ? { ...recruitment, status: 'Canceled', statusUpdateDate: new Date() }
              : recruitment,
          ),
        );
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  const handleEdit = () => {
    navigate(`/recruitment/edit/${id}`);
  };

  const handleDetails = () => {
    navigate(`/recruitment/details/${id}`);
  };

  const handleMoveToArchives = () => {
    setLoading(true);
    requestWrapper('GET', `/interview/setarchived?id=${id}`)
      .then(() => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'SUCCESS',
          message: meetingHasBeenMovedToArchives,
        });
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  const handleReschedule = (reschedule: boolean) => {
    setLoading(true);
    requestWrapper('GET', `/interview/view?id=${id}`)
      .then((response) => {
        const requisition: IRequisition = {
          id: response.JobId,
          requisitionId: response.RequisitionId,
          title: response.JobTitle,
          companyName: response.JobCompanyName,
          isHot: false,
          isNew: false,
          isClosed: false,
          type: '',
          expanded: false,
          candidatesList: [
            {
              firstName: response.CandidateFirstName,
              lastName: response.CandidateLastName,
              email: response.CandidateEmail,
              id: response.CandidateId,
              candidateId: response.CandidateId,
              phone: '',
              note: '',
              selected: true,
              questionnaireStatusCache: 'NoQuestionnaire',
              questionnaireStatusUpdateDate: '',
            },
          ],
          contactPersonEmail: response.ContactPersonEmail,
          contactPerson: response.ContactPerson,
        };

        const participants = response.Recruiters.map((participant: IRecruiterResponse) => ({
          email: participant.Email,
          firstName: participant.FirstName,
          id: participant.Id,
          lastName: participant.LastName,
        }));
        setLoading(false);

        navigate('/create-poll', {
          state: {
            requisition,
            candidates: requisition.candidatesList,
            participants,
            duration: response.Duration,
            timezone: response.TimeZone,
            pollExpirationDate: dayjs(response.QuestionnaireExpireDate).toDate(),
            reschedule,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  const dropdownOptions = mapRecruitmentStatusData(
    status,
    isArchived,
    handleAddLinkModal,
    handleCancelMeetingModal,
    handleEdit,
    handleRescheduleMeetingModal,
    handleDetails,
    handleMoveToArchives,
  );

  return (
    <header data-test-id='card-header' className={styles.cardHeader}>
      <div className={styles.cardInfo}>
        <div className={styles.cardTitle}>
          <h2 data-test-id='card-title'>{jobTitle}</h2>
          <StatusBean status={status} />
        </div>
        <div className={styles.requisitionDetails}>
          <p data-test-id='company-name'>
            <Icon icon={IconType.Folder} /> {companyName}
          </p>
          <p data-test-id='id'>
            <Icon icon={IconType.RequisitionId} /> <span>{translate('requisitionID')}:</span>{' '}
            {requisitionId}
          </p>
        </div>
      </div>

      <div className={styles.actionButtons} ref={ref} data-test-id='card-actions-buttons'>
        <Button
          ctaText={translate('history')}
          stylingType='secondary'
          type='button'
          dataTestId='card-secondary-button'
          ownClass={styles.historyButton}
          onClick={() => navigate(`/recruitment/history/${id}`)}
        />
        {dropdownOptions.length > 0 && (
          <Button
            ctaText={<Icon icon={IconType.More} />}
            stylingType='primary'
            type='button'
            dataTestId='card-primary-button'
            ownClass={styles.actionButton}
            onClick={() => {
              setOpenActions(!openActions);
            }}
          />
        )}
        {openActions && dropdownOptions.length > 0 && (
          <div className={styles.actionMenu} data-test-id='actions-menu'>
            <ul>
              {dropdownOptions?.map((option) => (
                <li
                  key={getUUID()}
                  onClick={() => {
                    option.action();
                  }}
                  data-test-id={option.dataTestId}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <Modal
        handleClose={() => setOpenAddLinkModal(false)}
        isOpen={openAddLinkModal}
        dataTestId='add-link-modal'
      >
        <AddLinkModal
          onClose={() => {
            setOpenAddLinkModal(false);
          }}
          handleSave={handleLinkSave}
        />
      </Modal>
      <Modal
        handleClose={() => setOpenCancelMeetingModal(false)}
        isOpen={openCancelMeetingModal}
        dataTestId='cancel-meeting-modal'
      >
        <CancelMeetingModal
          onClose={() => {
            setOpenCancelMeetingModal(false);
          }}
          handleCancel={handleCancelMeeting}
          recruitment={recruitment}
        />
      </Modal>
      <Modal
        handleClose={() => setOpenRescheduleMeetingModal(false)}
        isOpen={openRescheduleMeetingModal}
        dataTestId='reschedule-meeting-modal'
      >
        <RescheduleMeetingModal
          onClose={() => {
            setOpenRescheduleMeetingModal(false);
          }}
          handleReschedule={() => handleReschedule(true)}
          recruitment={recruitment}
        />
      </Modal>
      <LoaderModal isOpen={loading} />
    </header>
  );
};

const mapRecruitmentStatusData = (
  status: TRecruitmentStatus,
  isArchived: boolean,
  handleAddLinkModal: () => void,
  handleCancelMeetingModal: () => void,
  handleEdit: () => void,
  handleReschedule: (reschedule: boolean) => void,
  handleDetails: () => void,
  handleMoveToArchives: () => void,
) => {
  let dropdownOptions: { name: string; action: () => void; dataTestId: string }[] = [];

  if (isArchived) {
    return [
      {
        name: translate('details'),
        action: handleDetails,
        dataTestId: 'details',
      },
    ];
  }

  switch (status) {
    case 'New':
      {
        dropdownOptions = [
          {
            name: translate('edit'),
            action: handleEdit,
            dataTestId: 'edit',
          },
          {
            name: translate('cancelResign'),
            action: handleCancelMeetingModal,
            dataTestId: 'cancel',
          },
        ];
      }
      break;
    case 'WaitingForURL':
      {
        dropdownOptions = [
          {
            name: translate('addLink'),
            action: handleAddLinkModal,
            dataTestId: 'add-link',
          },
          {
            name: translate('edit'),
            action: handleEdit,
            dataTestId: 'edit',
          },
          {
            name: translate('reschedule'),
            action: () => {
              handleReschedule(true);
            },
            dataTestId: 'reschedule',
          },
          {
            name: translate('cancelResign'),
            action: handleCancelMeetingModal,
            dataTestId: 'cancel',
          },
        ];
      }
      break;
    case 'Scheduled':
    case 'Rescheduled':
      {
        dropdownOptions = [
          {
            name: translate('edit'),
            action: handleEdit,
            dataTestId: 'edit',
          },
          {
            name: translate('reschedule'),
            action: () => {
              handleReschedule(true);
            },
            dataTestId: 'reschedule',
          },
          {
            name: translate('cancelResign'),
            action: handleCancelMeetingModal,
            dataTestId: 'cancel',
          },
        ];
      }
      break;
    case 'ToReschedule':
    case 'NoSuitableDate':
      {
        dropdownOptions = [
          {
            name: translate('details'),
            action: handleDetails,
            dataTestId: 'details',
          },
          {
            name: translate('edit'),
            action: handleEdit,
            dataTestId: 'edit',
          },
          {
            name: translate('createNewPoll'),
            action: () => {
              handleReschedule(false);
            },
            dataTestId: 'create-new-poll',
          },
          {
            name: translate('cancelResign'),
            action: handleCancelMeetingModal,
            dataTestId: 'cancel',
          },
        ];
      }
      break;
    case 'CandidateResigned':
      {
        dropdownOptions = [
          {
            name: translate('details'),
            action: handleDetails,
            dataTestId: 'details',
          },
          {
            name: translate('moveToArchives'),
            action: handleMoveToArchives,
            dataTestId: 'move-to-archives',
          },
        ];
      }
      break;
    case 'Canceled':
      {
        dropdownOptions = [
          {
            name: translate('details'),
            action: handleDetails,
            dataTestId: 'details',
          },
        ];
      }
      break;
    case 'Past':
      {
        dropdownOptions = [];
      }
      break;
    default: {
      dropdownOptions = [];
    }
  }

  return dropdownOptions;
};

export default RecruitmentCardHeader;
