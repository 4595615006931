import { useLocation } from 'react-router-dom';
import CandidatePollSuccessTemplate from 'src/components/templates/CandidatePollSuccessTemplate/CandidatePollSuccessTemplate';

const CandidatePollSuccessPage = () => {
  const location = useLocation();

  const type = location?.state?.type || 'EXPIRED';
  const templateProps = { ...location.state, type: type };

  return <CandidatePollSuccessTemplate {...templateProps} />;
};

export default CandidatePollSuccessPage;
