import type { PreloadedState } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import paginationReducer from 'src/store/pagination/paginationSlice';
import requisitionsReducer from 'src/store/requisitions/requisitionsSlice';

const rootReducer = combineReducers({
  pagination: paginationReducer,
  requisitions: requisitionsReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
