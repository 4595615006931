import classNames from 'classnames';
import { translate } from 'src/contexts/Language';
import type { TRecruitmentStatus } from 'src/pages/RecruitmentPage/Recruitment.def';
import styles from './StatusBean.module.scss';

interface IStatusBeanProps {
  status: string;
}

const StatusBean = ({ status }: IStatusBeanProps) => {
  let statusStyles = '';
  let statusName = '';

  switch (status) {
    case 'New':
      {
        statusStyles = styles.pendingStatus;
        statusName = translate('pending');
      }
      break;
    case 'WaitingForURL':
      {
        statusStyles = styles.upcomingStatus;
        statusName = translate('upcoming');
      }
      break;
    case 'Scheduled':
      {
        statusStyles = styles.upcomingStatus;
        statusName = translate('upcoming');
      }
      break;
    case 'ToReschedule':
    case 'Rescheduled':
    case 'NoSuitableDate':
    case 'CandidateResigned':
      {
        statusStyles = styles.actionRequiredStatus;
        statusName = translate('actionRequired');
      }
      break;
    case 'Resignation':
      {
        statusStyles = styles.actionRequiredStatus;
        statusName = translate('resignation');
      }
      break;
    case 'Past':
    case 'Canceled':
      {
        statusStyles = '';
        statusName = 'none';
      }
      break;
    case 'Reschedule':
      {
        statusStyles = styles.rescheduleStatus;
        statusName = translate('reschedule');
      }
      break;
    case '_NoSuitableDate':
      {
        statusStyles = styles.actionRequiredStatus;
        statusName = translate('NoSuitableDate');
      }
      break;
    case '_ToReschedule':
      {
        statusStyles = styles.actionRequiredStatus;
        statusName = translate('ToReschedule');
      }
      break;
    default: {
      statusName = 'none';
    }
  }

  if (statusName !== 'none') {
    return (
      <div className={classNames(styles.statusBean, statusStyles)} data-test-id='status-bean'>
        <div></div> <p data-test-id='status-name'>{statusName}</p>
      </div>
    );
  } else {
    return <></>;
  }
};

export default StatusBean;
