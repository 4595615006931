import classNames from 'classnames';
import Term from 'src/components/molecules/Term/Term';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IPollDetails } from 'src/pages/PollDetailsPage/PollDetailsPage.def';
import { getUUID } from 'src/utils/getUUID';
import styles from './PollDetails.module.scss';
import { Fragment } from 'react';
import Button from 'src/components/atoms/Button/Button';
import { useNavigate } from 'react-router-dom';

const PollDetails = (props: IPollDetails) => {
  const totalVotesNumber = props.responents.length;
  const votedNumber = props.responents.filter((resp) => resp.voted).length;
  const commentsNumber = props.comments ? props.comments.length : 0;

  const navigate = useNavigate();

  return (
    <div className={styles.pollDetailsWrapper}>
      <div className={styles.basicInfo} data-test-id='basic-info'>
        <p>
          {translate('votes')}: <span>{votedNumber}</span>/{totalVotesNumber}
        </p>
        <p>
          {translate('theMinimumNumberOfDatesRequired')}: <span>{props.minimumDatesCount}</span>
        </p>
        <p>
          {translate('commonDates')}: <span>{props.commonDatesCount}</span>
        </p>
        <p>
          {translate('comments')}: <span>{commentsNumber}</span>
        </p>
      </div>
      <div className={styles.votesWrapper}>
        <h3>{translate('votes')}</h3>
        <div className={styles.votes}>
          {props.responents.map((respondent) => (
            <div
              key={getUUID()}
              className={classNames(
                styles.respondentWrapper,
                respondent.voted && styles.respondentVoted,
              )}
            >
              <div className={styles.respondent}>
                <div
                  className={styles.initials}
                  data-test-id='respondent-initials'
                >{`${respondent.firstName[0]}${respondent.lastName[0]}`}</div>
                <div className={styles.respondentDetails}>
                  <p data-test-id='respondent-full-name'>{`${respondent.firstName} ${respondent.lastName}`}</p>
                  <span data-test-id='respondent-email'>{respondent.email}</span>
                </div>
              </div>
              {respondent.voted && <Icon icon={IconType.Tick} />}
            </div>
          ))}
        </div>
      </div>
      {props.comments && (
        <div className={styles.commentsWrapper}>
          <h3>{translate('pollComments')}</h3>
          <div className={styles.comments}>
            {props.comments.map((comment) => (
              <div key={getUUID()} className={classNames(styles.commentWrapper)}>
                <p>{`${comment.recruiterFirstName} ${comment.recruiterLastName}`}</p>
                <span>{comment.comment}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.bestTermsWrapper}>
        <h3>{translate('bestTerms')}</h3>
        <div className={styles.bestTerms}>
          {props.terms
            .sort((a, b) => b.votesCount - a.votesCount)
            .slice(0, 3)
            .map((term) => (
              <Fragment key={getUUID()}>
                <Term
                  dateFrom={term.dateFrom}
                  dateTo={term.dateTo}
                  votesTotal={totalVotesNumber}
                  votesNumber={term.respondents.filter((r) => r.voted).length}
                  respondents={term.respondents}
                />{' '}
              </Fragment>
            ))}
        </div>
      </div>
      {props.terms.length > 3 && (
        <div className={styles.bestTermsWrapper}>
          <h3>{translate('allTerms')}</h3>
          <div className={styles.bestTerms}>
            {props.terms
              .sort((a, b) => b.votesCount - a.votesCount)
              .slice(-(props.terms.length - 3))
              .map((term) => (
                <Fragment key={getUUID()}>
                  <Term
                    dateFrom={term.dateFrom}
                    dateTo={term.dateTo}
                    votesTotal={totalVotesNumber}
                    votesNumber={term.votesCount}
                    respondents={term.respondents}
                  />{' '}
                </Fragment>
              ))}
          </div>
        </div>
      )}
      <div className={styles.backButtonWrapper}>
        <Button
          dataTestId='back-button'
          ctaText={translate('back')}
          type='button'
          stylingType='secondary'
          ownClass={styles.backButton}
          onClick={() => {
            navigate('/requisitions');
          }}
        />
      </div>
    </div>
  );
};

export default PollDetails;
