import { Fragment } from 'react';
import styles from './AddParticipantSection.module.scss';
import type { Dispatch, SetStateAction } from 'react';
import AddPersonForm from 'src/components/molecules/AddPersonForm/AddPersonForm';
import ParticipantsListItem from 'src/components/molecules/ParticipantsListItem/ParticipantsListItem';
import { translate } from 'src/contexts/Language';
import { getUUID } from 'src/utils/getUUID';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';

interface IAddParticipantSectionProps {
  participants: IParticipant[];
  setParticipants: Dispatch<SetStateAction<IParticipant[]>>;
  skipParticipants: boolean;
  setSkipParticipants: Dispatch<SetStateAction<boolean>>;
}

const AddParticipantSection = ({
  participants,
  setParticipants,
  skipParticipants,
  setSkipParticipants,
}: IAddParticipantSectionProps) => (
  <div className={styles.participantSectionContainer}>
    <div className={styles.participantSectionHeader} data-test-id='add-participant-header'>
      <div className={styles.punction}>1</div>
      <h3>{translate('addAParticipant')}</h3>
    </div>

    <div className={styles.participants} data-test-id='added-participants'>
      {participants.map((participant) => (
        <Fragment key={getUUID()}>
          <ParticipantsListItem
            participant={participant}
            onRemove={() => {
              setParticipants(participants.filter((el) => el.email !== participant.email));
            }}
          />
        </Fragment>
      ))}
      <AddPersonForm participants={participants} setParticipants={setParticipants} />
      <div className={styles.skipParticipants}>
        <div className={styles.info}>
          <p className={styles.bold} data-test-id='skip-participants-header'>
            {translate('dontSendThePollToParticipants')}
          </p>
          <p data-test-id='skip-participants-info'>
            {translate('thePollWillBeSentDirectlyToTheCandidate')}
          </p>
        </div>
        <input
          className={styles.switch}
          data-test-id='skip-participants-switch'
          type='checkbox'
          checked={skipParticipants}
          onChange={() => {
            setSkipParticipants(!skipParticipants);
          }}
        />
      </div>
    </div>
  </div>
);

export default AddParticipantSection;
