import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './UserDropdown.module.scss';

const UserDropdown = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  return (
    <div className={styles.userDropdown} data-test-id='user-dropdown-menu'>
      <p
        className={process.env.REACT_APP_NODE_ENV !== 'development' ? styles.disabled : ''}
        data-test-id='settings-option'
        onClick={() => {
          if (process.env.REACT_APP_NODE_ENV === 'development') {
            navigate('/settings');
          }
        }}
      >
        <Icon icon={IconType.Email} /> {translate('settings')}
      </p>

      <p
        data-test-id='signout-option'
        onClick={() => {
          instance.logout();
          sessionStorage.removeItem('user');
          sessionStorage.removeItem('idToken');
          // navigate('/login');
        }}
      >
        <Icon icon={IconType.SignOut} /> {translate('signOut')}
      </p>
    </div>
  );
};

export default UserDropdown;
