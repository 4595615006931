import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import styles from './MeetingDetailsForm.module.scss';
import type { FormikHelpers } from 'formik';
import Button from 'src/components/atoms/Button/Button';
import FormDateTimePicker from 'src/components/atoms/FormDateTimePicker/FormDateTimePicker';
import FormSelect from 'src/components/atoms/FormSelect/FormSelect';
import timezones from 'src/configs/timezones.json';
import { translate } from 'src/contexts/Language';
import { useNavigate } from 'react-router-dom';
import type {
  IMeetingDetails,
  IMeetingPoolData,
  IParticipant,
} from 'src/pages/CreatePollPage/CreatePoll.def';
import type { ICandidate, IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';

interface IMeetingDetailsFormProps {
  requisition: Partial<IRequisition>;
  candidates: ICandidate[];
  participants: IParticipant[];
  duration?: string;
  timezone?: string;
  pollExpirationDate?: Date;
  skipParticipants: boolean;
}

const MeetingDetailsForm = ({
  requisition,
  candidates,
  participants,
  duration,
  timezone,
  pollExpirationDate,
  skipParticipants,
}: IMeetingDetailsFormProps) => {
  const navigate = useNavigate();

  return (
    <div className={styles.meetingDetailsSectionContainer}>
      <div className={styles.meetingDetailsSectionHeader} data-test-id='meeting-details-header'>
        <div className={styles.punction}>2</div>
        <h3>{translate('meetingDetails')}</h3>
      </div>
      <Formik
        initialValues={{
          timezone: timezone || 'Europe/Warsaw',
          duration: duration || '60',
          pollExpirationDate:
            pollExpirationDate ||
            dayjs().add(1, 'month').set('hour', 13).set('minute', 0).set('second', 0).toDate(),
        }}
        onSubmit={(values: IMeetingDetails, { setSubmitting }: FormikHelpers<IMeetingDetails>) => {
          const meetingPollData: Partial<IMeetingPoolData> = {
            requisition,
            candidates,
            participants,
            ...values,
            skipRespondentVoting: skipParticipants,
          };

          navigate('/create-poll/calendar', { state: meetingPollData });

          setSubmitting(false);
        }}
      >
        <Form data-test-id='meeting-details-form' className={styles.form}>
          <div className={styles.inputs}>
            <FormSelect
              disabled
              dataTestId='timezone'
              inputDataTestId='select-input-timezone'
              name='timezone'
              label={translate('timezone')}
              ownClass={styles.longSelectInput}
              options={timezones}
            />
            <FormSelect
              dataTestId='durarion'
              inputDataTestId='select-input-duration'
              name='duration'
              label={translate('duration')}
              ownClass={styles.shortSelectInput}
              options={[
                { name: '30 min', value: '30' },
                { name: '45 min', value: '45' },
                { name: '60 min', value: '60' },
                { name: '90 min', value: '90' },
                { name: '120 min', value: '120' },
              ]}
            />
            <FormDateTimePicker name='pollExpirationDate' />
          </div>
          <div className={styles.buttons} data-test-id='form-buttons'>
            <Button
              dataTestId='back-button'
              ctaText={translate('back')}
              type='button'
              stylingType='secondary'
              ownClass={styles.backButton}
              onClick={() => {
                navigate('/requisitions');
              }}
            />

            <Button
              dataTestId='propose-dates-button'
              ctaText={translate('proposeDates')}
              type='submit'
              stylingType='primary'
              disabled={participants.length < 1}
              ownClass={styles.proposeDatesButton}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default MeetingDetailsForm;
