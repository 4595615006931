import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './LoginTemplate.module.scss';

const LoginTemplate = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (searchParams.get('type') === 'AUTO_LOGOUT') {
    instance.logout();
  }

  useEffect(() => {
    if (isAuthenticated && searchParams.get('type') !== 'AUTO_LOGOUT') {
      navigate('/');
    }
  }, [instance, isAuthenticated, navigate, searchParams]);

  const handleAD = () => {
    instance.loginRedirect({ scopes: ['user.read'] }).catch((e) => {
      console.error(e);
    });
  };

  return (
    <main data-test-id='login-page' className={styles.loginContainer}>
      <section data-test-id='login-card' className={styles.loginCard}>
        <BrandingLogo ownClass={styles.branding} />
        {searchParams.get('type') === 'AUTO_LOGOUT' && (
          <div className={styles.autoSignedOut}>
            <div className={styles.signOutIcon}>
              <Icon icon={IconType.SignOut} />
            </div>
            <p>{translate('autoSignOutInfo')}</p>
          </div>
        )}
        <div className={styles.content}>
          <Icon icon={IconType.Microsoft} />
          <h1>
            {searchParams.get('type') === 'AUTO_LOGOUT'
              ? translate('signInWithMicrosoftAgain')
              : translate('signInWithMicrosoft')}
          </h1>
          <Button
            dataTestId='sing-in-button'
            ctaText={translate('signIn')}
            type='button'
            stylingType='primary'
            ownClass={styles.ADButton}
            onClick={handleAD}
          />
        </div>
      </section>
    </main>
  );
};

export default LoginTemplate;
