import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './BackHeader.module.scss';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { useAppDispatch } from 'src/store/reduxHooks';
import { changePage } from 'src/store/pagination/paginationSlice';

interface IBackHeaderProps {
  title: string;
  backUrl: string;
  ownClass?: string;
  backState?: object;
  clearPage?: boolean;
}

const BackHeader = ({ title, backUrl, ownClass, backState, clearPage }: IBackHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={classNames(styles.backHeader, ownClass)}>
      <div
        className={styles.content}
        onClick={() => {
          if (clearPage) {
            dispatch(changePage(1));
          }
          navigate(backUrl, { state: backState });
        }}
        data-test-id='return-header'
      >
        <div data-test-id='return-icon' className={styles.leftArrow}>
          <Icon icon={IconType.ArrowDownBlack} />
        </div>
        <h1 data-test-id='return-title'>{title}</h1>
      </div>
    </div>
  );
};

export default BackHeader;
