import type { FC } from 'react';
import NotFoundPageTemplate from 'src/components/templates/NotFoundPageTemplate/NotFoundPageTemplate';

interface NotFoundPageProps {
  isLogout?: boolean;
}

const NotFoundPage: FC<NotFoundPageProps> = ({ isLogout = false }) => (
  <NotFoundPageTemplate isLogout={isLogout} />
);

export default NotFoundPage;
