import { useState } from 'react';
import styles from './NoteModal.module.scss';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import classNames from 'classnames';

interface INoteModalProps {
  type: 'EDIT' | 'NEW' | null;
  note?: string;
  onClose: () => void;
  handleSave: (candidateNote: string) => void;
}

const NoteModal = ({ type, note, onClose, handleSave }: INoteModalProps) => {
  const [candidateNote, setCandidateNote] = useState<string>(note || '');

  return (
    <div className={styles.modal} data-test-id='note-modal-content'>
      <div className={styles.icon} data-test-id='modal-thumbnail'>
        <Icon icon={type === 'NEW' ? IconType.AddDocument : IconType.EditDocument} />
      </div>
      <div className={styles.modalContent}>
        <h2 data-test-id='modal-header'>
          {type === 'NEW' ? translate('addANote') : translate('editNote')}
        </h2>

        <div data-test-id='modal-text-input' className={styles.input}>
          <label data-test-id='input-label' htmlFor='note'>
            {translate('internalNote')}
          </label>
          <textarea
            data-test-id='input'
            name='note'
            placeholder={translate('enterYourNote')}
            value={candidateNote}
            maxLength={100}
            onChange={(event) => {
              setCandidateNote(event?.target.value);
            }}
          />
          <p className={classNames(candidateNote.length >= 100 && styles.charactersLimit)}>
            {candidateNote.length}/100 {translate('characters')}
          </p>
        </div>

        <div className={styles.actionButtons} data-test-id='actions-buttons'>
          <Button
            ctaText={translate('cancel')}
            stylingType='secondary'
            type='button'
            dataTestId='cancel-button'
            ownClass={styles.cancelButton}
            onClick={onClose}
          />
          <Button
            ctaText={type === 'NEW' ? translate('addANote') : translate('save')}
            stylingType='primary'
            type='button'
            dataTestId='confirm-button'
            ownClass={styles.addButton}
            onClick={() => {
              handleSave(candidateNote);
              onClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NoteModal;
