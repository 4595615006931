import type { ICandidatePoll, ICandidatePollResponseData } from './CandidatePoll.def';

const prepareClientPollPageData = (
  clientPollResponse: ICandidatePollResponseData,
): ICandidatePoll => ({
  token: clientPollResponse.Token,
  title: clientPollResponse.Title,
  description: clientPollResponse.Description,
  companyName: clientPollResponse.CompanyName,
  duration: clientPollResponse.Duration,
  timezone: clientPollResponse.TimeZone,
  isCompleted: clientPollResponse.IsCompleted,
  isResigned: clientPollResponse.IsResigned,
  timeSpans: clientPollResponse.TimeSpans.map((timespan) => ({
    id: timespan.Id,
    dateFrom: timespan.DateFrom,
    dateTo: timespan.DateTo,
    votesCount: timespan.VotesCount,
    selected: timespan.Selected,
  })),
});

export default prepareClientPollPageData;
