import styles from './RecruitmentCard.module.scss';

import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from 'src/components/atoms/Button/Button';
import RecruitmentCardHeader from 'src/components/molecules/RecruitmentCardHeader/RecruitmentCardHeader';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRecruitment } from 'src/pages/RecruitmentPage/Recruitment.def';

interface IRecruitmentCardProps {
  recruitment: IRecruitment;
  setRecruitmentsList: (recruitmentsList: IRecruitment[]) => void;
  recruitmentsList: IRecruitment[];
}

const RecruitmentCard = ({
  recruitment,
  setRecruitmentsList,
  recruitmentsList,
}: IRecruitmentCardProps) => {
  const {
    candidateFirstName,
    candidateLastName,
    status,
    statusUpdateDate,
    link,
    dateFrom,
    dateTo,
  } = recruitment;

  const copyLink = () => {
    navigator.clipboard.writeText(recruitment.link);
  };

  return (
    <section data-test-id='recruitment-card' className={styles.recruitmentCard}>
      <RecruitmentCardHeader
        recruitment={recruitment}
        setRecruitmentsList={setRecruitmentsList}
        recruitmentsList={recruitmentsList}
      />
      <div className={styles.meetingDetails} data-test-id='meeting-info'>
        <p className={styles.infoItem} data-test-id='meeting-date'>
          <span className={styles.label} data-test-id='meeting-date-label'>
            <Icon icon={IconType.Clock} /> {`${translate('meeting')}:`}
          </span>
          {dateFrom &&
            `${dayjs(dateFrom).format('DD.MM.YYYY')}, ${dayjs(dateFrom).format('H:mm')} - ${dayjs(
              dateTo,
            ).format('H:mm')}`}
          {!dateFrom && '-'}
        </p>
        <div className={styles.infoItem} data-test-id='meeting-link'>
          <span className={styles.label} data-test-id='meeting-link-label'>
            <Icon icon={IconType.SmallLink} /> {`${translate('link')}:`}
          </span>
          {link && (
            <p className={styles.link}>
              {link}
              <Button
                ctaText={<Icon icon={IconType.Copy} />}
                stylingType='secondary'
                type='button'
                dataTestId='copy-button'
                ownClass={styles.copyButton}
                onClick={copyLink}
              />{' '}
            </p>
          )}
          {!link && `${translate('noLinkToTheMeeting')}`}
        </div>
      </div>
      <div className={styles.additionalInfo}>
        <p className={classNames(styles.infoItem, styles.bold)} data-test-id='meeting-candidate'>
          <span className={styles.label} data-test-id='meeting-candidate-label'>
            <Icon icon={IconType.Candidates} /> {`${translate('candidate')}:`}
          </span>
          {`${candidateFirstName} ${candidateLastName}`}
        </p>
        <p className={styles.meetingStatus}>
          {translate(status)}{' '}
          {statusUpdateDate && (
            <span>{`${dayjs(statusUpdateDate).format('DD.MM.YYYY')}, ${dayjs(
              statusUpdateDate,
            ).format('H:mm')}`}</span>
          )}
        </p>
      </div>
    </section>
  );
};

export default RecruitmentCard;
