import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';

export type TRecruitmentTabs = 'all' | 'upcoming' | 'pending' | 'action_required' | 'archives';

export enum RecruitmentTabs {
  'all' = 0,
  'upcoming' = 1,
  'pending' = 2,
  'action_required' = 3,
  'archives' = 4,
}

export type TRecruitmentStatus =
  | 'New'
  | 'WaitingForURL'
  | 'Scheduled'
  | 'CandidateResigned'
  | 'ToReschedule'
  | 'NoSuitableDate'
  | 'Past'
  | 'Canceled'
  | 'Rescheduled';

export interface IRecruitmentDataResponse {
  Data: IRecruitmentResponse[];
  TotalDataCount: number;
  TotalPageCount: number;
}

export interface IRecruitmentResponse {
  Id: number;
  RequisitionId: number;
  JobTitle: string;
  JobDescription: string;
  CompanyName: string;
  CandidateFirstName: string;
  CandidateLastName: string;
  Status: TRecruitmentStatus;
  StatusUpdateDate: Date;
  DateFrom: Date;
  DateTo: Date;
  Url: string;
  IsArchived: boolean;
}

export interface IRecruitment {
  id: number;
  requisitionId: number;
  jobTitle: string;
  jobDescription: string;
  companyName: string;
  candidateFirstName: string;
  candidateLastName: string;
  status: TRecruitmentStatus;
  statusUpdateDate: Date;
  dateFrom: Date;
  dateTo: Date;
  link: string;
  isArchived: boolean;
}

export interface IRecruitmentPageData {
  infoCard: IInfoCardData | null;
  recruitmentsList: IRecruitment[] | null;
}

export interface IRecruiterResponse {
  Id: number;
  FirstName: string;
  LastName: string;
  Email: string;
}

export interface IRecruitmentDetailsResponse {
  Id: number;
  JobId: number;
  JobTitle: string;
  JobDescription: string;
  JobCompanyName: string;
  CandidateFirstName: string;
  CandidateLastName: string;
  CandidateEmail: string;
  Duration: number;
  TimeZone: string;
  SelectedDateFrom: Date;
  SelectedDateTo: Date;
  Status: TRecruitmentStatus;
  Url: string;
  ContactPerson: string;
  ContactPersonEmail: string;
  QuestionnaireExpireDate: Date;
  Recruiters: IRecruiterResponse[];
}

export interface IRecruiter {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IRecruitmentDetails {
  id: number;
  jobId: number;
  jobTitle: string;
  jobDescription: string;
  jobCompanyName: string;
  candidateFirstName: string;
  candidateLastName: string;
  candidateEmail: string;
  duration: number;
  timeZone: string;
  selectedDateFrom: Date;
  selectedDateTo: Date;
  status: TRecruitmentStatus;
  url: string;
  contactPerson: string;
  contactPersonEmail: string;
  questionnaireExpireDate: Date;
  recruiters: IRecruiter[];
}

export interface IRecruitmentResignationDetailsResponse {
  Id: number;
  JobTitle: string;
  JobDescription: string;
  CompanyName: string;
  CandidateFirstName: string;
  CandidateLastName: string;
  CandidateEmail: string;
  CandidateResignReason: string;
  CandidateResignOtherReason: string;
  CandidateRescheduleReason: string;
  CandidateNoSuitableDateComment: string;
  Status: TRecruitmentStatus;
  SupervisorFirstName: string;
  SupervisorLastName: string;
  SupervisorEmail: string;
  Attendees: IRecruiterResponse[];
}

export interface IRecruitmentResignationDetails {
  id: number;
  jobTitle: string;
  jobDescription: string;
  jobCompanyName: string;
  candidateFirstName: string;
  candidateLastName: string;
  candidateEmail: string;
  candidateResignReason: string;
  candidateResignOtherReason: string;
  candidateRescheduleReason: string;
  candidateNoSuitableDateComment: string;
  status: TRecruitmentStatus;
  contactPerson: string;
  contactPersonEmail: string;
  recruiters: IRecruiter[];
}
