import type { IRecruitment } from 'src/pages/RecruitmentPage/Recruitment.def';
import { getUUID } from 'src/utils/getUUID';
import RecruitmentCard from 'src/components/organisms/RecruitmentCard/RecruitmentCard';
import styles from './RecruitmentsList.module.scss';
import { useEffect, useState } from 'react';

interface IRecruitmentsListProps {
  recruitmentsList: IRecruitment[];
}

const RecruitmentsList = ({ recruitmentsList }: IRecruitmentsListProps) => {
  const [newRecruitmentsList, setNewRecruitmentsList] = useState<IRecruitment[]>(recruitmentsList);

  useEffect(() => {
    setNewRecruitmentsList(recruitmentsList);
  }, [recruitmentsList]);

  return (
    <ul className={styles.recruitmentsList} data-test-id='recruitments-list'>
      {newRecruitmentsList.map((recruitment: IRecruitment) => (
        <li key={getUUID()}>
          <RecruitmentCard
            recruitment={recruitment}
            setRecruitmentsList={setNewRecruitmentsList}
            recruitmentsList={newRecruitmentsList}
          />
        </li>
      ))}
    </ul>
  );
};

export default RecruitmentsList;
