import dayjs from 'dayjs';
import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import CadidatesShortenInfoList from 'src/components/molecules/CadidatesShortenInfoList/CadidatesShortenInfoList';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import PollDetails from 'src/components/organisms/PollDetails/PollDetails';
import RequisitionInfo from 'src/components/organisms/RequisitionInfo/RequisitionInfo';
import { translate } from 'src/contexts/Language';
import type { IPollDetails, ITerm } from 'src/pages/PollDetailsPage/PollDetailsPage.def';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import { useState, useContext } from 'react';

import styles from './PollDetailsPageTemplate.module.scss';
import Button from 'src/components/atoms/Button/Button';
import Modal from 'src/components/utility/Modal/Modal';
import SendPollAnywayModal from 'src/components/modals/SendPollAnywayModal/SendPollAnywayModal';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';

const PollDetailsPageTemplate = (props: IPollDetails) => {
  const [sendPollModal, setSendPollModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pollSent, setPollSent] = useState<boolean>(false);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { pollSentSuccessfullyText, pollSentErrorText } = useAlertTexts();

  const requisition: Partial<IRequisition> = {
    title: props.jobTitle,
    companyName: props.companyName,
    id: props.id,
    contactPersonEmail: '',
    contactPerson: '',
  };

  const candidates = props.candidates;

  const totalVotesNumber = props.responents.length;

  const handleSendPoll = (chosenTerms: ITerm[]) => {
    setLoading(true);
    const data = {
      questionnaireId: props.id,
      timeSpans: chosenTerms
        .filter((term) => term.selected)
        .map((term) => ({ dateFrom: term.dateFrom, dateTo: term.dateTo })),
    };
    requestWrapper('POST', '/questionnaire/sendanyway', data)
      .then(() => {
        addAlert({
          id: getUUID(),
          type: 'SUCCESS',
          message: pollSentSuccessfullyText,
        });
        setLoading(false);
        setPollSent(true);
      })
      .catch((e) => {
        console.error(e);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: pollSentErrorText,
        });
        setLoading(false);
      });
  };

  return (
    <main className={styles.container}>
      <AppHeader currentPage={'REQUISITIONS'} />
      <section className={styles.createMeetingCard} data-test-id='poll-details-card'>
        <div className={styles.header}>
          <BackHeader
            title={translate('pollDetails')}
            backUrl={'/requisitions'}
            ownClass={styles.backHeader}
          />
          <p className={styles.expirationInfo} data-test-id='expiration-date'>
            <span data-test-id='expiration-date-label'>{translate('expirationDate')}:</span>
            {props.expireDate &&
              `${dayjs(props.expireDate).format('DD.MM.YYYY')} / ${dayjs(props.expireDate).format(
                'HH:mm',
              )}`}
          </p>
        </div>
        <aside className={styles.requisitionInfo} data-test-id='aside-requisition-info'>
          <RequisitionInfo requisition={requisition} />
          <div className={styles.divider}></div>
          <CadidatesShortenInfoList candidates={candidates} />
          {!pollSent &&
            ['SentToClient', 'NoCommonAvailability', 'ForVerification'].includes(props.status) && (
              <>
                <div className={styles.divider}></div>
                <Button
                  ctaText={translate('sendPollAnyway')}
                  stylingType='primary'
                  type='button'
                  dataTestId='send-poll-anyway-button'
                  ownClass={styles.primaryButton}
                  onClick={() => {
                    setSendPollModal(true);
                  }}
                />
              </>
            )}
        </aside>
        <div className={styles.pollDetails} data-test-id='poll-details'>
          <PollDetails {...props} />
        </div>
      </section>
      <LoaderModal isOpen={loading} />
      <Modal
        handleClose={() => setSendPollModal(false)}
        isOpen={sendPollModal}
        dataTestId='add-link-modal'
      >
        <SendPollAnywayModal
          onCancel={() => {
            setSendPollModal(false);
          }}
          onSend={handleSendPoll}
          totalVotesNumber={totalVotesNumber}
          minTermsCount={props.candidates.length}
          terms={props.terms.map((term) => ({
            uuid: getUUID(),
            votesCount: term.votesCount,
            dateFrom: term.dateFrom,
            dateTo: term.dateTo,
            selected: false,
          }))}
        />
      </Modal>
    </main>
  );
};

export default PollDetailsPageTemplate;
