import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { requestWrapper } from 'src/utils/requestWrapper';
import type {
  ICandidatePoll,
  ICandidatePollResponseData,
} from 'src/pages/CandidatePollPage/CandidatePoll.def';
import prepareClientPollPageData from 'src/pages/CandidatePollPage/prepareCandidatePollPageData';
import ResignationPageTemplate from 'src/components/templates/ResignationPageTemplate/ResignationPageTemplate';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';

const ResignationPage = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState<ICandidatePoll | null>();
  const navigate = useNavigate();

  useEffect(() => {
    requestWrapper('GET', `/client/candidatetimespans?token=${id}`)
      .then((res) => {
        if (res.status === 499) {
          res.text().then((txt: string) => {
            console.error(txt);
          });
        } else {
          return res;
        }
      })
      .then((res: ICandidatePollResponseData) => {
        setPageData(prepareClientPollPageData(res));
      })
      .catch((err) => {
        console.error(err);
        navigate('/candidate-poll/success', { state: { type: 'EXPIRED' } });
      });
  }, [id, navigate]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <ResignationPageTemplate {...pageData} />;
};

export default ResignationPage;
