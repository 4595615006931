import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './UserNavProfile.module.scss';
import UserDropdown from 'src/components/atoms/UserDropdown/UserDropdown';
import UserThumbnail from 'src/components/molecules/UserThumbnail/UserThumbnail';

const UserNavProfile = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className={styles.userDropdown} ref={ref}>
      <UserThumbnail onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
      <CSSTransition
        in={isDropdownOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.dropdownEnter,
          enterActive: styles.dropdownEnterActive,
          exit: styles.dropdownExit,
          exitActive: styles.dropdownExitActive,
        }}
      >
        <UserDropdown />
      </CSSTransition>
    </div>
  );
};

export default UserNavProfile;
