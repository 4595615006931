import styles from './AppHeader.tsx.module.scss';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import AppHeaderNav from 'src/components/molecules/AppHeaderNav/AppHeaderNav';
import UserNavProfile from 'src/components/organisms/UserNavProfile/UserNavProfile';

export type TPageType = 'REQUISITIONS' | 'RECRUITMENT' | 'AI_MATCHING';

interface IAppHeaderProps {
  currentPage: TPageType;
}

const AppHeader = ({ currentPage }: IAppHeaderProps) => (
  <header className={styles.header} data-test-id='application-header'>
    <BrandingLogo />
    <AppHeaderNav currentPage={currentPage} />
    <UserNavProfile />
  </header>
);

export default AppHeader;
