import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { requestWrapper } from 'src/utils/requestWrapper';
import type { IRecruitmentDetails } from 'src/pages/RecruitmentPage/Recruitment.def';
import prepareEditRecruitmentPageData from './prepareEditRecruitmentPageData';
import EditRecruitmentPageTemplate from 'src/components/templates/EditRecruitmentPageTemplate/EditRecruitmentPageTemplate';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import useADAccessToken from 'src/hooks/useADAccessToken';

const EditRecruitmentPage = () => {
  const [pageData, setPageData] = useState<IRecruitmentDetails | null>(null);

  const { id } = useParams();
  useADAccessToken();

  useEffect(() => {
    requestWrapper('GET', `/interview/view?id=${id}`)
      .then((response) => setPageData(prepareEditRecruitmentPageData(response)))
      .catch((err) => console.error(err));
  }, [id]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <EditRecruitmentPageTemplate {...pageData} />;
};

export default EditRecruitmentPage;
