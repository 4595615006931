import axios from 'axios';
import type { AxiosResponse, AxiosError } from 'axios';

type TAPIMethods = 'GET' | 'POST';

export const requestWrapper = async (method: TAPIMethods, url: string, body?: object) => {
  let token = sessionStorage.getItem('idToken');
  token = token ? JSON.parse(token) : '';

  return handleResponse(
    await axios({
      method,
      headers: token
        ? {
            'Content-Type': 'application/json-patch+json',
            Authorization: `Bearer ${token}`,
          }
        : { 'Content-Type': 'application/json-patch+json' },
      url: `${process.env.REACT_APP_API_URL}${url}`,
      data: JSON.stringify(body) || {},
    }).catch((e: AxiosError) => {
      if (e?.response?.status === 401) {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('idToken');
        window.location.href = '/login?type=AUTO_LOGOUT';
      }
      return e;
    }),
  );
};

function handleResponse(response: AxiosResponse | AxiosError) {
  const data = (response as AxiosResponse).data || {};

  // TODO: Remove that 404 error skipping
  if (response.status !== 200 && (response as AxiosError).response?.status !== 404) {
    const error = (data && data.message) || (response as AxiosResponse).statusText;
    return Promise.reject(error);
  }

  return data;
}
