import dayjs from 'dayjs';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './SettingsInfo.module.scss';

interface ISettingsInfoProps {
  title: string;
  editedBy: string;
  editedByEmail: string;
  editionDate: Date;
}

const SettingsInfo = ({ title, editedBy, editedByEmail, editionDate }: ISettingsInfoProps) => (
  <div className={styles.settingsInfo}>
    <h2>{title}</h2>
    <div className={styles.group}>
      <Icon icon={IconType.RequisitionId} />
      <div>
        <p>
          <span>{translate('editedBy')}:</span> {editedBy}
        </p>
        <p>
          <span>{translate('email')}:</span> {editedByEmail}
        </p>
      </div>
    </div>
    <div>
      <p>
        <Icon icon={IconType.Clock} /> <span>{translate('editionDate')}:</span>{' '}
        {dayjs(editionDate).format('DD.MM.YYYY | HH:mm')}
      </p>
    </div>
  </div>
);

export default SettingsInfo;
