import { translate } from 'src/contexts/Language';

const useAlertTexts = () => {
  const pollCanceledSuccessfullyText = translate('pollHasBeenCanceledSuccessfully');
  const pollSentSuccessfullyText = translate('pollHasBeenSentSuccessfully');
  const pollSentErrorText = translate('pollHasNotBeenSentDueToError');
  const meetingCanceledSuccessText = translate('meetingHasBeenCanceledSuccessfully');
  const linkAddedSuccessText = translate('linkHasBeenAddedSuccessfully');
  const changesHaveBeenSaved = translate('changesHaveBeenSaved');
  const meetingHasBeenMovedToArchives = translate('meetingHasBeenMovedToArchives');
  const anErrorOccured = translate('anErrorOccured');
  const emailTemplateHasBeenChanged = translate('emailTemplateHasBeenChanged');
  const testEmailHasBeenSent = translate('testEmailHasBeenSent');
  const emailTemplateHasBeenReturnedToDefault = translate('emailTemplateHasBeenReturnedToDefault');
  const dataHasBeenSynchronized = translate('dataHasBeenSynchronized');
  const smtpConfigurationHasBeenSaved = translate('smtpConfigurationHasBeenSaved');

  return {
    pollCanceledSuccessfullyText,
    pollSentSuccessfullyText,
    pollSentErrorText,
    meetingCanceledSuccessText,
    linkAddedSuccessText,
    changesHaveBeenSaved,
    meetingHasBeenMovedToArchives,
    anErrorOccured,
    emailTemplateHasBeenChanged,
    testEmailHasBeenSent,
    emailTemplateHasBeenReturnedToDefault,
    dataHasBeenSynchronized,
    smtpConfigurationHasBeenSaved,
  };
};

export default useAlertTexts;
