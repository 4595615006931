import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import styles from './FormSelect.module.scss';
import { getUUID } from 'src/utils/getUUID';

interface IFormSelectProps {
  name: string;
  label: string;
  disabled?: boolean;
  options: { name: string; value: string }[];
  ownClass?: string;
  dataTestId: string;
  inputDataTestId?: string;
}

const FormSelect = ({
  name,
  label,
  disabled,
  options,
  ownClass,
  dataTestId,
  inputDataTestId,
}: IFormSelectProps) => (
  <div data-test-id={dataTestId} className={classNames(styles.formInput, ownClass)}>
    <label data-test-id='select-input-label' htmlFor={name}>
      {label}
    </label>
    <Field
      data-test-id={inputDataTestId || 'select-input'}
      name={name}
      as='select'
      disabled={disabled || false}
      className={classNames(styles.select, ownClass)}
    >
      {options.map((option) => (
        <option key={getUUID()} value={option.value}>
          {option.name}
        </option>
      ))}
    </Field>
    <p data-test-id='input-error-message'>
      <ErrorMessage name={name} />
    </p>
  </div>
);

export default FormSelect;
