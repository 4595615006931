import dayjs from 'dayjs';
import type { ITimeSpan } from 'src/pages/ClientPollPage/ClientPoll.def';

export interface IDaySlot {
  [key: string]: ITimeSpan[];
}

export const splitTimeSlotsToDays = (timeslots?: ITimeSpan[]) => {
  if (!timeslots) return;
  const daySlots: IDaySlot = {};

  timeslots.forEach((slot) => {
    const date = dayjs(slot.dateFrom).format('DD-MM-YYYY');
    if (!daySlots[date]) {
      Object.assign(daySlots, { [date]: [] });
    }
    daySlots[date] = [...daySlots[date], { ...slot }];
  });

  return daySlots;
};
