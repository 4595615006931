import classNames from 'classnames';
import styles from './Pagination.module.scss';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { getUUID } from 'src/utils/getUUID';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';
import { changePage } from 'src/store/pagination/paginationSlice';
import { DOTS, usePagination } from 'src/hooks/usePagination';

const Pagination = () => {
  const { page, pageCount } = useAppSelector((state) => state.pagination);
  const dispatch = useAppDispatch();

  const paginationRange = usePagination(pageCount, page, 3);

  // If there are less than 2 times in pagination range we shall not render the component
  if (!paginationRange || paginationRange.length < 2) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      <div
        className={styles.leftArrow}
        onClick={() => {
          if (page - 1 >= 1) {
            dispatch(changePage(page - 1));
          }
        }}
      >
        <Icon icon={IconType.ArrowDownBlack} />
      </div>
      {paginationRange.map((pageNumber: number) => {
        // If the pageItem is a DOT, render dots
        if (pageNumber === DOTS) {
          return <p key={getUUID()}>...</p>;
        }

        // Render our Page Pills
        return (
          <div
            key={getUUID()}
            className={classNames(styles.paginationItem, page === pageNumber && styles.active)}
            onClick={() => {
              if (page !== pageNumber) {
                dispatch(changePage(pageNumber));
              }
            }}
          >
            {pageNumber}
          </div>
        );
      })}
      <div
        className={styles.rightArrow}
        onClick={() => {
          if (page + 1 <= pageCount) {
            dispatch(changePage(page + 1));
          }
        }}
      >
        <Icon icon={IconType.ArrowDownBlack} />
      </div>
    </div>
  );
};

export default Pagination;
