import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import styles from './MatchingDescription.module.scss';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { useEffect, useState } from 'react';

interface IMatchingDescriptionTemplateProps {
  jobDescription: string;
  setJobDescription: (jobDescription: string) => void;
}

const MatchingDescription = ({
  jobDescription,
  setJobDescription,
}: IMatchingDescriptionTemplateProps) => {
  const [tempJobDescription, setTempJobDescription] = useState<string>(jobDescription);

  useEffect(() => {
    setTempJobDescription(jobDescription);
  }, [jobDescription]);

  return (
    <div className={styles.matchingDescriptionContainer}>
      <div className={styles.matchingDescriptionHeader}>
        <div className={styles.matchingDescriptionTitle}>
          <h2>Job Description</h2>
          <p>Write your job offer text here</p>
        </div>
        <Button
          ctaText={<Icon icon={IconType.Refresh} />}
          stylingType='secondary'
          type='button'
          dataTestId='card-secondary-button'
          ownClass={styles.actionButton}
          onClick={() => {
            setJobDescription(tempJobDescription);
          }}
        />
      </div>
      <textarea
        className={styles.textarea}
        defaultValue={jobDescription}
        placeholder='Write your job offer text here...'
        onChange={(e) => {
          setTempJobDescription(e.currentTarget.value);
        }}
      ></textarea>
    </div>
  );
};

export default MatchingDescription;
