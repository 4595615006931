import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';

export type TRequisitionsTab = 'all' | 'pending' | 'active' | 'canceled' | 'action_required';

export enum RequisitionsTabs {
  'all' = 0,
  'pending' = 1,
  'active' = 2,
  'canceled' = 3,
  'action_required' = 4,
}

export type TQuestionnaireStatus =
  | 'NoQuestionnaire'
  | 'SentToClient'
  | 'SentToCandidate'
  | 'NoCommonAvailability'
  | 'WaitingForUrl'
  | 'UrlSet'
  | 'Resignation'
  | 'ToReschedule'
  | 'NoDate'
  | 'Canceled'
  | 'Past'
  | 'Expired';

export interface ICandidateResponseData {
  Id: number;
  CandidateId: number;
  JobId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Note: string;
  Selected: boolean;
  QuestionnaireStatusCache: TQuestionnaireStatus;
  QuestionnaireStatusUpdateDate?: string;
}

export interface IRequisitionResponseData {
  Id: number;
  RequisitionId: number;
  CompanyName: string;
  IsClosed: boolean;
  Title: string;
  Description: string;
  Type: string;
  Status: string;
  IsHot: boolean;
  IsNew: boolean;
  ShortListCandidates: ICandidateResponseData[];
  ContactPersonEmail: string;
  ContactPerson: string;
}

export interface IRequisitionsResponse {
  Data: IRequisitionResponseData[];
  TotalDataCount: number;
  TotalPageCount: number;
}

export interface ICandidate {
  id: number;
  candidateId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  note: string;
  selected: boolean;
  questionnaireStatusCache: TQuestionnaireStatus;
  questionnaireStatusUpdateDate: string | undefined;
}

export interface IRequisition {
  id: number;
  requisitionId: number;
  title: string;
  companyName: string;
  isHot: boolean;
  isNew: boolean;
  isClosed: boolean;
  type: string;
  candidatesList: ICandidate[];
  contactPersonEmail: string;
  contactPerson: string;
  expanded: boolean;
}

export interface IRequisitionsPageData {
  infoCard: IInfoCardData | null;
}
