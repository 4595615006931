import classNames from 'classnames';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './ReschedulePageTemplate.module.scss';

const ReschedulePageTemplate = ({
  token,
  isCompleted,
}: {
  token: string;
  isCompleted: boolean;
}) => {
  const [note, setNote] = useState<string>('');
  const navigate = useNavigate();

  if (isCompleted) {
    return <Navigate to='/candidate-poll/success' state={{ type: 'RESCHEDULED' }} />;
  }

  const handleReschedule = () => {
    const data = {
      token: token,
      reason: note,
    };

    requestWrapper('POST', '/client/reschedule', data)
      .then((res) => {
        navigate('/candidate-poll/success', { state: { type: 'RESCHEDULED' } });
      })
      .catch((err) => console.error(err));
  };

  return (
    <main className={styles.container}>
      <div className={styles.pageCard}>
        <div className={styles.header}>
          <BrandingLogo ownClass={styles.brandingLogo} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.informationContainer}>
          <div className={styles.information} data-test-id='content'>
            <div className={styles.icon} data-test-id='thumbnail'>
              <Icon icon={IconType.Reschedule} />
            </div>
            <div className={styles.formContent}>
              <div className={styles.text}>
                <h1 data-test-id='header'>{translate('meetingReschedule')}</h1>
              </div>
              <p className={styles.question} data-test-id='reschedule-question'>
                {translate('rescheduleQuestion')}
              </p>
              <div className={styles.formWrapper}>
                <div>
                  <p>{translate('rescheduleInputLabel')}</p>
                  <div className={styles.textarea}>
                    <textarea
                      data-test-id='input'
                      name='note'
                      placeholder={translate('enterYourNote')}
                      value={note}
                      maxLength={250}
                      onChange={(event) => {
                        setNote(event?.target.value);
                      }}
                    />
                    <p className={classNames(note.length >= 250 && styles.charactersLimit)}>
                      {note.length}/250 {translate('characters')}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.actionButtons} data-test-id='actions-buttons'>
                <Button
                  ctaText={translate('confirm')}
                  stylingType='primary'
                  type='button'
                  dataTestId='confirm-button'
                  ownClass={styles.confirmButton}
                  onClick={() => {
                    handleReschedule();
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className={styles.footer}>
            {translate('resignationFooterInfo')} <a href='tel:+48555444333'> +48 555 444 333</a>
          </div> */}
        </div>
      </div>
    </main>
  );
};

export default ReschedulePageTemplate;
