export interface IEmailCreatorTemplateData {
  Type: string;
  Subject: string;
  Body: string;
  Parameters: string[];
  Buttons: string[];
  EditedByName: string;
  EditedByEmail: string;
  EditDate: Date;
}

export type SettingsPageType =
  | 'EMAIL_CREATOR'
  | 'SMTP_CONFIGURATION'
  | 'LANGUAGE_AND_REGION'
  | 'AUTOLOGOUT_SETTINGS';

export const bodyToHtml = (body: string) => {
  let _body = body;

  while (_body.indexOf('button[') > 0) {
    const buttonStartIndex = _body.indexOf('button[');
    const buttonEndIndex = _body.indexOf(']', buttonStartIndex);

    if (buttonEndIndex === -1) {
      break;
    }

    const button = _body.substring(buttonStartIndex + 7, buttonEndIndex).split(',');
    const type = button[0];
    const title = button[1];
    const url = button[2];
    const color = button[3];
    const textColor = button[4];
    _body =
      _body.substring(0, buttonStartIndex) +
      `<a data-test-id="button" href="${url}" class="${type}-button" style="${
        type === 'primary' ? `background-color:${color}` : `border:1px solid ${color}`
      };color:${textColor};padding:12px 40px;border-radius:6px;margin:10px 15px 10px 0;display:inline-block;box-shadow:0px 2px 4px rgba(0, 0, 0, 0.12);text-decoration:none;">${title}</a>` +
      _body.substring(buttonEndIndex + 1);
  }

  _body = _body.replace(/(\r\n|\n|\r)/gm, '');
  return _body;
};

export const htmlToBody = (body: string) => {
  let _body = body;
  const startBodyIndex = _body.indexOf('</header>') + 9;
  const endBodyIndex = _body.indexOf('<footer class="email-footer">');
  _body = _body.substring(startBodyIndex, endBodyIndex);

  while (_body.indexOf('<a data-test-id="button"') > 0) {
    const buttonStartIndex = _body.indexOf('<a data-test-id="button"');
    const buttonEndIndex = _body.indexOf('/a>', buttonStartIndex) - 1;
    const button = _body.substring(buttonStartIndex, buttonEndIndex);

    const type = button.substring(
      button.indexOf('class="') + 7,
      button.indexOf('-button"', button.indexOf('class="') + 7),
    );

    const title = button.substring(button.indexOf('>') + 1);

    const url = button.substring(
      button.indexOf('href="') + 6,
      button.indexOf('"', button.indexOf('href="') + 6),
    );

    const color =
      type === 'primary'
        ? button.substring(
            button.indexOf('background-color:') + 17,
            button.indexOf(';', button.indexOf('background-color:') + 17),
          )
        : button
            .substring(
              button.indexOf('border') + 6,
              button.indexOf(';', button.indexOf('border') + 6),
            )
            .split(' ')[2];

    const textColor = button.substring(
      button.indexOf(';color:') + 7,
      button.indexOf(';', button.indexOf(';color:') + 7),
    );

    _body =
      _body.substring(0, buttonStartIndex) +
      `button[${type},${title},${url},${color},${textColor}]` +
      _body.substring(buttonEndIndex + 4);
  }

  _body = _body.replace(/(\r\n|\n|\r)/gm, '');
  return _body;
};
