import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './SetURLSuccessPageTemplate.module.scss';

const SetURLSuccessPageTemplate = () => (
  <main className={styles.container}>
    <div className={styles.pageCard}>
      <div className={styles.header}>
        <BrandingLogo ownClass={styles.brandingLogo} />
      </div>
      <div className={styles.divider}></div>
      <div className={styles.informationContainer}>
        <div className={styles.information} data-test-id='content'>
          <div className={styles.icon} data-test-id='thumbnail'>
            <Icon icon={IconType.Tick} />
          </div>
          <div className={styles.content}>
            <div className={styles.text}>
              <h1 data-test-id='header'>{translate('thankYou')}</h1>
              <p data-test-id='info'>{translate('setURLSuccessInfo')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default SetURLSuccessPageTemplate;
