import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styles from './AddPersonForm.module.scss';
import type { FormikHelpers } from 'formik';
import type { Dispatch, SetStateAction } from 'react';
import FormInput from 'src/components/atoms/FormInput/FormInput';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import classNames from 'classnames';

interface IAddPersonFormProps {
  participants: IParticipant[];
  setParticipants: Dispatch<SetStateAction<IParticipant[]>>;
  ownClass?: string;
}

const AddPersonForm = ({ participants, setParticipants, ownClass }: IAddPersonFormProps) => {
  const signInSchema = Yup.object().shape({
    firstName: Yup.string().required(translate('pleasePassName')),
    lastName: Yup.string().required(translate('pleasePassLastName')),
    email: Yup.string().required(translate('pleasePassEmail')),
  });
  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '' }}
      validationSchema={signInSchema}
      onSubmit={(
        values: IParticipant,
        { setSubmitting, resetForm }: FormikHelpers<IParticipant>,
      ) => {
        setParticipants([...participants, values]);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ errors }) => (
        <Form data-test-id='add-participant-form' className={classNames(styles.form, ownClass)}>
          <div className={styles.inputs}>
            <FormInput
              dataTestId='add-participant-firstname-input'
              name='firstName'
              label={translate('name')}
              type='text'
              placeholder={translate('name')}
              ownClass={styles.shortInput}
            />
            <FormInput
              dataTestId='add-participant-lastname-input'
              name='lastName'
              label={translate('lastName')}
              type='text'
              placeholder={translate('lastName')}
              ownClass={styles.shortInput}
            />
            <FormInput
              dataTestId='add-participant-email-input'
              name='email'
              label={translate('email')}
              type='text'
              placeholder={translate('email')}
              ownClass={styles.longInput}
            />
          </div>
          <button type='submit' data-test-id='add-participant-button' className={styles.button}>
            <div className={styles.plus}>
              <Icon icon={IconType.PlusWhite} />
            </div>
            <p>{translate('addParticipant')}</p>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AddPersonForm;
