import type { AccountInfo } from '@azure/msal-common';
import { useMsal } from '@azure/msal-react';
import type { ReactNode } from 'react';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { graphConfig, loginRequest } from 'src/App/authConfig';
import NotFoundPage from 'src/pages/ErrorPages/NotFoundPage';

interface MsalGroupProps {
  children: ReactNode;
  validAzureAdGroupId: string;
}

type IdTokenClaims = {
  groups: string[];
};

const getUserGroups = async (accessToken: string) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${accessToken}`);

  const options = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(graphConfig.graphGroups.endpoint, options);
  const groupsData = await response.json();

  return groupsData.value;
};

const isUserInGroup = async (accessToken: string, groupId: string): Promise<boolean> => {
  const userGroups = await getUserGroups(accessToken);
  return userGroups.some((group: any) => group.id === groupId);
};

const MsalGroup: React.FC<MsalGroupProps> = ({ children, validAzureAdGroupId }) => {
  const { instance } = useMsal();
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    const checkGroupMembership = async () => {
      try {
        let token = sessionStorage.getItem('accessToken');
        token = token ? JSON.parse(token) : '';

        const isInGroup = await isUserInGroup(token || '', '736d49fe-4cd0-44ad-8192-f30f0c9ab2c2');

        setAuthorized(isInGroup);
      } catch (error) {
        console.error('Error acquiring token silently:', error);
        setAuthorized(false);
      }
    };

    checkGroupMembership();
  }, [instance, validAzureAdGroupId]);

  return <>{authorized ? <>{children}</> : <NotFoundPage isLogout />}</>;
};

export default MsalGroup;
