import type { ICreatePollPageData, IParticipant } from './CreatePoll.def';
import type { ICandidate, IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';

const prepareCreatePollPageData = (
  requisition: IRequisition,
  candidatesList: ICandidate[],
  participants: IParticipant[],
  duration: string,
  timezone: string,
  pollExpirationDate: Date,
  reschedule: boolean,
): ICreatePollPageData => ({
  requisition: {
    id: requisition.id,
    title: requisition.title,
    companyName: requisition.companyName,
    contactPersonEmail: requisition.contactPersonEmail,
    contactPerson: requisition.contactPerson,
  },
  candidates: candidatesList.map((candidate) => ({
    id: candidate.id,
    candidateId: candidate.candidateId,
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    email: candidate.email,
    phone: candidate.phone,
    note: candidate.note,
    selected: candidate.selected,
    questionnaireStatusCache: candidate.questionnaireStatusCache,
    questionnaireStatusUpdateDate: candidate.questionnaireStatusUpdateDate,
  })),
  participants,
  duration,
  timezone,
  pollExpirationDate,
  reschedule: !!reschedule,
});

export default prepareCreatePollPageData;
