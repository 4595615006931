import { useCallback, useEffect, useState, useContext } from 'react';
import type { ITimeSpan } from 'src/pages/ClientPollPage/ClientPoll.def';
import { splitTimeSlotsToDays } from 'src/utils/Time';
import type { IDaySlot } from 'src/utils/Time';
import styles from './ClientPollVoting.module.scss';
import dayjs from 'dayjs';
import { getUUID } from 'src/utils/getUUID';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import classNames from 'classnames';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import Button from 'src/components/atoms/Button/Button';
import AlertContext from 'src/contexts/AlertContext';
import type { AlertContextType } from 'src/contexts/AlertContext';
import useAlertTexts from 'src/hooks/useAlertTexts';
import { requestWrapper } from 'src/utils/requestWrapper';
import { useNavigate } from 'react-router-dom';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';

interface IClientPollVotingProps {
  timeSpans: ITimeSpan[];
  minimumToSelect: number;
  token: string;
}
const ClientPollVoting = ({ timeSpans, minimumToSelect, token }: IClientPollVotingProps) => {
  const [newTimeSpans, setNewTimeSpans] = useState<ITimeSpan[]>(timeSpans);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [noneSuits, setNoneSuits] = useState<boolean>(false);
  const [mappedDays, setMappedDays] = useState<IDaySlot>();
  const [isMinimumSelected, setIsMinimumSelected] = useState<boolean>();
  const [comment, setComment] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { anErrorOccured } = useAlertTexts();

  const navigate = useNavigate();

  const handleCheckDay = (index: number) => {
    const _newTimeSpans = newTimeSpans.map((span) =>
      span.id === index
        ? {
            ...span,
            selected: !span.selected,
            votesCount: span.selected ? span.votesCount - 1 : span.votesCount + 1,
          }
        : span,
    );

    setNewTimeSpans(_newTimeSpans);
  };

  const checkIfAllInDayChecked = (day: string) =>
    newTimeSpans.filter(
      (span) => dayjs(span.dateFrom).format('DD-MM-YYYY') === day && !span.selected,
    ).length === 0;

  const handleCheckAllInDay = (day: string, checked: boolean) => {
    const _newTimeSpans = newTimeSpans.map((span) => {
      if (dayjs(span.dateFrom).format('DD-MM-YYYY') === day) {
        let newVotesCount = span.votesCount;

        if (!checked) {
          newVotesCount -= 1;
        } else {
          if (!span.selected) {
            newVotesCount += 1;
          }
        }

        return {
          ...span,
          selected: checked,
          votesCount: newVotesCount,
        };
      } else {
        return span;
      }
    });
    setNewTimeSpans(_newTimeSpans);
  };

  const checkIfAllChecked = useCallback(() => {
    const allChecked = newTimeSpans.filter((span) => !span.selected).length === 0;
    setAllChecked(allChecked);
    return allChecked;
  }, [newTimeSpans]);

  const handleCheckAllDays = () => {
    const allChecked = checkIfAllChecked();
    const _newTimeSpans = newTimeSpans.map((span) => {
      let newVotesCount = span.votesCount;

      if (allChecked) {
        newVotesCount -= 1;
      } else if (!allChecked && !span.selected) {
        newVotesCount += 1;
      }

      return {
        ...span,
        selected: !allChecked,
        votesCount: newVotesCount,
      };
    });
    setNewTimeSpans(_newTimeSpans);
  };

  const getCheckedAmount = useCallback(
    () => newTimeSpans.filter((timespan) => timespan.selected).length,
    [newTimeSpans],
  );

  const handleSave = () => {
    setLoading(true);
    const data = {
      token: token,
      comment: comment,
      nothingSelected: noneSuits,
      timeSpans: newTimeSpans,
    };

    requestWrapper('POST', '/client/addresponse', data)
      .then(() => {
        setLoading(false);
        if (noneSuits) {
          navigate('/client-poll/success', { state: { type: 'NO_DATE' } });
        } else {
          navigate('/client-poll/success', { state: { type: 'SUCCESS' } });
        }
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  useEffect(() => {
    setMappedDays(splitTimeSlotsToDays(newTimeSpans));
    setIsMinimumSelected(getCheckedAmount() >= minimumToSelect);
  }, [getCheckedAmount, minimumToSelect, newTimeSpans]);

  useEffect(() => {
    checkIfAllChecked();
  }, [checkIfAllChecked, newTimeSpans]);

  useEffect(() => {
    if (noneSuits) {
      setNewTimeSpans(
        newTimeSpans.map((span) => ({
          ...span,
          selected: false,
          votesCount: span.selected ? span.votesCount - 1 : span.votesCount,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noneSuits]);

  return (
    <div className={styles.votingContainer}>
      <div className={styles.daysContainer}>
        {mappedDays &&
          Object.keys(mappedDays).length > 0 &&
          Object.keys(mappedDays).map((day) => (
            <div key={getUUID()} className={styles.dayContainer}>
              <div className={styles.dayHeader}>
                <Checkbox
                  dataTestId='day-checkbox'
                  checked={checkIfAllInDayChecked(day)}
                  onChange={() => {
                    handleCheckAllInDay(day, !checkIfAllInDayChecked(day));
                  }}
                  disabled={noneSuits}
                  ownClass={styles.checkbox}
                />
                <p data-test-id='day-label'>
                  {dayjs(mappedDays[day][0]?.dateFrom).format('dddd, MMMM DD')}
                </p>
              </div>
              <div className={styles.datesRow}>
                {mappedDays[day].map((time) => (
                  <button
                    key={getUUID()}
                    disabled={noneSuits}
                    className={classNames(
                      styles.dateButton,
                      time.selected && styles.activeDateButton,
                    )}
                    onClick={() => handleCheckDay(time.id)}
                    data-test-id='day-tile'
                    data-test-date={dayjs(time.dateFrom).format('DD.MM.YYYY')}
                  >
                    {`${dayjs(time.dateFrom).format('H:mm')} - ${dayjs(time.dateTo).format(
                      'H:mm',
                    )}`}
                    <span data-test-id='day-tile-votes'>
                      {time.selected && <Icon icon={IconType.WhiteTick} />}
                      {time.votesCount > 0 && time.votesCount}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.footerChecks} data-test-id='footer-checks'>
          <button
            className={classNames(styles.votingButton, allChecked && styles.activeVotingButton)}
            onClick={handleCheckAllDays}
            disabled={noneSuits}
            data-test-id='check-all-button'
          >
            <Checkbox
              dataTestId='check-all-checkbox'
              checked={allChecked}
              onChange={handleCheckAllDays}
              disabled={noneSuits}
              ownClass={styles.checkbox}
            />
            <p>{translate('voteForAllDates')}</p>
          </button>
          <div className={styles.nonSuitedButton}>
            <button
              className={classNames(styles.votingButton, noneSuits && styles.nonSuitesButton)}
              onClick={() => {
                setNoneSuits(!noneSuits);
              }}
              data-test-id='non-suits-button'
            >
              <Checkbox
                dataTestId='none-suits-checkbox'
                checked={noneSuits}
                onChange={() => {
                  setNoneSuits(!noneSuits);
                }}
                ownClass={styles.checkbox}
                disabled={false}
              />
              <p>{translate('theAboveDatesAreNotAppropriateForMe')}</p>
            </button>
            {noneSuits && (
              <div className={styles.noneSuitsCommentContainer}>
                <div
                  className={styles.nonSuitsCommentLabel}
                  data-test-id='comments-for-client-label'
                >
                  <Icon icon={IconType.CommentEmpty} />
                  <p>{translate('commentFromClient')}</p>
                </div>
                <div className={styles.textarea}>
                  <textarea
                    data-test-id='comments-for-client-input'
                    name='comment'
                    placeholder={translate('proposeADifferentMeetingDate')}
                    value={comment}
                    maxLength={250}
                    onChange={(event) => {
                      setComment(event?.target.value);
                    }}
                  />
                  <p className={classNames(comment.length >= 250 && styles.charactersLimit)}>
                    {comment.length}/250 {translate('characters')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.confirmButtons}>
          {!noneSuits && !isMinimumSelected && (
            <div className={styles.confirmAnywayContainer}>
              <p data-test-id='too-few-dates-info'>
                {translate('tooFewSelectedDatesInQuestionnaireInfo')}
              </p>
              <Button
                ctaText={translate('confirmAnyway')}
                stylingType='primary'
                type='button'
                dataTestId='confirm-anyway-button'
                disabled={getCheckedAmount() === 0}
                ownClass={styles.confirmAnywayButton}
                onClick={handleSave}
              />
            </div>
          )}
          {(noneSuits || isMinimumSelected) && (
            <div className={styles.confirmContainer}>
              {!noneSuits && getCheckedAmount() > 0 && (
                <p data-test-id='times-selected-info'>{`${
                  newTimeSpans.filter((span) => span.selected).length
                } ${translate('timesSelected')}`}</p>
              )}
              <Button
                ctaText={translate('confirm')}
                stylingType='primary'
                type='button'
                dataTestId='confirm-button'
                ownClass={styles.confirmButton}
                onClick={handleSave}
              />
            </div>
          )}
        </div>
      </div>
      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default ClientPollVoting;
