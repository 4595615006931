import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App/App';
import './index.scss';

import { LanguageProvider } from './contexts/Language';
import reportWebVitals from './reportWebVitals';

import type { AuthenticationResult, EventMessage } from '@azure/msal-browser';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import msalConfig from './App/authConfig';

import { Provider } from 'react-redux';
import { setupStore } from 'src/store/store';

const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);

    sessionStorage.setItem('idToken', JSON.stringify(payload.idToken));
    sessionStorage.setItem('accessToken', JSON.stringify(payload.accessToken));
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <LanguageProvider>
        <Provider store={setupStore()}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </LanguageProvider>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
