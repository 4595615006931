import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import type { RootState } from 'src/store/store';

interface INoteChange {
  requisitionId: number;
  candidateId: number;
  note: string;
}

const initialState: IRequisition[] | null = [];

export const requisitionsSlice = createSlice({
  name: 'requisitions',
  initialState,
  reducers: {
    setRequisitions: (state, action: PayloadAction<IRequisition[]>) => action.payload,
    toggleRequisitionCard: (state, action: PayloadAction<number>) =>
      state.map((req) =>
        req.id === action.payload ? { ...req, expanded: !req.expanded } : { ...req },
      ),
    changeRequisitionNote: (state, action: PayloadAction<INoteChange>) =>
      state.map((req) =>
        req.id === action.payload.requisitionId
          ? {
              ...req,
              candidatesList: req.candidatesList.map((cand) =>
                cand.candidateId === action.payload.candidateId
                  ? { ...cand, note: action.payload.note }
                  : { ...cand },
              ),
            }
          : { ...req },
      ),
  },
});

export const { setRequisitions, toggleRequisitionCard, changeRequisitionNote } =
  requisitionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getRequisitions = (state: RootState) => state.requisitions;

export default requisitionsSlice.reducer;
