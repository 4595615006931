import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import styles from './AIMatchingRequisitionCardHeader.module.scss';
import Button from 'src/components/atoms/Button/Button';

interface IAIMatchingRequisitionCardHeaderProps {
  requisition: IRequisition | null;
}

const AIMatchingRequisitionCardHeader = ({
  requisition,
}: IAIMatchingRequisitionCardHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  if (!requisition) {
    return (
      <header data-test-id='card-header' className={styles.cardHeader}>
        <div className={styles.cardInfo}>
          <div className={styles.cardTitle}>
            <h2 data-test-id='card-title'>New Match</h2>
          </div>
        </div>

        <div className={styles.actionButtons} ref={ref} data-test-id='card-actions-buttons'>
          <Button
            ctaText={<Icon icon={IconType.ArrowDownWhite} />}
            stylingType='primary'
            type='button'
            dataTestId='card-secondary-button'
            ownClass={styles.actionButton}
            onClick={() => navigate('/match-candidate', { state: requisition })}
          />
        </div>
      </header>
    );
  }

  const { title, companyName, requisitionId } = requisition;

  return (
    <header data-test-id='card-header' className={styles.cardHeader}>
      <div className={styles.cardInfo}>
        <div className={styles.cardTitle}>
          <h2 data-test-id='card-title'>{title}</h2>
        </div>
        <div className={styles.requisitionDetails}>
          <p data-test-id='company-name'>
            <Icon icon={IconType.Folder} /> {companyName}
          </p>
          <p data-test-id='id'>
            <Icon icon={IconType.RequisitionId} /> <span>{translate('requisitionID')}:</span>{' '}
            {requisitionId}
          </p>
        </div>
      </div>

      <div className={styles.actionButtons} ref={ref} data-test-id='card-actions-buttons'>
        <Button
          ctaText={<Icon icon={IconType.ArrowDownWhite} />}
          stylingType='primary'
          type='button'
          dataTestId='card-secondary-button'
          ownClass={styles.actionButton}
          onClick={() => navigate('/match-candidate', { state: requisition })}
        />
      </div>
    </header>
  );
};

export default AIMatchingRequisitionCardHeader;
