import classNames from 'classnames';
import type { MutableRefObject } from 'react';
import styles from './Button.module.scss';

type TButtonType = 'submit' | 'button' | 'reset' | undefined;
type TButtonStylingType = 'primary' | 'secondary';

interface IButtonProps {
  ctaText: string | JSX.Element;
  ownClass?: string;
  ctaClass?: string;
  stylingType?: TButtonStylingType;
  type: TButtonType;
  dataTestId?: string;
  onClick?: () => void;
  disabled?: boolean;
  ref?: MutableRefObject<HTMLButtonElement | null>;
}

const Button = ({
  ctaText,
  stylingType = 'primary',
  type = 'submit',
  ownClass = '',
  ctaClass = '',
  dataTestId = '',
  onClick,
  disabled,
  ref,
}: IButtonProps) => (
  <button
    ref={ref}
    type={type}
    data-test-id={dataTestId}
    disabled={disabled}
    className={classNames(
      stylingType === 'primary' ? styles.primaryButton : styles.secondaryButton,
      disabled && stylingType === 'primary' && styles.disabledPrimary,
      disabled && stylingType === 'secondary' && styles.disabledSecondary,
      ownClass,
    )}
    onClick={onClick}
  >
    <p className={ctaClass}>{ctaText}</p>
  </button>
);

export default Button;
