import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import StatusBean from 'src/components/atoms/StatusBean/StatusBean';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import MeetingEditionForm from 'src/components/organisms/MeetingEditionForm/MeetingEditionForm';
import RequisitionInfo from 'src/components/organisms/RequisitionInfo/RequisitionInfo';
import { translate } from 'src/contexts/Language';
import type { IRecruitmentDetails } from 'src/pages/RecruitmentPage/Recruitment.def';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import styles from './EditRecruitmentPageTemplate.module.scss';

const EditRecruitmentPageTemplate = (props: IRecruitmentDetails) => {
  const requisitionInfoData: Partial<IRequisition> = {
    title: props.jobTitle,
    companyName: props.jobCompanyName,
    id: props.jobId,
    contactPerson: props.contactPerson,
    contactPersonEmail: props.contactPersonEmail,
  };

  return (
    <main className={styles.container}>
      <AppHeader currentPage='RECRUITMENT' />
      <section className={styles.pageCard} data-test-id='page-card'>
        <BackHeader
          title={translate('edition')}
          backUrl={'/recruitment'}
          ownClass={styles.backHeader}
        />
        <aside className={styles.requisitionInfo} data-test-id='aside-requisition-info'>
          <StatusBean status={props.status} />
          <RequisitionInfo requisition={requisitionInfoData} />
        </aside>
        <div className={styles.editionForm} data-test-id='edition'>
          <MeetingEditionForm {...props} />
        </div>
      </section>
    </main>
  );
};

export default EditRecruitmentPageTemplate;
