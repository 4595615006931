import { useLocation } from 'react-router-dom';
import prepareCreatePollPageData from './prepareCreatePollPageData';
import CreatePollTemplate from 'src/components/templates/CreatePollTemplate/CreatePollTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';

const CreatePollPage = () => {
  const location = useLocation();
  useADAccessToken();

  return (
    <CreatePollTemplate
      {...prepareCreatePollPageData(
        location.state.requisition,
        location.state.candidates,
        location.state.participants,
        location.state.duration,
        location.state.timezone,
        location.state.pollExpirationDate,
        location.state.reschedule,
      )}
    />
  );
};

export default CreatePollPage;
