import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './DetailedBackHeader.module.scss';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { useAppDispatch } from 'src/store/reduxHooks';
import { changePage } from 'src/store/pagination/paginationSlice';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import { translate } from 'src/contexts/Language';

interface IDetailedBackHeaderProps {
  requisition: IRequisition | null;
  backUrl: string;
  ownClass?: string;
  backState?: object;
  clearPage?: boolean;
}

const DetailedBackHeader = ({
  requisition,
  backUrl,
  ownClass,
  backState,
  clearPage,
}: IDetailedBackHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={classNames(styles.backHeader, ownClass)}>
      <div
        className={styles.content}
        onClick={() => {
          if (clearPage) {
            dispatch(changePage(1));
          }
          navigate(backUrl, { state: backState });
        }}
        data-test-id='return-header'
      >
        <div data-test-id='return-icon' className={styles.leftArrow}>
          <Icon icon={IconType.ArrowDownBlack} />
        </div>
        <h1 data-test-id='return-title'>{requisition?.title || 'New Match'}</h1>
      </div>
      {requisition && (
        <div className={styles.requisitionDetails}>
          <p data-test-id='company-name'>
            <Icon icon={IconType.Folder} /> {requisition.companyName}
          </p>
          <p data-test-id='id'>
            <Icon icon={IconType.RequisitionId} /> <span>{translate('requisitionID')}:</span>{' '}
            {requisition.requisitionId}
          </p>
        </div>
      )}
    </div>
  );
};

export default DetailedBackHeader;
