import styles from './ParticipantsListItem.module.scss';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import classNames from 'classnames';

interface IParticipantsListItemProps {
  participant: IParticipant;
  onRemove: () => void;
  ownClass?: string;
}

const ParticipantsListItem = ({ participant, onRemove, ownClass }: IParticipantsListItemProps) => (
  <div className={classNames(styles.participant, ownClass)}>
    <div className={styles.participantInfo}>
      <div
        className={styles.initials}
        data-test-id='participant-initials'
      >{`${participant.firstName[0]}${participant.lastName[0]}`}</div>
      <div className={styles.participantDetails}>
        <p data-test-id='participant-full-name'>{`${participant.firstName} ${participant.lastName}`}</p>
        <span data-test-id='participant-email'>{participant.email}</span>
      </div>
    </div>
    <Button
      ctaText={<Icon icon={IconType.Remove} />}
      stylingType='secondary'
      type='button'
      dataTestId='participant-remove-button'
      ownClass={styles.participantRemove}
      onClick={onRemove}
    />
  </div>
);

export default ParticipantsListItem;
