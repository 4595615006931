import { Fragment } from 'react';
import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import HistoryEvent from 'src/components/molecules/HistoryEvent/HistoryEvent';
import Pagination from 'src/components/molecules/Pagination/Pagination';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRecruitmentHistoryPageData } from 'src/pages/RecruitmentHistoryPage/RecruitmentHistoryPage.def';
import { getUUID } from 'src/utils/getUUID';
import styles from './RecruitmentHistoryPageTemplate.module.scss';

const RecruitmentHistoryPageTemplate = ({
  events,
  jobName,
  jobId,
  companyName,
  candidateFirstName,
  candidateLastName,
}: IRecruitmentHistoryPageData) => (
  <main className={styles.container}>
    <AppHeader currentPage='RECRUITMENT' />
    <section className={styles.card} data-test-id='recruitment-history-card'>
      <BackHeader
        title={translate('history')}
        backUrl={'/recruitment'}
        ownClass={styles.backHeader}
        clearPage
      />
      <div className={styles.info}>
        <h2>{jobName}</h2>
        <ul className={styles.requisitionDetailsList}>
          <li data-test-id='meeting-candidate'>
            <Icon icon={IconType.Candidates} />
            <span data-test-id='candidate'>
              <span className={styles.label} data-test-id='candidate-label'>
                {`${translate('candidate')}: `}
              </span>
              {`${candidateFirstName} ${candidateLastName}`}
            </span>
          </li>
          <li data-test-id='requisition-company-name'>
            <Icon icon={IconType.Folder} /> {companyName}
          </li>
          <li>
            <Icon icon={IconType.RequisitionId} />{' '}
            <span data-test-id='requisition-id'>
              <span className={styles.label} data-test-id='requisition-id-label'>
                {`${translate('requisitionID')}: `}
              </span>
              {jobId}
            </span>
          </li>
        </ul>
      </div>

      <ul className={styles.content} data-test-id='card-content'>
        {events.map((event) => (
          <Fragment key={getUUID()}>
            <HistoryEvent {...event} />
          </Fragment>
        ))}
      </ul>
    </section>
    <Pagination />
  </main>
);

export default RecruitmentHistoryPageTemplate;
