import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { formats, modules } from './EditorToolbar';
import styles from './TextEditor.module.scss';
import './Toolbar.scss';

interface ITextEditorProps {
  value: string;
  setFieldValue: (val: string) => void;
}

export const TextEditor = ({ value, setFieldValue }: ITextEditorProps) => (
  // const toolbarOptions = [
  //   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  //   ['blockquote', 'code-block'],
  //   [{ list: 'ordered' }, { list: 'bullet' }],
  //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //   [{ color: [] }], // dropdown with defaults from theme
  //   [{ align: [] }],
  // ];

  // const modules = {
  //   toolbar: {
  //     container: toolbarOptions,
  //   },
  // };

  <div className={styles.editor}>
    <EditorToolbar />
    <ReactQuill
      theme='snow'
      value={value}
      onChange={setFieldValue}
      modules={modules}
      formats={formats}
    />
  </div>
);
