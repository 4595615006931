import pl from 'date-fns/locale/pl';
import { useField } from 'formik';
import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FormDateTimePicker.scss';
import styles from './FormDateTimePicker.module.scss';
import type { InputHTMLAttributes } from 'react';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';

registerLocale('pl', pl);

interface IFormDatePickerProps {
  name: string;
  setOutsideValue?: (newDate: Date) => void;
  label?: boolean;
  additionalLabels?: boolean;
}

const FormDatePicker = ({
  name = '',
  setOutsideValue,
  label = true,
  additionalLabels = false,
}: IFormDatePickerProps) => {
  const [field, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  const DatePickerInput = forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  >((props, ref) => (
    <div className={styles.datePickerWrapper} data-test-id='date-picker'>
      <input className={styles.datePickerInput} ref={ref} {...props} />
      <Icon icon={IconType.Calendar} />
    </div>
  ));

  DatePickerInput.displayName = 'DatePickerInput';

  const TimePickerInput = forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  >((props, ref) => (
    <div className={styles.timePickerWrapper} data-test-id='time-picker'>
      <input className={styles.datePickerInput} ref={ref} {...props} />
      <Icon icon={IconType.ArrowDownBlack} />
    </div>
  ));

  TimePickerInput.displayName = 'DatePickerInput';

  return (
    <div className={styles.datetimePicker} data-test-id='datetime-picker'>
      {label && (
        <p className={styles.label} data-test-id='datetime-picker-label'>
          {translate('pollExpirationDate')} <span>{translate('pollExpirationDateInfo')}</span>
        </p>
      )}
      <div className={styles.pickers}>
        <div className={styles.wrapper}>
          {additionalLabels && <p>{translate('date')}</p>}
          <DatePicker
            {...field}
            selected={value}
            minDate={new Date()}
            locale='pl'
            onChange={(date: Date) => {
              setValue(date);
              if (setOutsideValue) {
                setOutsideValue(date);
              }
            }}
            dateFormat='dd.MM.yyyy'
            customInput={<DatePickerInput />}
          />
        </div>
        <div className={styles.wrapper}>
          {additionalLabels && <p>{translate('time')}</p>}
          <DatePicker
            {...field}
            selected={value}
            minDate={new Date()}
            onChange={(date: Date) => {
              setValue(date);
              if (setOutsideValue) {
                setOutsideValue(date);
              }
            }}
            showTimeSelect
            showTimeSelectOnly
            locale='pl'
            timeIntervals={30}
            dateFormat='HH:mm'
            customInput={<TimePickerInput />}
          />
        </div>
      </div>
    </div>
  );
};

export default FormDatePicker;
