import classNames from 'classnames';
import dayjs from 'dayjs';
import styles from './PollStatus.module.scss';
import type { TQuestionnaireStatus } from 'src/pages/RequisitionsPage/Requisitions.def';
import { translate } from 'src/contexts/Language';

interface IPollStatusProps {
  questionnaireStatusCache: TQuestionnaireStatus;
  questionnaireStatusUpdateDate?: string;
}

const PollStatus = ({
  questionnaireStatusCache,
  questionnaireStatusUpdateDate,
}: IPollStatusProps) => {
  let dotColorClass;
  if (questionnaireStatusCache === 'NoQuestionnaire') {
    dotColorClass = styles.pending;
  } else if (questionnaireStatusCache === 'SentToClient') {
    dotColorClass = styles.sentToClient;
  } else if (questionnaireStatusCache === 'NoCommonAvailability') {
    dotColorClass = styles.forVerification;
  } else if (questionnaireStatusCache === 'Expired') {
    dotColorClass = styles.expired;
  } else {
    dotColorClass = styles.default;
  }

  return (
    <div className={styles.pollStatus} data-test-id='poll-status'>
      <div className={styles.pollStatusText} data-test-id='poll-status-text'>
        <div className={classNames(styles.dot, dotColorClass)}></div>
        {translate(questionnaireStatusCache)}
      </div>
      <span data-test-id='poll-status-update-date'>
        {questionnaireStatusUpdateDate && dayjs(questionnaireStatusUpdateDate).format('DD.MM.YYYY')}
      </span>
    </div>
  );
};

export default PollStatus;
