import dayjs from 'dayjs';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRequisitionHistoryEvent } from 'src/pages/RequisitionHistoryPage/RequisitionHistoryPage.def';
import styles from './HistoryEvent.module.scss';

const getAdditionalData = (eventType: string, message: string, additionalData: string) => {
  switch (eventType) {
    case 'QuestionnaireCandidateVoted': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleGreen} /> {translate('candidate')}:
            </span>
            <p>{message}</p>
          </div>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleGreenTick} /> {translate('selectedData')}:
            </span>
            <p>
              {additionalData
                ? dayjs(additionalData).add(1, 'hours').format('DD.MM.YYYY, HH:mm')
                : '-'}
            </p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireParticipantVoted': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleGreen} /> {translate('participant')}:
            </span>
            <p>{message}</p>
          </div>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleGreenTick} /> {translate('vote')}:
            </span>
            <p>
              {additionalData} {translate('datesSelected')}
            </p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireParticipantNotVoted': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleGreen} /> {translate('participant')}:
            </span>
            <p>{message}</p>
          </div>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleOrangeTick} /> {translate('vote')}:
            </span>
            <p>{translate('noDateSelected')}</p>
          </div>
        </div>
      );
    }
    case 'LinkRequestSent': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.SmallLink} /> {translate('contactPerson')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireReminderSent': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleOrange} /> {translate('participant')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireSentToCandidate': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.Candidates} /> {translate('candidate')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireSentToRecruiters': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.Candidates} /> {translate('participants')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'NotificationSentResignation': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleOrange} /> {translate('participants')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireCandidateResign': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.PeopleRed} /> {translate('candidate')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'InterviewUpdated': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.Document} /> {translate('edition')}:
            </span>
            <p>
              {message
                ? message
                    .split(',')
                    .map((el) => translate(el))
                    .join(', ')
                : '-'}
            </p>
          </div>
        </div>
      );
    }
    case 'QuestionnaireAdded': {
      return (
        <div className={styles.row}>
          <div className={styles.info}>
            <span className={styles.label}>
              <Icon icon={IconType.Candidates} /> {translate('candidates')}:
            </span>
            <p>{message}</p>
          </div>
        </div>
      );
    }
    case 'InterviewInvitationSent': {
      return (
        <>
          <div className={styles.row}>
            <div className={styles.info}>
              <span className={styles.label}>
                <Icon icon={IconType.Candidates} /> {translate('candidate')}:
              </span>
              <p>{message}</p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.info}>
              <span className={styles.label}>
                <Icon icon={IconType.Candidates} /> {translate('participants')}:
              </span>
              <p>{additionalData}</p>
            </div>
          </div>
        </>
      );
    }
    default:
      return <></>;
  }
};

const HistoryEvent = (props: IRequisitionHistoryEvent) => (
  <div className={styles.event} data-test-id='history-event'>
    <div className={styles.leftSide}>
      <p className={styles.idNumber} data-test-id='history-event-id'>
        {props.id}
      </p>
      <div className={styles.divider}></div>
      <div className={styles.eventInfo}>
        <div className={styles.mainInfo}>
          <p className={styles.eventName} data-test-id='history-event-type'>
            {translate(props.eventType)}
          </p>
          <div className={styles.eventDate}>
            <p data-test-id='history-event-date'>
              {translate('date')}: <span>{dayjs(props.date).format('DD.MM.YYYY')}</span>
            </p>

            <div className={styles.dateDivider} />
            <span data-test-id='history-event-time'>{dayjs(props.date).format('HH:mm')}</span>
          </div>
        </div>
        <div className={styles.additionalInfo}>
          {getAdditionalData(props.eventType, props.message, props.additionalData)}
        </div>
      </div>
    </div>
    <div className={styles.rightSide}>
      <Icon icon={IconType.Enter} />
      <p data-test-id='history-event-from'>
        <span data-test-id='history-event-from-label'>{translate('from')}:</span>{' '}
        {props.userFullName || 'System'}
      </p>
    </div>
  </div>
);

export default HistoryEvent;
