import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IRecruitment } from 'src/pages/RecruitmentPage/Recruitment.def';
import styles from './RescheduleMeetingModal.module.scss';

interface IRescheduleMeetingModalProps {
  recruitment: IRecruitment;
  onClose: () => void;
  handleReschedule: () => void;
}

const RescheduleMeetingModal = ({
  onClose,
  handleReschedule,
  recruitment,
}: IRescheduleMeetingModalProps) => (
  <div className={styles.modal} data-test-id='modal-content'>
    <div className={styles.modalContent}>
      <div className={styles.modalHeader}>
        <h2 data-test-id='modal-header'>{translate('rescheduleTheMeeting')}</h2>
        <p data-test-id='modal-additional-info'>{translate('rescheduleTheMeetingFirstInfo')}</p>
      </div>
      <div className={styles.infoWrapper}>
        <h3 data-test-id='job-title'>{recruitment.jobTitle}</h3>
        <div className={styles.info}>
          <div data-test-id='company-name' className={styles.companyName}>
            <Icon icon={IconType.Folder} />
            {recruitment.companyName}
          </div>
          <div data-test-id='candidate' className={classNames(styles.infoItem, styles.candidate)}>
            <span data-test-id='candidate-label' className={styles.label}>
              <Icon icon={IconType.Candidates} /> {`${translate('candidate')}:`}
            </span>
            {`${recruitment.candidateFirstName} ${recruitment.candidateLastName}`}
          </div>
          <div data-test-id='meeting-date' className={styles.infoItem}>
            <span data-test-id='meeting-date-label' className={styles.label}>
              <Icon icon={IconType.Clock} /> {`${translate('meeting')}:`}
            </span>
            {recruitment.dateFrom
              ? `${dayjs(recruitment.dateFrom).format('DD.MM.YYYY')}, ${dayjs(
                  recruitment.dateFrom,
                ).format('H:mm')} - ${dayjs(recruitment.dateTo).format('H:mm')}`
              : '-'}
          </div>
        </div>
      </div>
      <p data-test-id='modal-question-info' className={styles.question}>
        {translate('rescheduleTheMeetingQuestion')}
      </p>
    </div>
    <div className={styles.actionButtons} data-test-id='actions-buttons'>
      <Button
        ctaText={translate('cancel')}
        stylingType='secondary'
        type='button'
        dataTestId='cancel-button'
        ownClass={styles.cancelButton}
        onClick={onClose}
      />
      <Button
        ctaText={translate('confirm')}
        stylingType='primary'
        type='button'
        dataTestId='confirm-button'
        ownClass={styles.primaryButton}
        onClick={() => {
          handleReschedule();
          onClose();
        }}
      />
    </div>
  </div>
);

export default RescheduleMeetingModal;
