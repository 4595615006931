import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IPollDetails } from 'src/pages/PollDetailsPage/PollDetailsPage.def';
import styles from './CancelPollModal.module.scss';

interface ICancelPollModalProps {
  pollDetails: IPollDetails | null;
  onClose: () => void;
  handleCancel: () => void;
}

const CancelPollModal = ({ onClose, handleCancel, pollDetails }: ICancelPollModalProps) => {
  if (!pollDetails) {
    return null;
  }

  return (
    <div className={styles.modal} data-test-id='modal-content'>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2 data-test-id='modal-header'>{translate('cancelThePoll')}</h2>
          <p data-test-id='modal-additional-info'>{translate('cancelThePollFirstInfo')}</p>
        </div>
        <div data-test-id='modal-question-info' className={styles.question}>
          <p>{`${translate('cancelPollIncludesInfo')} ${pollDetails.candidates.length} ${translate(
            'ccandidates',
          )}.`}</p>
          <p>{translate('cancelThePollQuestion')}</p>
        </div>
        <div className={styles.infoWrapper}>
          <h3 data-test-id='job-title'>{pollDetails.jobTitle}</h3>
          <div className={styles.info}>
            <div data-test-id='company-name' className={styles.companyName}>
              <Icon icon={IconType.Folder} />
              {pollDetails.companyName}
            </div>
            <div data-test-id='candidate' className={classNames(styles.infoItem, styles.candidate)}>
              <span data-test-id='candidate-label' className={styles.label}>
                <Icon icon={IconType.Candidates} /> {`${translate('candidate')}:`}
              </span>
              <p>
                {pollDetails.candidates
                  .reduce(
                    (currentText, candidate) =>
                      currentText + `${candidate.firstName} ${candidate.lastName}, `,
                    '',
                  )
                  .slice(0, -2)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionButtons} data-test-id='actions-buttons'>
        <Button
          ctaText={translate('cancel')}
          stylingType='secondary'
          type='button'
          dataTestId='cancel-button'
          ownClass={styles.cancelButton}
          onClick={onClose}
        />
        <Button
          ctaText={translate('confirm')}
          stylingType='primary'
          type='button'
          dataTestId='confirm-button'
          ownClass={styles.primaryButton}
          onClick={() => {
            handleCancel();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default CancelPollModal;
