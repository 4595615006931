import {
  AddDocument,
  ArrowDownBlack,
  ArrowDownWhite,
  Calendar,
  CalendarPurple,
  Candidates,
  Clock,
  CloseBlack,
  CloseGray,
  ClosePurple,
  Comment,
  CommentEmpty,
  Copy,
  Edit,
  EditDocument,
  Folder,
  Link,
  Location,
  More,
  MorePurple,
  Person,
  Plus,
  PlusWhite,
  Remove,
  RequisitionId,
  Resignation,
  Save,
  Search,
  SmallLink,
  Tick,
  Votes,
  WhiteTick,
  Microsoft,
  Reschedule,
  Enter,
  PeopleGreen,
  PeopleGreenTick,
  PeopleOrange,
  PeopleOrangeTick,
  PeopleRed,
  Document,
  Email,
  SignOut,
  NotFound,
  Refresh,
  HeartOutline,
  HeartFull,
  Similarity,
  Like,
} from './icons/index';

export enum IconType {
  AddDocument = 'AddDocument',
  ArrowDownBlack = 'ArrowDownBlack',
  ArrowDownWhite = 'ArrowDownWhite',
  Calendar = 'Calendar',
  CalendarPurple = 'CalendarPurple',
  Candidates = 'Candidates',
  Clock = 'Clock',
  CloseBlack = 'CloseBlack',
  CloseGray = 'CloseGray',
  ClosePurple = 'ClosePurple',
  Comment = 'Comment',
  CommentEmpty = 'CommentEmpty',
  Copy = 'Copy',
  Edit = 'Edit',
  EditDocument = 'EditDocument',
  Folder = 'Folder',
  Link = 'Link',
  Location = 'Location',
  More = 'More',
  MorePurple = 'MorePurple',
  Person = 'Person',
  Plus = 'Plus',
  PlusWhite = 'PlusWhite',
  Remove = 'Remove',
  RequisitionId = 'RequisitionId',
  Resignation = 'Resignation',
  Save = 'Save',
  Search = 'Search',
  SmallLink = 'SmallLink',
  Tick = 'Tick',
  Votes = 'Votes',
  WhiteTick = 'WhiteTick',
  Microsoft = 'Microsoft',
  Reschedule = 'Reschedule',
  Enter = 'Enter',
  PeopleGreen = 'PeopleGreen',
  PeopleGreenTick = 'PeopleGreenTick',
  PeopleOrange = 'PeopleOrange',
  PeopleOrangeTick = 'PeopleOrangeTick',
  PeopleRed = 'PeopleRed',
  Document = 'Document',
  Email = 'Email',
  SignOut = 'SignOut',
  NotFound = 'NotFound',
  Refresh = 'Refresh',
  HeartOutline = 'HeartOutline',
  HeartFull = 'HeartFull',
  Similarity = 'Similarity',
  Like = 'Like',
}

const getIcon = (icon: IconType) => {
  switch (icon) {
    case IconType.ArrowDownBlack:
      return ArrowDownBlack;
    case IconType.ArrowDownWhite:
      return ArrowDownWhite;
    case IconType.Folder:
      return Folder;
    case IconType.RequisitionId:
      return RequisitionId;
    case IconType.Search:
      return Search;
    case IconType.Candidates:
      return Candidates;
    case IconType.Plus:
      return Plus;
    case IconType.MorePurple:
      return MorePurple;
    case IconType.CloseBlack:
      return CloseBlack;
    case IconType.ClosePurple:
      return ClosePurple;
    case IconType.CloseGray:
      return CloseGray;
    case IconType.AddDocument:
      return AddDocument;
    case IconType.EditDocument:
      return EditDocument;
    case IconType.Edit:
      return Edit;
    case IconType.Remove:
      return Remove;
    case IconType.PlusWhite:
      return PlusWhite;
    case IconType.Person:
      return Person;
    case IconType.Calendar:
      return Calendar;
    case IconType.CalendarPurple:
      return CalendarPurple;
    case IconType.Clock:
      return Clock;
    case IconType.Location:
      return Location;
    case IconType.Comment:
      return Comment;
    case IconType.CommentEmpty:
      return CommentEmpty;
    case IconType.Tick:
      return Tick;
    case IconType.WhiteTick:
      return WhiteTick;
    case IconType.Votes:
      return Votes;
    case IconType.Link:
      return Link;
    case IconType.SmallLink:
      return SmallLink;
    case IconType.More:
      return More;
    case IconType.Copy:
      return Copy;
    case IconType.Save:
      return Save;
    case IconType.Resignation:
      return Resignation;
    case IconType.Microsoft:
      return Microsoft;
    case IconType.Reschedule:
      return Reschedule;
    case IconType.Enter:
      return Enter;
    case IconType.PeopleGreen:
      return PeopleGreen;
    case IconType.PeopleGreenTick:
      return PeopleGreenTick;
    case IconType.PeopleOrange:
      return PeopleOrange;
    case IconType.PeopleOrangeTick:
      return PeopleOrangeTick;
    case IconType.PeopleRed:
      return PeopleRed;
    case IconType.Document:
      return Document;
    case IconType.Email:
      return Email;
    case IconType.SignOut:
      return SignOut;
    case IconType.NotFound:
      return NotFound;
    case IconType.Refresh:
      return Refresh;
    case IconType.HeartOutline:
      return HeartOutline;
    case IconType.HeartFull:
      return HeartFull;
    case IconType.Similarity:
      return Similarity;
    case IconType.Like:
      return Like;
    default:
      break;
  }
};

interface IIconComponentProps {
  icon: IconType;
  className?: string;
}

const Icon = ({ icon, className }: IIconComponentProps) => (
  <img src={getIcon(icon)} alt='' className={className || ''} />
);

export default Icon;
