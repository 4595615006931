import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './ClientPollSuccessTemplate.module.scss';

interface IClientPollSuccessTemplateProps {
  type:
    | 'SUCCESS'
    | 'NO_DATE'
    | 'VOTED'
    | 'EXPIRED'
    | 'RESCHEDULED'
    | 'ADD_LINK_EXPIRED'
    | 'CANCELED';
}

const ClientPollSuccessTemplate = ({ type }: IClientPollSuccessTemplateProps) => {
  let info = '';
  let title = '';
  let expired = false;
  switch (type) {
    case 'SUCCESS': {
      info = translate('clientPollSuccessInfo');
      title = translate('thanksForVoting');
      break;
    }
    case 'NO_DATE': {
      info = translate('clientPollNoDateInfo');
      title = translate('thanksForVoting');
      break;
    }
    case 'VOTED': {
      info = translate('pollVotedInfo');
      title = translate('youHaveAlreadyVoted');
      break;
    }
    case 'EXPIRED': {
      info = translate('youCannotVoteAnymore');
      title = translate('thisPollHasExpired');
      expired = true;
      break;
    }
    case 'ADD_LINK_EXPIRED': {
      info = '';
      title = translate('linkIsAlreadySet');
      expired = true;
      break;
    }
    case 'CANCELED': {
      info = translate('youWillReceiveALinkToANewPoll');
      title = translate('sorryThisPollWasCanceled');
      expired = true;
      break;
    }
    case 'RESCHEDULED': {
      info = translate('yourRequestHasBeenSentToTheOrganizer');
      title = translate('thankYou');
      break;
    }
    default: {
      info = translate('clientPollSuccessInfo');
      title = translate('thanksForVoting');
    }
  }

  return (
    <main className={styles.container}>
      <div className={styles.pageCard}>
        <div className={styles.header}>
          <BrandingLogo ownClass={styles.brandingLogo} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.informationContainer}>
          <div className={styles.information} data-test-id='content'>
            <div className={styles.icon} data-test-id='thumbnail'>
              {expired && <Icon icon={IconType.ClosePurple} />}
              {!expired && <Icon icon={IconType.Tick} />}
            </div>
            <div className={styles.content}>
              <div className={styles.text}>
                <h1 data-test-id='header'>{title}</h1>
                <p data-test-id='info'>{info}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ClientPollSuccessTemplate;
