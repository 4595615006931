import { useState } from 'react';
import styles from './CadidatesShortenInfoList.module.scss';
import type { ICandidate } from 'src/pages/RequisitionsPage/Requisitions.def';
import PersonShortenInfo from 'src/components/atoms/PersonShortenInfo/PersonShortenInfo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { NoteBubble } from 'src/components/utility/Icon/icons';
import { translate } from 'src/contexts/Language';
import { getUUID } from 'src/utils/getUUID';

interface ICadidatesShortenInfoListProps {
  candidates: Partial<ICandidate>[];
}

const CadidatesShortenInfoList = ({ candidates }: ICadidatesShortenInfoListProps) => {
  const [showFullNoteIndex, setShowFullNoteIndex] = useState<number | null>(null);

  return (
    <>
      <div className={styles.candidatesLabel} data-test-id='requisition-candidates-label'>
        <Icon icon={IconType.Candidates} />
        <span className={styles.label}>{translate('candidates')}:</span>
      </div>
      <ul className={styles.candidatesList}>
        {candidates.map((candidate, index) => (
          <li key={getUUID()}>
            <PersonShortenInfo
              name={`${candidate.firstName} ${candidate.lastName}`}
              email={candidate.email || ''}
              ownClass={styles.personShortenInfo}
            />
            {candidate.note && candidate.note !== '' && (
              <div
                className={styles.note}
                onMouseEnter={() =>
                  setShowFullNoteIndex(candidate.note && candidate.note.length > 51 ? index : null)
                }
                onMouseLeave={() => setShowFullNoteIndex(null)}
              >
                <img src={NoteBubble} alt='' />
                <p className={styles.shortenNote} data-test-id='candidate-shorten-note'>
                  {candidate.note}
                </p>
                {showFullNoteIndex === index && (
                  <div className={styles.fullNote} data-test-id='candidate-full-note'>
                    <p>{candidate.note}</p>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default CadidatesShortenInfoList;
