import classnames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import styles from './FormInput.module.scss';

type TInputType = 'text' | 'password' | 'checkbox';

interface IFormInputProps {
  label: string;
  name: string;
  ownClass?: string;
  placeholder?: string;
  type?: TInputType;
  dataTestId?: string;
}

const FormInput = ({
  name,
  label,
  placeholder = '',
  type = 'text',
  ownClass = '',
  dataTestId = '',
}: IFormInputProps) => (
  <div data-test-id={dataTestId} className={classnames(styles.formInput, ownClass)}>
    <label data-test-id='input-label' htmlFor={name}>
      {label}
    </label>
    <Field
      data-test-id='input'
      className={styles.input}
      id={name}
      name={name}
      placeholder={placeholder}
      type={type}
    />
    <p data-test-id='input-error-message'>
      <ErrorMessage name={name} />
    </p>
  </div>
);

export default FormInput;
