import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import PollDetailsPageTemplate from 'src/components/templates/PollDetailsPageTemplate/PollDetailsPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import { requestWrapper } from 'src/utils/requestWrapper';
import type { IPollDetails } from './PollDetailsPage.def';
import preparePollDetailsPageData from './preparePollDetailsPageData';

const PollDetailsPage = () => {
  const [pageData, setPageData] = useState<IPollDetails | null>(null);

  const { id } = useParams();
  useADAccessToken();

  useEffect(() => {
    requestWrapper('GET', `/Questionnaire/details?shortListCandidateId=${id}`)
      .then((response) => setPageData(preparePollDetailsPageData(response)))
      .catch((err) => console.error(err));
  }, [id]);

  if (!pageData) {
    return <LoadingSpinner />;
  }

  return <PollDetailsPageTemplate {...pageData} />;
};

export default PollDetailsPage;
