import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import styles from './RemoveParticipantModal.module.scss';

interface IRemoveParticipantModalProps {
  participant: IParticipant | null;
  onClose: () => void;
  handleRemove: (participantEmail: string) => void;
}

const RemoveParticipantModal = ({
  participant,
  onClose,
  handleRemove,
}: IRemoveParticipantModalProps) => {
  if (!participant) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalInfo}>
        <h3 data-test-id='header'>{translate('removeParticipant')}</h3>
        <div className={styles.thumbnail}>
          <div className={styles.initials} data-test-id='thumbnail'>
            {`${participant.firstName[0]}${participant.lastName[0]}`}
          </div>
          <div className={styles.icon}>
            <Icon icon={IconType.ClosePurple} />
          </div>
        </div>
        <p data-test-id='question'>
          {translate('removeParticipantQuestion')}
          <span>{` ${participant.firstName} ${participant.lastName}`}?</span>
        </p>
      </div>
      <div className={styles.actionButtons} data-test-id='actions-buttons'>
        <Button
          ctaText={translate('cancel')}
          stylingType='secondary'
          type='button'
          dataTestId='cancel-button'
          ownClass={styles.cancelButton}
          onClick={onClose}
        />
        <Button
          ctaText={translate('yesRemove')}
          stylingType='primary'
          type='button'
          dataTestId='confirm-button'
          ownClass={styles.addButton}
          onClick={() => {
            handleRemove(participant.email);
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default RemoveParticipantModal;
