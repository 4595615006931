import { useContext, useEffect, useState } from 'react';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import type { AlertContextType, IAlert } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { getUUID } from 'src/utils/getUUID';
import styles from './Alert.module.scss';

const Alert = () => {
  const { alert, removeAlert } = useContext(AlertContext) as AlertContextType;

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        removeAlert();
      }, 5000);
    }
  }, [alert, removeAlert]);

  if (!alert) {
    removeAlert();
    return null;
  }

  return (
    <div className={styles.alerts}>
      <div key={getUUID()} className={styles.alert}>
        <div className={styles.alertContent}>
          <div className={styles.left}>
            {alert.type === 'SUCCESS' && (
              <div className={styles.succesIcon}>
                <Icon icon={IconType.WhiteTick} />
              </div>
            )}
            {alert.type === 'ERROR' && (
              <div className={styles.errorIcon}>
                <Icon icon={IconType.CloseGray} />
              </div>
            )}
            <p>{alert.message}</p>
          </div>

          <button
            className={styles.closeAlertButton}
            onClick={() => {
              removeAlert();
            }}
          >
            <Icon icon={IconType.CloseGray} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;
