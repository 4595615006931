import type { IPollDetails, IPollDetailsResponse } from './PollDetailsPage.def';

const preparePollDetailsPageData = (res: IPollDetailsResponse): IPollDetails => ({
  id: res.Id,
  jobTitle: res.JobTitle,
  companyName: res.CompanyName,
  expireDate: res.ExpireDate,
  status: res.Status,
  commonDatesCount: res.CommonDatesCount,
  minimumDatesCount: res.MinimumDatesCount,
  candidates: res.Candidates.map((cand) => ({
    candidateId: cand.CandidateId,
    email: cand.Email,
    firstName: cand.FirstName,
    id: cand.Id,
    lastName: cand.LastName,
  })),
  comments: res.Comments
    ? res.Comments.map((com) => ({
        recruiterFirstName: com.RecruiterFirstName,
        recruiterLastName: com.RecruiterLastName,
        comment: com.Comment,
      }))
    : null,

  responents: res.Responents.map((r) => ({
    id: r.Id,
    firstName: r.FirstName,
    lastName: r.LastName,
    email: r.Email,
    voted: r.Voted,
    noSuitableDate: r.NoSuitableDate,
  })),

  terms: res.Terms.map((term) => ({
    votesCount: term.VotesCount,
    dateFrom: term.DateFrom,
    dateTo: term.DateTo,
    respondents: term.Respondents.map((r) => ({
      id: r.Id,
      firstName: r.FirstName,
      lastName: r.LastName,
      email: r.Email,
      voted: r.Voted,
      noSuitableDate: r.NoSuitableDate,
    })),
  })),
});

export default preparePollDetailsPageData;
