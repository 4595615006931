/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import dayjs from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { ICalendarEvent, IParticipant } from 'src/pages/CreatePollPage/CreatePoll.def';
import { getUUID } from 'src/utils/getUUID';
import styles from './Calendar.module.scss';
import { Calendar as RBCCalendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import './Calendar.scss';
import dayjsLocalizer from 'src/utils/dayjsLocalizer';
import enGb from 'dayjs/locale/en-gb';
import { requestWrapper } from 'src/utils/requestWrapper';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';

const DnDCalendar = withDragAndDrop(RBCCalendar);

dayjs.locale(enGb);
const localizer = dayjsLocalizer(dayjs);

interface ICalendarProps {
  events: ICalendarEvent[];
  setEvents: Dispatch<SetStateAction<ICalendarEvent[]>>;
  duration: string;
  participants: IParticipant[];
}

const Calendar = ({ events, setEvents, duration, participants }: ICalendarProps) => {
  const [date, setDate] = useState(dayjs().toDate());
  const [daysList, setDaysList] = useState<Date[]>([]);
  const [dateToDelete, setDateToDelete] = useState<Date | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [dateRangeSide, setDateRangeSide] = useState<null | 'RIGHT' | 'LEFT'>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePrevClick = () => {
    if (currentPage === 0) return null;
    setCurrentPage((prev) => prev - 1);
    setDate(dayjs(date).subtract(7, 'days').toDate());
    setDateRangeSide('LEFT');
  };

  const handleNextClick = () => {
    setCurrentPage((prev) => prev + 1);
    setDate(dayjs(date).add(7, 'days').toDate());
    setDateRangeSide('RIGHT');
  };

  const handleAddEvent = (data: any) => {
    if (dayjs().isAfter(data.start)) {
      return undefined;
    }

    const newEvents = [
      ...events,
      {
        start: data.start,
        end: dayjs(data.start).add(Number(duration), 'minutes').toDate(),
        id: events.length,
        dateFrom: data.start,
        dateTo: dayjs(data.start).add(Number(duration), 'minutes').toDate(),
        votesCount: 0,
        selected: true,
      },
    ];

    setEvents(newEvents);
  };

  const handleClearEvent = useCallback(
    (data: any) => {
      const event = events.find((event) => event.start === data.start);
      if (event && event.type !== 'EXTERNAL') {
        const newEvents = events.filter((event) => event.start !== data.start);
        setEvents(newEvents);
      } else {
        const _events = events.filter(
          (event) => event.start === data.start && event.type !== 'EXTERNAL',
        );
        if (events.length > 0) {
          const newEvents = events.filter((event) => !_events.includes(event));
          setEvents(newEvents);
        }
      }
    },
    [events],
  );

  const onEventDrop = (data: any) => {
    handleAddEvent({ start: data.start });
  };

  const onClearTimes = (day: any) => {
    const newEvents = [...events].filter(
      (e) =>
        dayjs(e.start).format('MMM D, YYYY') !== dayjs(day).format('MMM D, YYYY') ||
        e.type === 'EXTERNAL',
    );
    setEvents(newEvents);
  };

  useEffect(() => {
    const newList: Date[] = [];
    for (let i = 0; i < 7; i++) {
      newList.push(dayjs(date).startOf('week').add(i, 'days').toDate());
    }

    setDaysList(newList);
  }, [date]);

  useEffect(() => {
    handleClearEvent({ start: dateToDelete });
  }, [dateToDelete]);

  useEffect(() => {
    const eventTiles: NodeListOf<HTMLElement> = document.querySelectorAll('.rbc-event');
    eventTiles.forEach((tile) => {
      if (duration === '30') {
        tile.style.maxHeight = '45px';
      } else if (duration === '45') {
        tile.style.height = '45px';
      } else if (duration === '60') {
        tile.style.maxHeight = '100px';
      } else if (duration === '90') {
        tile.style.maxHeight = '157px';
      } else if (duration === '120') {
        tile.style.maxHeight = '213px';
      }

      const externalEventTiles: Element[] = Array.from(
        document.querySelectorAll('.rbc-event-content'),
      ).filter((tile) => tile.textContent && tile.textContent === '[EXTERNAL]');

      externalEventTiles.forEach((tile) => {
        if (tile.parentElement?.querySelector('.rbc-event-label')?.textContent) {
          const meetingTimeRange =
            tile.parentElement?.querySelector('.rbc-event-label')?.textContent;
          const startTime = meetingTimeRange?.split(' ')[0];
          const endTime = meetingTimeRange?.split(' ')[2];
          const meetingDuration = dayjs(`2022-02-01 ${endTime}`).diff(
            dayjs(`2022-02-01 ${startTime}`),
            'minutes',
          );
          const eventElement = tile.parentElement?.parentElement;

          if (eventElement) {
            eventElement.style.maxHeight = `${
              meetingDuration / 60 > 1
                ? (meetingDuration / 60) * 100 + (Math.floor(meetingDuration / 60) - 1) * 12
                : (meetingDuration / 60) * 102
            }px`;

            eventElement.classList.add(styles.externalEvent);
          }
        }
      });

      const wrongExternalEvents: Element[] = Array.from(
        document.querySelectorAll('.' + styles.externalEvent),
      ).filter((tile) => !(tile as HTMLElement).title.includes('[EXTERNAL]'));

      wrongExternalEvents.forEach((tile) => tile.classList.remove(styles.externalEvent));
    });
  }, [events]);

  useEffect(() => {
    setLoading(true);
    const data = {
      emailAddresses: participants.map((participant) => participant.email),
      startTime: dayjs(daysList[0]).add(2, 'hours').toDate(),
      endTime: dayjs(daysList[daysList.length - 1])
        .add(25, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds')
        .toDate(),
    };

    requestWrapper('POST', '/Questionnaire/getmsschedule', data)
      .then((res) => {
        if (res.Persons) {
          const _events = [];
          for (const person of res.Persons) {
            if (person.notFound) {
              continue;
            } else {
              for (const event of person.Events) {
                const newEvent = {
                  title: '[EXTERNAL]',
                  start: dayjs(event.StartDate).toDate(),
                  end: dayjs(event.EndDate).toDate(),
                  id: events.length,
                  dateFrom: dayjs(event.StartDate).toDate(),
                  dateTo: dayjs(event.EndDate).toDate(),
                  votesCount: 0,
                  selected: true,
                  type: 'EXTERNAL',
                };

                _events.push(newEvent);
              }
            }
          }
          setEvents(_events);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [daysList]);

  useEffect(() => {
    let timeslots: HTMLDivElement[] = Array.from(
      document.querySelectorAll('div[data-test-id="timeslot"]'),
    );

    if (dateRangeSide) {
      timeslots = Array.from(document.querySelectorAll('div[data-test-id="date-timeslot"]'));
      for (const timeslot of timeslots) {
        let date = dayjs(
          `${timeslot.dataset.testDate?.split('.').reverse().join('-')}T${
            timeslot.dataset.testTime
          }`,
        );

        if (dateRangeSide === 'LEFT') {
          date = date.subtract(7, 'days');
        } else if (dateRangeSide === 'RIGHT') {
          date = date.add(7, 'days');
        }

        if (timeslot.parentElement) {
          timeslot.parentElement.dataset.testDate = date.format('DD.MM.YYYY');

          if (dayjs(date).isBefore(dayjs(new Date()))) {
            timeslot.parentElement.classList.add(styles.pastDate);
          } else {
            timeslot.parentElement.classList.remove(styles.pastDate);
          }
        }
      }
    } else {
      for (const timeslot of timeslots) {
        if (timeslot.parentElement?.parentElement?.parentElement?.dataset.testId !== 'gutter') {
          timeslot.dataset.testId = 'date-timeslot';

          const date = dayjs(
            `${timeslot.dataset.testDate?.split('.').reverse().join('-')}T${
              timeslot.dataset.testTime
            }`,
          );

          if (timeslot.parentElement) {
            timeslot.parentElement.dataset.testId = 'timeslot-group';
            timeslot.parentElement.dataset.testDate = timeslot.dataset.testDate;

            if (dayjs(date).isBefore(dayjs(new Date()))) {
              timeslot.parentElement.classList.add(styles.pastDate);
            } else {
              timeslot.parentElement.classList.remove(styles.pastDate);
            }
          }
        } else {
          timeslot.dataset.testId = 'gutter-timeslot';
        }
      }
    }
  }, [date, currentPage]);

  return (
    <div className={styles.calendarContainer}>
      <LoaderModal isOpen={loading} />
      <div className={styles.calendarNavigation} data-test-id='calendar-navigation'>
        <button
          onClick={handlePrevClick}
          className={classNames(styles.leftButton, currentPage === 0 && styles.disabledButton)}
          data-test-id='calendar-prev-week-button'
        >
          <Icon icon={IconType.ArrowDownBlack} />
        </button>
        <p className={styles.navText} data-test-id='calendar-chosen-week'>
          {`${dayjs(date).startOf('week').format('MMM D')} - ${dayjs(date)
            .endOf('week')
            .format('MMM D, YYYY')} `}
        </p>
        <button
          onClick={handleNextClick}
          className={styles.rightButton}
          data-test-id='calendar-next-week-button'
        >
          <Icon icon={IconType.ArrowDownBlack} />
        </button>
      </div>
      <div className={styles.daysContainer} data-test-id='calendar-days-header'>
        {daysList.map((day) => (
          <div
            key={getUUID()}
            className={classNames(
              styles.calendarDayHeader,
              dayjs(new Date()).format('YYYY.MM.DD') > dayjs(day).format('YYYY.MM.DD') &&
                styles.disabledDay,
            )}
            data-test-id='calendar-days-header-cell'
          >
            <div
              className={classNames(
                styles.dayWrapper,
                dayjs(day).format('DD MM') === dayjs(new Date()).format('DD MM') &&
                  styles.activeDay,
              )}
            >
              <span className={styles.day} data-test-id='calendar-day-name'>
                {dayjs(day).format('ddd')}
              </span>
              <span data-test-id='calendar-day-date'>{dayjs(day).format('D')}</span>
            </div>
            {events.find(
              (el) =>
                dayjs(el.start).format('MMM D, YYYY') === dayjs(day).format('MMM D, YYYY') &&
                el.type !== 'EXTERNAL',
            ) && (
              <p className={styles.clearTimes} onClick={() => onClearTimes(day)}>
                {translate('clearTimes')}
              </p>
            )}
          </div>
        ))}
      </div>
      <DnDCalendar
        localizer={localizer}
        date={date}
        scrollToTime={date}
        events={events}
        onDragStart={(date: any) => {
          setDateToDelete(date?.event?.start);
        }}
        defaultView='week'
        onSelectSlot={handleAddEvent}
        onSelectEvent={(slotInfo) => {
          if ((slotInfo as ICalendarEvent).type === 'EXTERNAL') {
            handleAddEvent(slotInfo);
          } else {
            handleClearEvent(slotInfo);
          }
        }}
        style={{ height: 500 }}
        selectable
        timeslots={duration === '45' ? 4 : 2}
        onEventDrop={onEventDrop}
        components={{
          timeSlotWrapper: TimeCell,
          timeGutterWrapper: TimeGutter,
        }}
      />
    </div>
  );
};

const TimeCell = ({ range, value, children }: any) => {
  const [date, setDate] = useState<Date>(value);

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <div
      data-test-id='timeslot'
      data-test-time={dayjs(date).format('HH:mm')}
      data-test-date={dayjs(date).format('DD.MM.YYYY')}
    >
      {children}
    </div>
  );
};

const TimeGutter = ({ value, children }: any) => (
  <div data-test-id='gutter' className={styles.gutter}>
    {children}
  </div>
);

export default Calendar;
