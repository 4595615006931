import classNames from 'classnames';
import styles from './PersonShortenInfo.module.scss';

interface IPersonShortenInfoProps {
  name: string;
  email: string;
  ownClass?: string;
}

const PersonShortenInfo = ({ name, email, ownClass }: IPersonShortenInfoProps) => (
  <div className={classNames(styles.candidateName, ownClass)} data-test-id='person-shorten-info'>
    <p data-test-id='person-name'>{name}</p>
    <span data-test-id='person-email'>{email}</span>
  </div>
);

export default PersonShortenInfo;
