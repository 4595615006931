import classNames from 'classnames';
import { useEffect } from 'react';
import styles from './Modal.module.scss';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import ReactPortal from 'src/components/utility/ReactPortal/ReactPortal';

interface IModalProps {
  children: JSX.Element;
  isOpen: boolean;
  dataTestId: string;
  modalClass?: string;
  handleClose: () => void;
}

const Modal = ({ children, isOpen, handleClose, modalClass, dataTestId }: IModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId='react-portal-modal-container'>
      <div className={styles.modalBackground}>
        <div data-test-id={dataTestId} className={classNames(styles.modal, modalClass)}>
          <button
            data-test-id='modal-close-button'
            onClick={handleClose}
            className={styles.closeButton}
          >
            <Icon icon={IconType.CloseBlack} />
          </button>
          {children}
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;
