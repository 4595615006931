import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import { getUUID } from 'src/utils/getUUID';
import styles from './Term.module.scss';

interface ITermProps {
  dateFrom: Date;
  dateTo: Date;
  votesTotal: number;
  votesNumber: number;
  respondents: {
    firstName: string;
    lastName: string;
    email: string;
    voted: boolean;
  }[];
}

const Term = (props: ITermProps) => {
  const [showRespontents, setShowRespondents] = useState<boolean>(false);

  return (
    <div className={styles.termWrapper}>
      <div className={styles.termHeader}>
        <div className={styles.date}>
          <p>{dayjs(props.dateFrom).format('dddd, MMMM DD')}</p>
          <p>{`${dayjs(props.dateFrom).format('HH:mm')} - ${dayjs(props.dateTo).format(
            'HH:mm',
          )}`}</p>
        </div>
        <div className={styles.rightHeader}>
          <p>
            {translate('votes')}: <span>{props.votesNumber}</span>/{props.votesTotal}
          </p>
          <Button
            ctaText={<Icon icon={IconType.ArrowDownWhite} />}
            stylingType='primary'
            type='button'
            dataTestId='show-respondents-button'
            ownClass={styles.expandButton}
            onClick={() => setShowRespondents(!showRespontents)}
            ctaClass={showRespontents ? styles.arrowUp : ''}
          />
        </div>
      </div>
      {showRespontents && (
        <div className={styles.respondents}>
          {props.respondents
            .sort((a, b) => Number(b.voted) - Number(a.voted))
            .map((resp) => (
              <div
                key={getUUID()}
                className={classNames(styles.respondent, resp.voted && styles.respondentVoted)}
              >
                <p>{`${resp.firstName} ${resp.lastName}`}</p>
                <p className={styles.email}>{resp.email}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Term;
