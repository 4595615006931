import type {
  IRecruitmentDetails,
  IRecruitmentDetailsResponse,
} from 'src/pages/RecruitmentPage/Recruitment.def';

const prepareEditRecruitmentPageData = (
  response: IRecruitmentDetailsResponse,
): IRecruitmentDetails => ({
  id: response.Id,
  jobId: response.JobId,
  jobTitle: response.JobTitle,
  jobDescription: response.JobDescription,
  jobCompanyName: response.JobCompanyName,
  candidateFirstName: response.CandidateFirstName,
  candidateLastName: response.CandidateLastName,
  candidateEmail: response.CandidateEmail,
  duration: response.Duration,
  timeZone: response.TimeZone,
  selectedDateFrom: response.SelectedDateFrom,
  selectedDateTo: response.SelectedDateTo,
  status: response.Status,
  url: response.Url,
  contactPerson: response.ContactPerson,
  contactPersonEmail: response.ContactPersonEmail,
  questionnaireExpireDate: response.QuestionnaireExpireDate,
  recruiters: response.Recruiters
    ? response.Recruiters.map((recruiter) => ({
        id: recruiter.Id,
        firstName: recruiter.FirstName,
        lastName: recruiter.LastName,
        email: recruiter.Email,
      }))
    : [],
});

export default prepareEditRecruitmentPageData;
