import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/atoms/Button/Button';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type { ITimeSpan } from 'src/pages/ClientPollPage/ClientPoll.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import type { IDaySlot } from 'src/utils/Time';
import { splitTimeSlotsToDays } from 'src/utils/Time';
import styles from './CandidatePollVoting.module.scss';

interface IClientPollVotingProps {
  timeSpans: ITimeSpan[];
  token: string;
}

const CandidatePollVoting = ({ timeSpans, token }: IClientPollVotingProps) => {
  const [newTimeSpans, setNewTimeSpans] = useState<ITimeSpan[]>(timeSpans);
  const [noneSuits, setNoneSuits] = useState<boolean>(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(true);
  const [mappedDays, setMappedDays] = useState<IDaySlot>();
  const [comment, setComment] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(false);

  const { addAlert } = useContext(AlertContext) as AlertContextType;
  const { anErrorOccured } = useAlertTexts();

  const navigate = useNavigate();

  const handleCheckDay = (index: number) => {
    const _newTimeSpans = newTimeSpans.map((span) => {
      if (span.id === index) {
        setDateFrom(span.dateFrom);
        setDateTo(span.dateTo);
        return { ...span, selected: !span.selected };
      } else return { ...span, selected: false };
    });
    setNewTimeSpans(_newTimeSpans);
  };

  const handleSave = () => {
    setLoading(true);
    const data = {
      token: token,
      comment: comment,
      nothingSelected: noneSuits,
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    requestWrapper('POST', '/client/candidateselectdate', data)
      .then(() => {
        setLoading(false);
        if (noneSuits) {
          navigate('/candidate-poll/success', { state: { type: 'NO_DATE' } });
        } else {
          navigate('/candidate-poll/success', { state: { dateFrom, dateTo, type: 'SUCCESS' } });
        }
      })
      .catch((err) => {
        setLoading(false);
        addAlert({
          id: getUUID(),
          type: 'ERROR',
          message: anErrorOccured,
        });
        console.error(err);
      });
  };

  useEffect(() => {
    setMappedDays(splitTimeSlotsToDays(newTimeSpans));
  }, [newTimeSpans]);

  useEffect(() => {
    if (noneSuits || newTimeSpans.filter((span) => span.selected).length > 0) {
      setConfirmButtonDisabled(false);
    } else {
      setConfirmButtonDisabled(true);
    }
  }, [noneSuits, newTimeSpans]);

  useEffect(() => {
    if (noneSuits) {
      setNewTimeSpans(
        newTimeSpans.map((span) => ({
          ...span,
          selected: false,
          votesCount: span.selected ? span.votesCount - 1 : span.votesCount,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noneSuits]);

  return (
    <div className={styles.votingContainer}>
      <div className={styles.daysContainer} data-test-id='days-container'>
        {mappedDays &&
          Object.keys(mappedDays).length > 0 &&
          Object.keys(mappedDays).map((day) => (
            <div data-test-id='day' key={getUUID()} className={styles.dayContainer}>
              <div className={styles.dayHeader}>
                <p data-test-id='day-label'>
                  {dayjs(mappedDays[day][0]?.dateFrom).format('dddd, MMMM DD')}
                </p>
              </div>
              <div className={styles.datesRow}>
                {mappedDays[day].map((time) => (
                  <button
                    key={getUUID()}
                    disabled={noneSuits}
                    className={classNames(
                      styles.dateButton,
                      time.selected && styles.activeDateButton,
                    )}
                    onClick={() => handleCheckDay(time.id)}
                    data-test-id='day-tile'
                    data-test-date={dayjs(time.dateFrom).format('DD.MM.YYYY')}
                  >
                    {`${dayjs(time.dateFrom).format('H:mm')} - ${dayjs(time.dateTo).format(
                      'H:mm',
                    )}`}
                    <span data-test-id='day-tile-tick'>
                      {time.selected && <Icon icon={IconType.WhiteTick} />}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.footerChecks} data-test-id='footer-checks'>
          <div className={styles.nonSuitedButton}>
            <button
              className={classNames(styles.votingButton, noneSuits && styles.nonSuitesButton)}
              onClick={() => {
                setNoneSuits(!noneSuits);
              }}
              data-test-id='non-suits-button'
            >
              <Checkbox
                dataTestId='none-suits-checkbox'
                checked={noneSuits}
                onChange={() => {
                  setNoneSuits(!noneSuits);
                }}
                ownClass={styles.checkbox}
                disabled={false}
              />
              <p>{translate('theAboveDatesAreNotAppropriateForMe')}</p>
            </button>
            {noneSuits && (
              <div className={styles.noneSuitsCommentContainer}>
                <div
                  className={styles.nonSuitsCommentLabel}
                  data-test-id='comments-for-client-label'
                >
                  <Icon icon={IconType.CommentEmpty} />
                  <p>{translate('comments')}</p>
                </div>
                <div className={styles.textarea}>
                  <textarea
                    data-test-id='comments-for-client-input'
                    name='comment'
                    maxLength={250}
                    placeholder={translate('proposeADifferentMeetingDate')}
                    value={comment}
                    onChange={(event) => {
                      setComment(event?.target.value);
                    }}
                  />
                  <p className={classNames(comment.length >= 250 && styles.charactersLimit)}>
                    {comment.length}/250 {translate('characters')}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.confirmButtonContainer}>
          <Button
            ctaText={translate('confirm')}
            stylingType='primary'
            type='button'
            dataTestId='confirm-button'
            disabled={confirmButtonDisabled}
            ownClass={styles.confirmButton}
            onClick={handleSave}
          />
        </div>
      </div>
      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default CandidatePollVoting;
