import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Button from 'src/components/atoms/Button/Button';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import styles from './NotFoundPageTemplate.module.scss';
import { useMsal } from '@azure/msal-react';

interface NotFoundPageTemplateProps {
  isLogout?: boolean;
}

const NotFoundPageTemplate: FC<NotFoundPageTemplateProps> = ({ isLogout = false }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  return (
    <main className={styles.container} data-test-id='not-found-page-container'>
      <div className={styles.pageCard}>
        <div className={styles.header} data-test-id='branding'>
          <BrandingLogo ownClass={styles.brandingLogo} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.content}>
          <Icon icon={IconType.NotFound} />
          <div>
            <h1>OOPS!</h1>
            <p>{isLogout ? 'You do not have access to this page' : translate('pageNotFound')}</p>
          </div>
          <Button
            ctaText={isLogout ? translate('signOut') : translate('goToHomepage')}
            stylingType='primary'
            type='button'
            dataTestId='button'
            ownClass={styles.button}
            onClick={() => {
              if (isLogout) {
                instance.logout();
                navigate('/login');
              } else {
                navigate('/');
              }
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default NotFoundPageTemplate;
