import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import Button from 'src/components/atoms/Button/Button';
import StatusBean from 'src/components/atoms/StatusBean/StatusBean';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import RequisitionInfo from 'src/components/organisms/RequisitionInfo/RequisitionInfo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import LoaderModal from 'src/components/utility/LoaderModal/LoaderModal';
import type { AlertContextType } from 'src/contexts/AlertContext';
import AlertContext from 'src/contexts/AlertContext';
import { translate } from 'src/contexts/Language';
import useAlertTexts from 'src/hooks/useAlertTexts';
import type { IRecruitmentResignationDetails } from 'src/pages/RecruitmentPage/Recruitment.def';
import type { IRequisition } from 'src/pages/RequisitionsPage/Requisitions.def';
import { getUUID } from 'src/utils/getUUID';
import { requestWrapper } from 'src/utils/requestWrapper';
import styles from './RecruitmentDetailsPageTemplate.module.scss';

const getStatus = (status: string) => {
  let _status: string = status;

  switch (status) {
    case 'CandidateResigned':
      _status = 'Resignation';
      break;
    case 'NoSuitableDate':
      _status = '_NoSuitableDate';
      break;
    case 'ToReschedule':
      _status = '_ToReschedule';
      break;
    default:
      break;
  }

  return _status;
};

const getReason = (props: IRecruitmentResignationDetails) => {
  let reason = <></>;

  switch (props.status) {
    case 'ToReschedule':
      {
        reason = (
          <>
            {props.candidateRescheduleReason && props.candidateRescheduleReason !== '' ? (
              <p>{props.candidateRescheduleReason}</p>
            ) : (
              <p>{translate('noAnswerContactWithCandidate')}</p>
            )}
          </>
        );
      }
      break;
    case 'NoSuitableDate':
      {
        reason = (
          <>
            {props.candidateNoSuitableDateComment && props.candidateNoSuitableDateComment !== '' ? (
              <p>{props.candidateNoSuitableDateComment}</p>
            ) : (
              <p>{translate('noAnswerContactWithCandidate')}</p>
            )}
          </>
        );
      }
      break;
    default:
      {
        reason = (
          <>
            {props.candidateResignReason !== translate('chooseReason') &&
              props.candidateResignOtherReason !== '' && (
                <p>
                  {props.candidateResignReason} <span>{props.candidateResignOtherReason}</span>
                </p>
              )}

            {props.candidateResignReason !== translate('chooseReason') &&
              props.candidateResignOtherReason === '' && <p>{props.candidateResignReason}</p>}

            {props.candidateResignReason === translate('chooseReason') &&
              props.candidateResignOtherReason !== '' && <p>{props.candidateResignOtherReason}</p>}

            {props.candidateResignReason === translate('chooseReason') &&
              props.candidateResignOtherReason === '' && (
                <p>{translate('noAnswerContactWithCandidate')}</p>
              )}
          </>
        );
      }
      break;
  }

  return reason;
};

const RecruitmentDetailsPageTemplate = (props: IRecruitmentResignationDetails) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { meetingHasBeenMovedToArchives, anErrorOccured } = useAlertTexts();
  const { addAlert } = useContext(AlertContext) as AlertContextType;

  const requisitionInfoData: Partial<IRequisition> = {
    title: props.jobTitle,
    companyName: props.jobCompanyName,
    id: props.id,
    contactPerson: props.contactPerson,
    contactPersonEmail: props.contactPersonEmail,
  };

  const navigate = useNavigate();

  return (
    <main className={styles.container}>
      <AppHeader currentPage='RECRUITMENT' />
      <section className={styles.pageCard} data-test-id='page-card'>
        <BackHeader
          title={translate('details')}
          backUrl={'/recruitment'}
          ownClass={styles.backHeader}
        />
        <aside className={styles.requisitionInfo} data-test-id='aside-requisition-info'>
          <StatusBean status={getStatus(props.status)} />
          <RequisitionInfo requisition={requisitionInfoData} />
        </aside>
        <div className={styles.details} data-test-id='details'>
          <div className={styles.meetingDetailsContainer}>
            <div className={styles.candidate}>
              <div className={styles.label} data-test-id='candidate-label'>
                <Icon icon={IconType.Candidates} />
                {translate('candidate')}
              </div>
              <div className={styles.candidateTile}>
                <div
                  className={styles.initials}
                  data-test-id='candidate-initials'
                >{`${props.candidateFirstName[0]}${props.candidateLastName[0]}`}</div>
                <div className={styles.candidateDetails}>
                  <p data-test-id='candidate-full-name'>{`${props.candidateFirstName} ${props.candidateLastName}`}</p>
                  <span data-test-id='candidate-email'>{props.candidateEmail}</span>
                </div>
              </div>
            </div>
            <div className={styles.resignationReason}>
              <div className={styles.label} data-test-id='resigantion-reason-label'>
                <Icon icon={IconType.Comment} />
                {translate('reason')}
              </div>
              {getReason(props)}
            </div>
            <div className={styles.participants}>
              <div className={styles.label} data-test-id='participants-label'>
                <Icon icon={IconType.Candidates} />
                {translate('participants')}
              </div>
              <div className={styles.participantsList} data-test-id='participants'>
                {props.recruiters.map((participant) => (
                  <div key={getUUID()} className={styles.candidateTile}>
                    <div
                      className={styles.initials}
                      data-test-id='candidate-initials'
                    >{`${participant.firstName[0]}${participant.lastName[0]}`}</div>
                    <div className={styles.candidateDetails}>
                      <p data-test-id='candidate-full-name'>{`${participant.firstName} ${participant.lastName}`}</p>
                      <span data-test-id='candidate-email'>{participant.email}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.actionButtons} data-test-id='actions-buttons'>
              <Button
                ctaText={translate('back')}
                stylingType='secondary'
                type='button'
                dataTestId='skip-button'
                ownClass={styles.skipButton}
                onClick={() => {
                  navigate('/recruitment');
                }}
              />
              {props.status !== 'Canceled' && (
                <Button
                  ctaText={translate('moveToArchives')}
                  stylingType='primary'
                  type='button'
                  dataTestId='confirm-button'
                  ownClass={styles.confirmButton}
                  onClick={() => {
                    setLoading(true);
                    requestWrapper('GET', `/interview/setarchived?id=${props.id}`)
                      .then(() => {
                        setLoading(false);
                        addAlert({
                          id: getUUID(),
                          type: 'SUCCESS',
                          message: meetingHasBeenMovedToArchives,
                        });
                        navigate('/recruitment');
                      })
                      .catch((err) => {
                        setLoading(false);
                        addAlert({
                          id: getUUID(),
                          type: 'ERROR',
                          message: anErrorOccured,
                        });
                        console.error(err);
                      });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <LoaderModal isOpen={loading} />
    </main>
  );
};

export default RecruitmentDetailsPageTemplate;
