import { useEffect, useState } from 'react';
import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';
import AIMatchingPageTemplate from 'src/components/templates/AIMatchingPageTemplate/AIMatchingPageTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import { prepareAIMatchingPageData } from 'src/pages/AIMatchingPage/prepareAIMatchingPageData';
import { RequisitionsTabs } from 'src/pages/RequisitionsPage/Requisitions.def';
import { prepareRequisitionsData } from 'src/pages/RequisitionsPage/prepareRequisitionsPageData';
import { changePageCount } from 'src/store/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';
import { setRequisitions } from 'src/store/requisitions/requisitionsSlice';
import { requestWrapper } from 'src/utils/requestWrapper';
// import { RequisitionsTabs } from './Requisitions.def';

const AIMatchingPage = () => {
  const [infoCardData, setInfoCardData] = useState<IInfoCardData | null>(null);

  const { page, search, tab } = useAppSelector((state) => state.pagination);
  const dispatch = useAppDispatch();
  useADAccessToken();

  useEffect(() => {
    requestWrapper('POST', '/job/joblist', {
      pageIndex: page,
      generalSearch: search,
      type: RequisitionsTabs['all'] || 0,
    })
      .then((response) => {
        dispatch(changePageCount(response.TotalPageCount));
        dispatch(setRequisitions(prepareRequisitionsData(response)));
        setInfoCardData(prepareAIMatchingPageData(response.TotalDataCount));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, page, search, tab]);

  return <AIMatchingPageTemplate infoCard={infoCardData} />;
};

export default AIMatchingPage;
