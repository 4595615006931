import type { AccountInfo } from '@azure/msal-common';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { graphConfig } from 'src/App/authConfig';
import type { IInfoCardData } from 'src/components/molecules/InfoCard/InfoCard';
import RequisitionsTemplate from 'src/components/templates/RequisitionsTemplate/RequisitionsTemplate';
import useADAccessToken from 'src/hooks/useADAccessToken';
import {
  prepareRequisitionsData,
  prepareRequisitionsInfoCardData,
} from 'src/pages/RequisitionsPage/prepareRequisitionsPageData';
import { changePageCount } from 'src/store/pagination/paginationSlice';
import { useAppDispatch, useAppSelector } from 'src/store/reduxHooks';
import { setRequisitions } from 'src/store/requisitions/requisitionsSlice';
import { requestWrapper } from 'src/utils/requestWrapper';
import type { TRequisitionsTab } from './Requisitions.def';
import { RequisitionsTabs } from './Requisitions.def';

const RequisitionsPage = () => {
  const [infoCardData, setInfoCardData] = useState<IInfoCardData | null>(null);

  const { page, search, tab } = useAppSelector((state) => state.pagination);
  const dispatch = useAppDispatch();
  useADAccessToken();

  useEffect(() => {
    requestWrapper('POST', '/job/joblist', {
      pageIndex: page,
      generalSearch: search,
      type: RequisitionsTabs[tab as TRequisitionsTab] || 0,
    })
      .then((response) => {
        dispatch(changePageCount(response.TotalPageCount));
        dispatch(setRequisitions(prepareRequisitionsData(response)));

        setInfoCardData(
          prepareRequisitionsInfoCardData(response.TotalDataCount, response.LastUpdateDate),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, page, search, tab]);

  return <RequisitionsTemplate infoCard={infoCardData} />;
};

export default RequisitionsPage;
