import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner';
import SetURLPageTemplate from 'src/components/templates/SetURLPageTemplate/SetURLPageTemplate';
import { requestWrapper } from 'src/utils/requestWrapper';
import type { IInterviewDataResponse, ISetURLPageData } from './SetURLPage.def';

const SetURLPage = () => {
  const [pageData, setPageData] = useState<ISetURLPageData>();
  const { token } = useParams();

  useEffect(() => {
    requestWrapper('GET', `/client/getinterviewdata?token=${token}`)
      .then((response: IInterviewDataResponse) =>
        setPageData({
          token: token || '',
          candidateFirstName: response.CandidateFirstName,
          candidateLastName: response.CandidateLastName,
          jobTitle: response.JobTitle,
          selectedDateFrom: response.SelectedDateFrom,
          selectedDateTo: response.SelectedDateTo,
          isUrlSet: response.IsUrlSet,
          isCanceled: response.IsQuestionnaireCanceled,
        }),
      )
      .catch((err) => console.error(err));
  }, [token]);

  if (!token || !pageData) {
    return <LoadingSpinner />;
  }

  return <SetURLPageTemplate {...pageData} />;
};

export default SetURLPage;
