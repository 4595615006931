import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Alert from 'src/components/atoms/Alert/Alert';
import PrivateRoute from 'src/components/utility/PrivateRoute/PrivateRoute';
import { AlertProvider } from 'src/contexts/AlertContext';
import useScrollToTop from 'src/hooks/useScrollToTop';
import AIMatchingPage from 'src/pages/AIMatchingPage/AIMatchingPage';
import MatchCandidatePage from 'src/pages/AIMatchingPage/sub-pages/MatchCandidatePage/MatchCandidatePage';
import CandidatePollPage from 'src/pages/CandidatePollPage/CandidatePollPage';
import CandidatePollSuccessPage from 'src/pages/CandidatePollPage/sub-pages/CandidatePollSuccessPage';
import ReschedulePage from 'src/pages/CandidatePollPage/sub-pages/ReschedulePage';
import ResignationPage from 'src/pages/CandidatePollPage/sub-pages/ResignationPage';
import ClientPollPage from 'src/pages/ClientPollPage/ClientPollPage';
import ClientPollSuccessPage from 'src/pages/ClientPollPage/sub-pages/ClientPollSuccessPage';
import CreatePollPage from 'src/pages/CreatePollPage/CreatePollPage';
import CalendarPage from 'src/pages/CreatePollPage/sub-pages/CalendarPage';
import PollSummaryPage from 'src/pages/CreatePollPage/sub-pages/PollSummaryPage';
import NotFoundPage from 'src/pages/ErrorPages/NotFoundPage';
import LoginPage from 'src/pages/Login/LoginPage';
import PollDetailsPage from 'src/pages/PollDetailsPage/PollDetailsPage';
import RecruitmentDetailsPage from 'src/pages/RecruitmentDetailsPage/RecruitmentDetailsPage';
import RecruitmentHistoryPage from 'src/pages/RecruitmentHistoryPage/RecruitmentHistoryPage';
import RecruitmentPage from 'src/pages/RecruitmentPage/RecruitmentPage';
import EditRecruitmentPage from 'src/pages/RecruitmentPage/sub-pages/EditRecruitmentPage/EditRecruitmentPage';
import RequisitionHistoryPage from 'src/pages/RequisitionHistoryPage/RequisitionHistoryPage';
import RequisitionsPage from 'src/pages/RequisitionsPage/RequisitionsPage';
import SettingsPage from 'src/pages/SettingsPage/SettingsPage';
import SetURLPage from 'src/pages/SetURLPage/SetURLPage';
import SetURLSuccessPage from 'src/pages/SetURLPage/sub-pages/SetURLSuccessPage';
import { history } from 'src/utils/history';

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  useScrollToTop();

  return (
    <AlertProvider>
      <Alert />
      <Routes>
        <Route path='/' element={<PrivateRoute component={<RequisitionsPage />} />} />
        <Route path='/login' element={<LoginPage />} />

        <Route path='/requisitions' element={<PrivateRoute component={<RequisitionsPage />} />} />
        <Route
          path='/requisitions/history/:id'
          element={<PrivateRoute component={<RequisitionHistoryPage />} />}
        />

        <Route
          path='/poll-details/:id'
          element={<PrivateRoute component={<PollDetailsPage />} />}
        />

        <Route path='/create-poll' element={<PrivateRoute component={<CreatePollPage />} />} />
        <Route
          path='/create-poll/calendar'
          element={<PrivateRoute component={<CalendarPage />} />}
        />
        <Route
          path='/create-poll/summary'
          element={<PrivateRoute component={<PollSummaryPage />} />}
        />

        <Route path='/client-poll/:id' element={<ClientPollPage />} />
        <Route path='/client-poll/success' element={<ClientPollSuccessPage />} />

        <Route path='/candidate-poll/:id' element={<CandidatePollPage />} />
        <Route path='/candidate-poll/success' element={<CandidatePollSuccessPage />} />
        <Route path='/candidate-poll/resignation/:id' element={<ResignationPage />} />
        <Route path='/candidate-poll/reschedule/:id' element={<ReschedulePage />} />

        <Route path='/client-set-url/:token' element={<SetURLPage />} />
        <Route path='/client-set-url/success' element={<SetURLSuccessPage />} />

        <Route path='/recruitment' element={<PrivateRoute component={<RecruitmentPage />} />} />
        <Route
          path='/recruitment/edit/:id'
          element={<PrivateRoute component={<EditRecruitmentPage />} />}
        />
        <Route
          path='/recruitment/details/:id'
          element={<PrivateRoute component={<RecruitmentDetailsPage />} />}
        />
        <Route
          path='/recruitment/history/:id'
          element={<PrivateRoute component={<RecruitmentHistoryPage />} />}
        />

        <Route path='/ai-matching' element={<PrivateRoute component={<AIMatchingPage />} />} />
        <Route
          path='/match-candidate'
          element={<PrivateRoute component={<MatchCandidatePage />} />}
        />

        <Route path='/settings' element={<PrivateRoute component={<SettingsPage />} />} />

        <Route path='*' element={<PrivateRoute component={<NotFoundPage />} />} />
      </Routes>
    </AlertProvider>
  );
}

export default App;
