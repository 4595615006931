import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import CandidatePollVoting from 'src/components/molecules/CandidatePollVoting/CandidatePollVoting';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { ICandidatePoll } from 'src/pages/CandidatePollPage/CandidatePoll.def';
import styles from './CandidatePollTemplate.module.scss';

const CandidatePollTemplate = (props: ICandidatePoll) => (
  <main className={styles.container}>
    <section className={styles.candidatePollCard} data-test-id='meeting-poll-card'>
      <aside className={styles.asideContent} data-test-id='aside-requisition-info'>
        <BrandingLogo ownClass={styles.brandingLogo} />
        <div className={styles.requisitionInfo}>
          <h3 className={styles.title} data-test-id='requisition-title'>
            {props.title}
          </h3>
          <ul className={styles.requisitionDetailsList}>
            <li data-test-id='requisition-company-name'>
              <Icon icon={IconType.Folder} /> {props.companyName}
            </li>
            <li>
              <Icon icon={IconType.Clock} />
              <span data-test-id='meeting-duration'>
                <span className={styles.label} data-test-id='meeting-duration-label'>
                  {translate('duration')}:
                </span>{' '}
                <span className={styles.bold}>{`${props.duration} min`}</span>
              </span>
            </li>
          </ul>
        </div>
      </aside>
      <div className={styles.mainContent} data-test-id='main-content'>
        <div className={styles.cardHeader}>
          <h1 className={styles.titleHeader} data-test-id='candidate-poll-header'>
            {translate('candidateMeetingPollHeader')}
          </h1>
          <p className={styles.timezone} data-test-id='timezone'>
            <span className={styles.timezoneLabel} data-test-id='timezone-label'>
              {translate('timezone')}:
            </span>
            {props.timezone}
          </p>
        </div>
        <CandidatePollVoting timeSpans={props.timeSpans} token={props.token} />
      </div>
    </section>
  </main>
);

export default CandidatePollTemplate;
