import styles from './CreatePollTemplate.module.scss';
import type { ICreatePollPageData } from 'src/pages/CreatePollPage/CreatePoll.def';
import BackHeader from 'src/components/atoms/BackHeader/BackHeader';
import AppHeader from 'src/components/organisms/AppHeader/AppHeader';
import CreatePollForm from 'src/components/organisms/CreatePollForm/CreatePollForm';
import RequisitionInfo from 'src/components/organisms/RequisitionInfo/RequisitionInfo';
import { translate } from 'src/contexts/Language';
import CadidatesShortenInfoList from 'src/components/molecules/CadidatesShortenInfoList/CadidatesShortenInfoList';
import StatusBean from 'src/components/atoms/StatusBean/StatusBean';

const CreatePollTemplate = ({
  requisition,
  candidates,
  participants,
  duration,
  timezone,
  pollExpirationDate,
  reschedule,
}: ICreatePollPageData) => (
  <main className={styles.container}>
    <AppHeader currentPage={reschedule ? 'RECRUITMENT' : 'REQUISITIONS'} />
    <section className={styles.createMeetingCard} data-test-id='create-new-poll-card'>
      <BackHeader
        title={translate('createANewMeetingPoll')}
        backUrl={reschedule ? '/recruitment' : '/requisitions'}
        ownClass={styles.backHeader}
      />
      <aside className={styles.requisitionInfo} data-test-id='aside-requisition-info'>
        {reschedule && (
          <div className={styles.statusBean}>
            {' '}
            <StatusBean status='Reschedule' />
          </div>
        )}
        <RequisitionInfo requisition={requisition} />
        <div className={styles.divider}></div>
        <CadidatesShortenInfoList candidates={candidates} />
      </aside>
      <div className={styles.createPollForm} data-test-id='create-poll-forms'>
        <CreatePollForm
          requisition={requisition}
          candidates={candidates}
          participants={participants}
          duration={duration}
          timezone={timezone}
          pollExpirationDate={pollExpirationDate}
        />
      </div>
    </section>
  </main>
);

export default CreatePollTemplate;
