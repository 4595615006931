import classNames from 'classnames';
import styles from './BrandingLogo.module.scss';
import AvengaLogo from 'src/components/atoms/AvengaLogo/AvengaLogo';

interface IBrandingLogoProps {
  ownClass?: string;
}

const BrandingLogo = ({ ownClass = '' }: IBrandingLogoProps) => (
  <div data-test-id='branding-logo' className={classNames(styles.branding, ownClass)}>
    <AvengaLogo />
    <p>HR AI Assistant</p>
  </div>
);

export default BrandingLogo;
