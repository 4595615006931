import { useState } from 'react';
import BrandingLogo from 'src/components/atoms/BrandingLogo/BrandingLogo';
import Icon, { IconType } from 'src/components/utility/Icon/Icon';
import { translate } from 'src/contexts/Language';
import type { IClientPoll } from 'src/pages/ClientPollPage/ClientPoll.def';
import styles from './ClientPollTemplate.module.scss';
import { NoteBubble } from 'src/components/utility/Icon/icons';
import ClientPollVoting from 'src/components/molecules/ClientPollVoting/ClientPollVoting';

const ClientPollTemplate = (props: IClientPoll) => {
  const [showFullNote, setShowFullNote] = useState<boolean>(false);

  return (
    <main className={styles.container}>
      <section className={styles.clientPollCard} data-test-id='meeting-poll-card'>
        <aside className={styles.asideContent} data-test-id='aside-requisition-info'>
          <BrandingLogo ownClass={styles.brandingLogo} />
          <div className={styles.requisitionInfo}>
            <div className={styles.title}>
              <p data-test-id='position-label'>{translate('position')}</p>
              <h3 data-test-id='requisition-title'>{props.title}</h3>
            </div>
            <ul className={styles.requisitionDetailsList}>
              <li data-test-id='requisition-company-name'>
                <Icon icon={IconType.Folder} /> {props.companyName}
              </li>
              <li>
                <Icon icon={IconType.Clock} />
                <span data-test-id='meeting-duration'>
                  <span className={styles.label} data-test-id='meeting-duration-label'>
                    {translate('duration')}:
                  </span>{' '}
                  <span className={styles.bold}>{`${props.duration} min`}</span>
                </span>
              </li>
              <li>
                <Icon icon={IconType.Candidates} />
                <span data-test-id='candidates-number'>
                  <span className={styles.label} data-test-id='candidates-number-label'>
                    {translate('candidates')}:
                  </span>{' '}
                  {props.selectedCandidatesCount}
                </span>
              </li>
              <li>
                <Icon icon={IconType.Votes} />
                <span data-test-id='votes-number'>
                  <span className={styles.label} data-test-id='votes-number-label'>
                    {translate('votes')}:
                  </span>{' '}
                  {`${props.votesCast} of ${props.votesTotal} invitee respondeds`}
                </span>
              </li>
            </ul>
          </div>
          {props.additionalInformation && props.additionalInformation !== '' && (
            <>
              <div className={styles.divider}></div>
              <div className={styles.noteContainer}>
                <div className={styles.noteLabel} data-test-id='note-label'>
                  <Icon icon={IconType.Comment} /> {translate('note')}:
                </div>
                <div
                  className={styles.note}
                  onMouseEnter={() =>
                    setShowFullNote(
                      props.additionalInformation && props.additionalInformation.length > 51
                        ? true
                        : false,
                    )
                  }
                  onMouseLeave={() => setShowFullNote(false)}
                >
                  <img src={NoteBubble} alt='' />
                  <p className={styles.shortenNote} data-test-id='shorten-note'>
                    {props.additionalInformation}
                  </p>
                  {showFullNote && (
                    <div className={styles.fullNote} data-test-id='full-note'>
                      <p>{props.additionalInformation}</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </aside>
        <div className={styles.mainContent} data-test-id='main-content'>
          <div className={styles.cardHeader}>
            <div className={styles.titleHeader}>
              <p className={styles.meetingPollLabel} data-test-id='meeting-poll-label'>
                {translate('meetingPoll')}
              </p>
              <h1 data-test-id='meeting-poll-header'>{translate('clientMeetingPollHeader')}</h1>
              <p className={styles.headerInfo} data-test-id='meeting-poll-additional-info'>
                {`${translate('pleaseSelect')} `}
                <span>{`${translate('atLeast')} ${props.minimumTimespansToSelect} ${translate(
                  'dates',
                )} `}</span>
                {`${translate('clientMeetingPollHeaderInfoEnding')}.`}
              </p>
            </div>
            <p className={styles.timezone} data-test-id='timezone'>
              <span className={styles.meetingPollLabel} data-test-id='timezone-label'>
                {translate('timezone')}:
              </span>
              {props.timezone}
            </p>
          </div>
          <ClientPollVoting
            timeSpans={props.timeSpans}
            minimumToSelect={props.minimumTimespansToSelect}
            token={props.token}
          />
        </div>
      </section>
    </main>
  );
};

export default ClientPollTemplate;
