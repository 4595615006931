import en from './en.json';

type IDictionaryTranslations = {
  [key: string]: string;
};

export type TDictionaryList = {
  [key: string]: IDictionaryTranslations;
};

export const dictionaryList: TDictionaryList = { en };

export const languageOptions = {
  en: 'English',
};
